<template>
  <div class="engraving flex flex-dir--top">
    <breadcrumb
      :title="$route.query.line || $t('main.head.prodictionLine')"
      :childTitle="$t('common.engraving')"
      @goBackPage="goBackPage"
    >
      <template v-slot:title>
        <div class="top-nav">
          <span
            v-for="(item, index) in navList"
            :key="index"
            @click="changeNav(item)"
            :class="[nowPage == item.val ? 'active-nav' : '', 'nav-item']"
            :id="`equipment_engraving_nav_${item.val}_tab`"
            ><t-text :text="translateLang(item.name)" width="160px"></t-text>
          </span>
        </div>
      </template>
      <div>
        <!-- <a-button><a-icon type="form" />导入配置</a-button>
        <a-button><a-icon type="share-alt" />分享配置</a-button> -->
        <a-button
          @click.native="handleOpenChangeRecord"
          id="equipment_engraving_change_record"
        >
          <!-- 修改记录 -->
          {{ $t('common.modificationRecord') }}
        </a-button>
        <a-button
          type="primary"
          class="save-box ml20"
          @click.native="saveEngraving"
          :disabled="!ableSaveSetting"
          id="equipment_engraving_save_btn"
        >
          {{ $t('common.saveSetting') }}
        </a-button>
      </div>
    </breadcrumb>
    <div class="wrap flex-box--1 h0">
      <!-- 开料对接 -->
      <cuttingDock
        v-show="nowPage == 'cuttingDock'"
        ref="cutting_dock"
        :obj="allData.knivesSetting"
        :ncExportSetting="allData.ncOutputSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      ></cuttingDock>
      <!-- 切割参数 -->
      <cuttingParams
        v-show="nowPage == 'cuttingParams'"
        ref="cutting_params"
        :obj="allData.cuttingSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      >
      </cuttingParams>
      <!-- 贴标设置 -->
      <labellingSetting
        v-show="nowPage == 'labellingSetting'"
        ref="labelling_setting"
        :obj="allData.labelingSetting"
        :paibanImgScale="paibanImgScale"
        :allLabelingList="allLabelingList"
        :labelNotOnline.sync="labelNotOnline"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      >
      </labellingSetting>
      <!-- 排钻设置 -->
      <gangDrill
        v-show="nowPage == 'gangDrill'"
        ref="gang_drill"
        :obj="allData.drillingSetting"
        :ncExportSetting="allData.ncOutputSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      ></gangDrill>
      <!-- NC导出设置 -->
      <NcExportSetting
        v-show="nowPage == 'NcExportSetting'"
        ref="nc_export"
        :obj="allData.ncOutputSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      >
      </NcExportSetting>
      <AioMachineSetting
        v-show="nowPage == 'aioMachineSetting'"
        ref="aioMachine"
        :obj="allData.integrationDeviceSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      >
      </AioMachineSetting>
      <OneDragTwoEngraving
        v-show="nowPage == 'oneDragTwoEngraving'"
        ref="oneDragTwo"
        :obj="allData.oneForTwoDeviceSetting"
        :isShowSecondaryCatalog="isShowSecondaryCatalog"
      ></OneDragTwoEngraving>
    </div>
    <m-lock-modal
      :visible.sync="isShowVisible"
      :status.sync="lockStatus"
      :verifyPassword="verifyPassword"
      :tempStatus.sync="tempStatus"
      @getLockPassword="handleGetLockPassword"
      :lineName="$route.query.line"
    ></m-lock-modal>

    <!-- 修改记录弹窗 -->
    <m-change-record-modal
      :visible.sync="showRecordModal"
      operate_equipment="engraving"
      @success="handleSuccess"
      ref="recordModalRef"
    />
  </div>
</template>

<script>
import { getOneMacLimit } from '@/apis/equipment'
import { getPreferencesSetting } from '@/apis/materialList'
import { getLockStatus } from '@/apis/productionLineLock'
import NcExportSetting from '@/components/engraving/NcExportSetting.vue'
import AioMachineSetting from '@/components/engraving/aioMachineSetting.vue'
import cuttingDock from '@/components/engraving/cuttingDock.vue'
import cuttingParams from '@/components/engraving/cuttingParams.vue'
import gangDrill from '@/components/engraving/gangDrill.vue'
import labellingSetting from '@/components/engraving/labellingSetting.vue'
import OneDragTwoEngraving from '@/components/engraving/oneDragTwoEngraving.vue'
import breadcrumb from '@/components/topBreadCrumb.vue'
import store from '@/store'
import { translate } from '@/util/commonFun'
import {
  Message,
  addIdForItems,
  buryPointApi, // compareDiffForm,
  flatObj,
  genFinalRecord,
  genFinalRecordPromise,
  modalMsg,
  scrollToElementFn, // genUniId,
  thirdPermission,
} from '@/util/commonFuncs.js'
import { operateRecordTableType, webId } from '@/util/configure'
import {
  AioMachineSettingTextObj,
  AioMachineSettingValueObj,
  NcExportSettingFormTextObj,
  NcExportSettingFormValueObj,
  OneDragTwoEngravingFormValueObj,
  OneDragTwoEngravingTextObj,
  OneDragTwoEngravingValueObj,
  cuttingDockFormTextObj,
  cuttingDockFormValueObj,
  cuttingParamsFormTextObj,
  cuttingParamsFormValueObj,
  gangDrillFormTextObj,
  labellingSettingFormTextObj,
  labellingSettingFormValueObj,
} from '@/util/operateRecordConfigure/engravingConfigure'
import { saveDataJsonToOssForRecord } from '@/util/saveFile'
import MChangeRecordModal from '@/views/equipment/component/m-change-record-modal.vue'
import MLockModal from '@/views/equipment/component/m-lock-modal.vue'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    breadcrumb,
    cuttingDock,
    gangDrill,
    cuttingParams,
    labellingSetting,
    NcExportSetting,
    AioMachineSetting,
    OneDragTwoEngraving,
    MLockModal,
    MChangeRecordModal,
  },
  data() {
    return {
      navList: [
        { name: 'cuttingDock.title', val: 'cuttingDock' },
        {
          name: 'cuttingDock.cuttingParams.title',
          val: 'cuttingParams',
        },
        {
          name: 'cuttingDock.labelingSettings.title',
          val: 'labellingSetting',
        },
        { name: 'cuttingDock.drillSettings.title', val: 'gangDrill' },
        {
          name: 'cuttingDock.NCExportSettings.title',
          val: 'NcExportSetting',
        },
        {
          name: 'cuttingDock.aioMachineSetting.title',
          val: 'aioMachineSetting',
        },
        {
          name: 'cuttingDock.oneDragTwo.title',
          val: 'oneDragTwoEngraving',
        },
      ],
      nowPage: 'cuttingDock',
      allData: {
        knivesSetting: {},
        cuttingSetting: {},
        labelingSetting: {},
        drillingSetting: {},
        ncOutputSetting: {},
        integrationDeviceSetting: {},
        oneForTwoDeviceSetting: {},
      },
      oriAllData: {},
      showTiangong: false,
      // 记录是否可以进行保存
      ableSaveSetting: false,
      // 记录所有的可选贴标机格式
      allLabelingList: [],
      // 记录当前贴标机格式是否在返回的贴标机列表中
      labelNotOnline: false,
      isShowVisible: false,
      lockStatus: 1, // 锁状态
      verifyPassword: true,
      tempStatus: 1, //默认状态
      showRecordModal: false,
      isShowSecondaryCatalog: false, //是否显示二级目录，默认不勾选
    }
  },
  computed: {
    ...mapState(['userInfo', 'ncSetting']),
    paibanImgScale() {
      if (this.userInfo.panelSize) {
        let scale =
          this.userInfo.panelSize.plankHeight /
          this.userInfo.panelSize.plankWidth
        return scale
      } else {
        return 2
      }
    },
  },
  methods: {
    ...mapActions(['setNcSetting']),
    translateLang(key) {
      return translate(key)
    },
    async handleGetLockPassword() {
      await this.saveFun()
    },
    // 回到设备对接页面
    goBackPage() {
      this.nowPath = ''
      if (this.showTiangong) {
        this.$router.push('/equipment?from=tiangong')
      } else {
        this.$router.push('/equipment')
      }
    },
    // 根据模板字符串生成自定义文件名
    genNameArrFromTemplateString(templateString) {
      let nameArr = templateString.match(/\{(.+?)\}/g)
      if (!nameArr) return
      for (let i = 0; i < nameArr.length; i++) {
        nameArr[i] = nameArr[i].replace(/\{|\}/g, '')
      }
      return nameArr
    },

    // 处理开料对接数据
    dealCuttingDockData() {
      let cutDockData = JSON.parse(
        JSON.stringify(this.$refs.cutting_dock.formData)
      )
      if (cutDockData.firstKnifPreStartCodeInsert === '') {
        cutDockData.firstKnifPreStartCodeInsert = -1
      }
      if (cutDockData.preStartCodeInsert === '') {
        cutDockData.preStartCodeInsert = -1
      }
      let s = cutDockData.knives.reduce((prev, cur) => {
        // 修改此处的顺序，解决首次保存时，转json与原始数据对比时，顺序不一致的问题
        prev[cur.desc] = {
          name: cur.name,
          desc: cur.desc,
          diameter: cur.diameter,
          cutable: cur.cutable,
          slotable: cur.knifePurpose.includes('slotable'),
          holeable: cur.knifePurpose.includes('holeable'),
          millable: cur.knifePurpose.includes('millable'), // 该生产线第一次保存时，数据对比中，缺少该字段
          millableHole: cur.knifePurpose.includes('millableHole'),
          millableSlot: cur.knifePurpose.includes('millableSlot'),
          startCode: cur.startCode,
          stopCode: cur.stopCode,
          startCode2: cur.startCode2,
          stopCode2: cur.stopCode2,
          preStartCode: cur.preStartCode,
          cutF: Number(cur.cutF) == 0 ? '' : Number(cur.cutF),
          moveF: Number(cur.moveF) == 0 ? '' : Number(cur.moveF),
          cutZF: Number(cur.cutZF) == 0 ? '' : Number(cur.cutZF),
          moveZF: Number(cur.moveZF) == 0 ? '' : Number(cur.moveZF),
          [webId]: cur[webId],
        }
        return prev
      }, {})
      cutDockData.knives = s
      return cutDockData
    },
    // 处理切割参数数据
    dealCuttingParamsData() {
      // 这里处理下数据
      let cutParam = JSON.parse(
        JSON.stringify(this.$refs.cutting_params.formData)
      )
      cutParam.trimmingSetting.secondTrimValue = Number(
        cutParam.trimmingSetting.secondTrimValue
      )
      cutParam.cuttingPunchingSetting.idleZ = Number(
        cutParam.cuttingPunchingSetting.idleZ
      )
      cutParam.cuttingPunchingSetting.cutZ = Number(
        cutParam.cuttingPunchingSetting.cutZ
      )
      cutParam.processingSpeedSetting.knifeSpeed = Number(
        cutParam.processingSpeedSetting.knifeSpeed
      )
      cutParam.processingSpeedSetting.arcSpeed = Number(
        cutParam.processingSpeedSetting.arcSpeed
      )
      let angleDecelerationList = []
      for (
        let index = 0;
        index < cutParam.processingSpeedSetting.angle_deceleration_list.length;
        index++
      ) {
        if (
          cutParam.processingSpeedSetting.angle_deceleration_list[index]
            .flat(1)
            .every((e) => Number(e)) ||
          (cutParam.processingSpeedSetting.angle_deceleration_list.length ==
            1 &&
            !index) ||
          (cutParam.processingSpeedSetting.angle_deceleration_list[index]
            .flat(1)
            .every((e) => !Number(e)) &&
            !index)
        ) {
          angleDecelerationList.push(
            cutParam.processingSpeedSetting.angle_deceleration_list[index].map(
              (item) => {
                return Number(item)
              }
            )
          )
        }
      }
      // 对angleDecelerationList调整顺序 二维数组
      if (
        angleDecelerationList.length > 1 &&
        angleDecelerationList[0][0] > angleDecelerationList[1][0]
      ) {
        angleDecelerationList = angleDecelerationList.sort((a, b) => {
          return a[0] - b[0]
        })
      }
      cutParam.processingSpeedSetting.angle_deceleration_list =
        angleDecelerationList
      this.$refs.cutting_params.formData.processingSpeedSetting.angle_deceleration_list =
        cutParam.processingSpeedSetting.angle_deceleration_list
      if (
        !this.judgeangleDeceleration(
          cutParam.processingSpeedSetting.angle_deceleration_list
        )
      )
        return
      cutParam.processingSpeedSetting.angle_deceleration_dis = Number(
        cutParam.processingSpeedSetting.angle_deceleration_list[0][0]
      )
      cutParam.processingSpeedSetting.angle_deceleration_speed = Number(
        cutParam.processingSpeedSetting.angle_deceleration_list[0][1]
      )
      cutParam.layeredCuttingSetting.minSafeLength = Number(
        cutParam.layeredCuttingSetting.minSafeLength
      )
      cutParam.layeredCuttingSetting.minSafeArea = Number(
        cutParam.layeredCuttingSetting.minSafeArea
      )
      cutParam.layeredCuttingSetting.secondCuttingDepth = Number(
        cutParam.layeredCuttingSetting.secondCuttingDepth
      )
      cutParam.decimalSetting.decimal = Number(cutParam.decimalSetting.decimal)
      cutParam.coedgeCuttingSetting.coedgeSpeed = Number(
        cutParam.coedgeCuttingSetting.coedgeSpeed
      )
      cutParam.coedgeCuttingSetting.coedgeThick = Number(
        cutParam.coedgeCuttingSetting.coedgeThick
      )
      cutParam.boardGapsSetting.boardGap = Number(
        cutParam.boardGapsSetting.boardGap
      )
      cutParam.mentaojiSizeSetting.mentaojiSize = Number(
        cutParam.mentaojiSizeSetting.mentaojiSize
      )
      if (
        !cutParam.longPlankCuttingSetting.longPlankMinLength &&
        cutParam.longPlankCuttingSetting.longPlankMinLength != 0
      ) {
        cutParam.longPlankCuttingSetting.longPlankMinLength = ''
      }
      if (
        !cutParam.longPlankCuttingSetting.longPlankExpandedSize &&
        cutParam.longPlankCuttingSetting.longPlankExpandedSize != 0
      ) {
        cutParam.longPlankCuttingSetting.longPlankExpandedSize = ''
      } else {
        cutParam.longPlankCuttingSetting.longPlankExpandedSize = Number(
          cutParam.longPlankCuttingSetting.longPlankExpandedSize
        )
      }
      if (
        cutParam.longPlankCuttingSetting.longPlankShortExpandedSize ||
        cutParam.longPlankCuttingSetting.longPlankShortExpandedSize == 0
      ) {
        cutParam.longPlankCuttingSetting.longPlankShortExpandedSize = Number(
          cutParam.longPlankCuttingSetting.longPlankShortExpandedSize
        )
      }
      if (cutParam.coedgeCuttingSetting.coedgeAccelerate) {
        if (
          !cutParam.coedgeCuttingSetting.coedgeSpeed ||
          cutParam.coedgeCuttingSetting.coedgeSpeed == 0
        ) {
          this.$message({
            message: this.$t('cuttingDock.upSpeedTip'),
            type: 'error',
          })
          return
        }
      }
      if (cutParam.coedgeCuttingSetting.coedgeCutting) {
        if (
          !cutParam.coedgeCuttingSetting.coedgeThick ||
          cutParam.coedgeCuttingSetting.coedgeThick == 0
        ) {
          this.$message({
            message: this.$t('cuttingDock.cutThickTip'),
            type: 'error',
          })
          return
        }
      }
      cutParam.processingSpeedSetting.noOblique =
        !cutParam.processingSpeedSetting.noOblique
      return cutParam
    },
    // 处理贴标参数数据
    dealLabellingSettingData() {
      let labelingSetting = JSON.parse(
        JSON.stringify(this.$refs.labelling_setting.formData)
      )

      for (
        let i = 0;
        i < labelingSetting.layoutImageSetting.layoutImage.length;
        ++i
      ) {
        let paibanImg = labelingSetting.layoutImageSetting.layoutImage[i]
        if (paibanImg.enable) {
          if (
            paibanImg.imageSize[0] == '' ||
            paibanImg.imageSize[0] == 0 ||
            paibanImg.imageSize[1] == '' ||
            paibanImg.imageSize[1] == 0 ||
            paibanImg.imageSize[2] == '' ||
            paibanImg.imageSize[2] == 0
          ) {
            this.$message({
              message: this.$t('cuttingDock.sizeErrTip'),
              type: 'error',
            })
            return
          }
        }
      }
      let labellingSetting = JSON.parse(
        JSON.stringify(this.$refs.labelling_setting.formData)
      )
      if (!labellingSetting.label_picture_delimiter) {
        labellingSetting.label_picture_delimiter = 'underline'
      } else {
        labellingSetting.label_picture_delimiter = 'none'
      }

      return labellingSetting
    },
    // 是否可以保存设置
    checkIsCanSaveSetting() {
      return (
        this.$refs.labelling_setting.formData.label_image_name_type.length &&
        !this.$refs.nc_export.isContainsSpecialChars &&
        !this.$refs.nc_export.isNullFileSide
      )
    },
    // 是否可以保存设置 单面板
    checkIsCanSaveSettingSingle(cutParam, oneDragTwoData) {
      return (
        (cutParam.trimmingSetting.is_first_trim_single_panel &&
          !cutParam.trimmingSetting.single_panel_trim_direction) ||
        (oneDragTwoData.enableOneForTwoDevice &&
          oneDragTwoData.one_for_two_trimming_setting.one_for_two_trim &&
          oneDragTwoData.one_for_two_trimming_setting
            .one_for_two_single_panel_trim &&
          !oneDragTwoData.one_for_two_trimming_setting
            .one_for_two_single_panel_trim_direction)
      )
    },
    // 一体机设置
    dealAioMachineData() {
      if (
        !this.$refs.aioMachine.formData.trimming_knife_diameter &&
        this.$refs.aioMachine.formData.yitiji_machine_name == 'ruinuo'
      ) {
        this.$message({
          message: this.$t('cuttingDock.offEdgeKnifeDiameter'),
          type: 'error',
        })
        return false
      }
      // 一体机刀具编号重复或为空判断
      const knivesNameList =
        this.$refs.aioMachine.formData.anc_setting.knives.map(
          (item) => item.name
        )
      if (
        new Set(knivesNameList).size !== knivesNameList.length ||
        knivesNameList.some((item) => item == '')
      ) {
        this.$message.error('一体机刀具库编号重复或为空，请重新输入保存')
        return
      }
      const aioMachineData = JSON.parse(
        JSON.stringify({
          ...this.$refs.aioMachine.formData,
          aioOptions: this.$refs.aioMachine.aioOptions,
        })
      )
      let s = aioMachineData.anc_setting.knives.reduce((prev, cur) => {
        // 修改此处的顺序，解决首次保存时，转json与原始数据对比时，顺序不一致的问题
        prev[cur.name] = {
          name: cur.name,
          diameter: cur.diameter,
          slotable: cur.use.includes('slotable'),
          holeable: cur.use.includes('holeable'),
          use: cur.use,
          startCode: cur.startCode,
          stopCode: cur.stopCode,
          cutF: Number(cur.cutF) == 0 ? '' : Number(cur.cutF),
          moveF: Number(cur.moveF) == 0 ? '' : Number(cur.moveF),
          cutZF: Number(cur.cutZF) == 0 ? '' : Number(cur.cutZF),
          moveZF: Number(cur.moveZF) == 0 ? '' : Number(cur.moveZF),
          xOffset: Number(cur.xOffset) == 0 ? '' : Number(cur.xOffset),
          yOffset: Number(cur.yOffset) == 0 ? '' : Number(cur.yOffset),
          zOffset: Number(cur.zOffset) == 0 ? '' : Number(cur.zOffset),
          [webId]: cur[webId],
        }
        return prev
      }, {})
      aioMachineData.anc_setting.knives = s
      return aioMachineData
    },
    // 一拖二雕刻机
    dealOneDragTwoData() {
      let oneDragTwoData = JSON.parse(
        JSON.stringify(this.$refs.oneDragTwo.formData)
      )
      if (
        !this.$refs.oneDragTwo?.formData?.one_for_two_trimming_setting
          ?.one_for_two_trim
      ) {
        oneDragTwoData.trimmingSetting = JSON.parse(
          JSON.stringify(this.$refs.cutting_params.formData.trimmingSetting)
        )
        delete oneDragTwoData.trimmingSetting.trimSideInBluenNcSettings
      }
      return oneDragTwoData
    },
    // 处理NC导出设置
    dealNcExportSettingData() {
      let ncExportData = JSON.parse(
        JSON.stringify(this.$refs.nc_export.formData)
      )
      if (
        ncExportData.fileNameSetting.customizeSetting.hasOwnProperty(
          'stockNumLength'
        )
      ) {
        delete ncExportData.fileNameSetting.customizeSetting.stockNumLength
      }
      ncExportData.fileNameSetting.stockNumLength = Number(
        ncExportData.fileNameSetting.stockNumLength
      )
      return ncExportData
    },
    // 比较开料对接
    compareCuttingDock(current, ori) {
      // debugger
      // let record = []
      // 当前数据
      // const current = data.knivesSetting
      // 原始数据
      // const ori = oriData.knivesSetting
      // ------------
      // 门套机原始table、当前table

      const currentTableList = Object.values(current.knives)
      const oriTableList = Object.values(ori.knives)
      const currentSawTableList = Object.values(current.mentaoji_saws)
      const oriSawTableList = Object.values(ori.mentaoji_saws)
      // 给record添加表格操作记录
      // addOperateTableToRecord(
      //   currentTableList, // 当前板件列表
      //   oriTableList, // 原始板件列表
      //   Object.keys(currentTableList[0]),
      //   operateRecordTableType.KNIFE_STORE_TABLE,
      //   '刀库',
      //   record
      // )
      let compareMsg = [
        {
          title: '其他',
          keys: ['autoSendStockCode', 'pauseCode', 'evenAutoSendStockCode'],
        },
        {
          title: '设备类型',
          keys: ['single_double_station'],
        },
        {
          title: '双工位不同换刀开始代码',
          keys: ['open_two_station_code'],
        },
        {
          title: '工位开始',
          keys: ['oddBeginCode', 'evenBeginCode'],
        },
        {
          title: '正反面开始',
          keys: ['beginCodeF', 'beginCodeB'],
        },
        {
          title: '正反面结束',
          keys: ['endCodeF', 'endCodeB'],
        },
        {
          title: '板件翻面',
          keys: ['rollCode', 'evenRollCode'],
        },
        {
          title: '自动下料代码',
          keys: ['oddAutoBaitingCode', 'evenAutoBaitingCode'],
        },
        {
          title: '工位结束',
          keys: ['oddEndCode', 'evenEndCode'],
        },
        {
          title: '预启动设备',
          keys: ['firstKnifPreStartCodeInsert', 'preStartCodeInsert'],
        },
        {
          title: '真空泵暂停',
          keys: ['oddVacuumPumpPauseCode', 'evenVacuumPumpPauseCode'],
        },
        {
          title: '正面注释代码',
          keys: ['first_front_annotation_code', 'second_front_annotation_code'],
        },
        {
          title: '门套机',
          keys: ['mentaoji_enable'],
        },
      ]

      return {
        key: '开料对接',
        dataArr: [
          {
            type: 'table',
            list: currentTableList,
            oriList: oriTableList,
            keys: Object.keys(currentTableList[0]),
            tableType: operateRecordTableType.KNIFE_STORE_TABLE,
            excludeKeys: ['millable'],
            key: '刀库',
          },
          {
            type: 'table',
            list: currentSawTableList,
            oriList: oriSawTableList,
            keys: Object.keys(currentSawTableList[0]),
            tableType: operateRecordTableType.MENTAOJI_SAW_TABLE,
            key: '门套机',
          },
          {
            type: 'form',
            current,
            ori,
            compareMsg,
            formTextObj: cuttingDockFormTextObj,
            formValueObj: cuttingDockFormValueObj,
          },
        ],
      }
    },
    // 比较切割参数
    compareCuttingParams(current, ori) {
      // const record = []

      // 当前数据铺平
      const currentFlat = flatObj(current)
      // 原始数据铺平
      const oriFlat = flatObj(ori)
      const compareMsg = [
        {
          title: '孔槽集中面',
          keys: [
            'holeConcentrated',
            'apertureSlotConcentrated',
            'processChainAndHoleOnBigPlaneBack',
            'throughHoleSlotSameSide',
            'percentage_of_back_hole_slot',
            'prorate_holes_slots_of_both_side',
          ],
        },
        {
          title: '靠边板槽放置',
          keys: ['sideBoardPlacing'],
        },
        {
          title: '共边切割',
          keys: ['coedgeCutting', 'coedgeThick'],
        },
        {
          title: '共边加速',
          keys: ['coedgeAccelerate', 'coedgeSpeed'],
        },
        {
          title: '台面设置',
          keys: [
            'startPosition',
            'xyReverse',
            'doubleStationReverse',
            'planeOffset',
            'planeXOffset',
            'planeYOffset',
            'planeZOffset',
            'X_reference_swap',
            'x_reverse_reference_swap',
            'y_reverse_reference_swap',
            'doubleDifferentSize',
            'stationOneMax',
            'stationTwoMax',
            'smallStationFirst',
            'one_for_two_start_position',
          ],
        },
        {
          title: '修边设置',
          keys: [
            'trimSide',
            'trimLSingle',
            'trimLMoment',
            'secondTrimValue',
            'new_trim',
            'is_first_trim_single_panel',
            'single_panel_trim_direction',
          ],
        },
        {
          title: '余料设置',
          keys: ['surplusPosition'],
        },
        {
          title: '高光设置',
          keys: ['highgloss_direction'],
        },
        {
          title: '切割打孔方式',
          keys: [
            'zReference',
            'idleZ',
            'knifeBasedOnPlank',
            'cutZ',
            'throughTowSideToCut',
            'cutDirection',
            'cuttingCurvedInterpolationCommand',
            'noG17G18G19',
            'arcCuttingCode',
            'autoClearFilletSlot',
            'mill_slots_way',
            'through_slot_two_side_cut',
          ],
        },
        {
          title: '加工速度优化',
          keys: [
            'noOblique',
            'back_trim_oblique',
            'obliqueSpeed',
            'knifeSpeed',
            'arcSpeed',
            'cuttingPreDistance',
            'angle_deceleration_enabled',
            'angle_deceleration_dis',
            'angle_deceleration_speed',
            'openknifeSpeed',
            'angle_deceleration_list',
          ],
        },
        {
          title: '分层切割',
          keys: [
            'repeatedSlot',
            'repeatedCut',
            'minSafeLength',
            'minSafeArea',
            'secondCuttingDepth',
            'millingSlotOverlapRate',
            'layered_slot_cutting',
            'layered_slot_lt_knife_diameter',
            'layered_slot_gt_slot_deep',
            'layered_slot_second_slot_deep',
          ],
        },
        {
          title: '长板加工',
          keys: [
            'longPlankCuttingEnable',
            'longPlankMinLength',
            'longPlankShortMinLength',
            'cutSingleDoor',
            'cutGeneralPlank',
            'generalPlankThick',
            'longPlankExpandedSize',
            'longPlankShortExpandedSize',
            'vacuumPumpPauseWay',
          ],
        },

        {
          title: '小数保留位数',
          keys: ['decimal'],
        },
        {
          title: '下刀缓冲指令设置',
          keys: [
            'plankSurfaceBufferCommand',
            'hole_surface_buffer',
            'hole_slot_surface_buffer',
            'buffer_way',
          ],
        },
        {
          title: '排版间隙设置',
          keys: ['boardGap'],
        },
        {
          title: '开料分层切割设置',
          keys: ['isLayeredCut', 'trimmingFollowsLayeredCutting'],
        },
        {
          title: '防跑板设置',
          keys: ['newCutSequence'],
        },
        {
          title: '废料自动截断',
          keys: ['waste_cutting', 'waste_size', 'waste_order'],
        },
        {
          title: 'Z扣斜孔加工旋转基准点自定义',
          keys: [
            'ZK_datum_point_customize',
            'rotate_base_point',
            'rotate_angle_direction',
          ],
        },
        {
          title: '锯切设置',
          keys: ['mentaojiSize', 'mentaojiSizeSetting', 'noVerticalSaw'],
        },
      ]
      const currentTableList = current.layeredThickSetting.layeredThicks
      const oriTableList = ori.layeredThickSetting.layeredThicks
      return {
        // key: '开料对接',
        key: '切割参数',
        dataArr: [
          {
            type: 'form',
            current: currentFlat,
            ori: oriFlat,
            compareMsg,
            formTextObj: cuttingParamsFormTextObj,
            formValueObj: cuttingParamsFormValueObj,
          },
          {
            type: 'table',
            list: currentTableList,
            oriList: oriTableList,
            keys: [
              'matCode',
              'highlight',
              'pThick',
              'firstCut',
              'secondCut',
              'thirdCut',
            ],
            tableType: operateRecordTableType.CUTTING_PARAMS_PLANK_TABLE,
            key: '开料分层切割设置',
          },
        ],
      }
    },
    // 比较贴标设置
    compareLabellingSetting(current, ori) {
      // const record = []
      // 当前数据铺平
      const currentFlat = flatObj(current)
      // 原始数据铺平
      const oriFlat = flatObj(ori)
      const compareMsg = [
        {
          title: '', // 输出自动贴标文件
          keys: ['labelingMachine'],
        },
        {
          title: '翻面贴标设置', // 反面贴标设置
          keys: ['labelOverturn'],
        },
        {
          title: '贴标文件格式',
          keys: ['labelFormat', 'label_after_send_stock'], // 这个要处理一下
        },
        {
          title: '自动贴标顺序设置',
          keys: ['labelingOrder'],
        },
        {
          title: '贴标自动避让',
          keys: ['labelingDodge'],
        },
        {
          title: '标签旋转设置',
          keys: [
            'rotateLabel',
            'horizontal',
            'criticalValue',
            'parallel_plank',
            'parallel_approach',
          ],
        },
        {
          title: '台面设置',
          keys: ['labelingStartPosition', 'xDatumSwap', 'yDatumSwap'],
        },
        {
          title: '贴标坐标偏移',
          keys: ['labelXOffset', 'labelYOffset'],
        },
        {
          title: '贴标图片设置',
          keys: [
            'labelImageFormat',
            'labelImageFormatNew',
            'bmp_color_style',
            'label_picture_delimiter',
            'label_resolution',
            'num_auto_offset',
            'label_image_name_separator',
            'front_labeling_start_num',
            'label_img_big_plank_folder',
          ],
        },
        {
          title: '贴标图片名',
          keys: ['label_image_name_type'],
        },
      ]

      let labellingSettingFormValueObjTemp = JSON.parse(
        JSON.stringify(labellingSettingFormValueObj)
      )
      let obj = {}
      this.allLabelingList.forEach((item) => {
        obj[item.labelFormat] = item.modelName
      })
      // 添加一下 labelFormat 对应的value值（因为是动态的）
      labellingSettingFormValueObjTemp['labelFormat'] = obj

      // const formChangeArr = compareDiffForm(
      //   currentFlat,
      //   oriFlat,
      //   compareMsg,
      //   labellingSettingFormTextObj,
      //   labellingSettingFormValueObjTemp
      // )
      // if (formChangeArr.length) {
      //   record.push(...formChangeArr)
      // }

      // const imageDirectionValueObj = {
      //   vert: '竖向',
      //   horiz: '横向',
      // }
      const currentTableList = current.layoutImageSetting.layoutImage
      // currentTableList.forEach((item) => {
      //   item.imageDirection = imageDirectionValueObj[item.imageDirection]
      // })

      const oriTableList = ori.layoutImageSetting.layoutImage
      // oriTableList.forEach((item) => {
      //   item.imageDirection = imageDirectionValueObj[item.imageDirection]
      // })

      // 给record添加表格操作记录
      // addOperateTableToRecord(
      //   currentTableList, // 当前板件列表
      //   oriTableList, // 原始板件列表
      //   [
      //     'enable',
      //     'imageNamePrefix',
      //     'imageSize',
      //     'imageFormat',
      //     'imageDirection',
      //   ],
      //   operateRecordTableType.LAYOUT_SETTINGS_TABLE,
      //   '排版图设置',
      //   record
      // )
      // return record
      return {
        key: '贴标设置',
        dataArr: [
          {
            type: 'form',
            current: currentFlat,
            ori: oriFlat,
            compareMsg,
            formTextObj: labellingSettingFormTextObj,
            formValueObj: labellingSettingFormValueObjTemp,
          },
          {
            type: 'table',
            list: currentTableList,
            oriList: oriTableList,
            keys: [
              'enable',
              'imageNamePrefix',
              'imageSize',
              'imageFormat',
              'imageDirection',
            ],
            tableType: operateRecordTableType.LAYOUT_SETTINGS_TABLE,
            key: '排版图设置',
            idKey: 'webId',
          },
        ],
      }
    },
    // 比较排钻设置
    compareGangDrill(current, ori) {
      // const record = []
      const currentTableList = current.drills
      const oriTableList = ori.drills

      //排钻组合下刀从孔表格数据
      const currentGangDrillCombinationAuxiliaryList =
        current.gangDrillCombinationAuxiliaryList
      const oriGangDrillCombinationAuxiliaryList =
        ori.gangDrillCombinationAuxiliaryList

      // 给record添加表格操作记录
      // addOperateTableToRecord(
      //   currentTableList, // 当前板件列表
      //   oriTableList, // 原始板件列表
      //   [
      //     'isUsed',
      //     'number',
      //     'diameter',
      //     'xOffset',
      //     'yOffset',
      //     'moveZF',
      //     'feedRate',
      //     'moveF',
      //     '_selected',
      //   ],
      //   operateRecordTableType.KNIFE_TOOL_TABLE,
      //   '刀具列表',
      //   record
      // )

      const compareMsg = [
        {
          title: '启用排钻包',
          keys: ['enabled'],
        },
        {
          title: '',
          keys: ['prefix', 'suffix', 'beginCode', 'endCode'],
        },
        {
          title: '排钻组合下刀',
          keys: [
            'gangDrillCombinationEnable',
            'gangDrillCombinationMainPrefix',
            'gangDrillCombinationMainSuffix',
            'gangDrillCombinationComment',
            'gangDrillCombinationuCommentFirstSign',
          ],
        },
        {
          title: '加工设置',
          keys: ['drill_process_mode'],
        },
      ]

      // 比较form的不同项
      // const formChangeArr = compareDiffForm(
      //   current,
      //   ori,
      //   compareMsg,
      //   gangDrillFormTextObj
      // )
      // if (formChangeArr.length) {
      //   record.push(...formChangeArr)
      // }
      // return record

      return {
        key: '排钻设置',
        dataArr: [
          {
            type: 'table',
            list: currentTableList,
            oriList: oriTableList,
            keys: [
              'isUsed',
              'number',
              'number2',
              'diameter',
              'xOffset',
              'yOffset',
              'moveZF',
              'feedRate',
              'moveF',
              '_selected',
            ],
            tableType: operateRecordTableType.KNIFE_TOOL_TABLE,
            key: '刀具列表',
            idKey: '',
            tableIdentify: 'number',
          },
          {
            type: 'table',
            list: currentGangDrillCombinationAuxiliaryList,
            oriList: oriGangDrillCombinationAuxiliaryList,
            keys: ['knifePrefix', 'knifeSuffix', 'name'],
            tableType:
              operateRecordTableType.SIDE_HOLE_DRILL_SETTING_DRILL_COMBINATION_FROM_HOLE_TABLE,
            key: '从孔列表',
            idKey: '',
            tableIdentify: 'number',
            excludeKeys: ['name'],
          },
          {
            type: 'form',
            current,
            ori,
            compareMsg,
            formTextObj: gangDrillFormTextObj,
            // formValueObj: labellingSettingFormValueObjTemp
          },
        ],
      }
    },
    // 比较NC导出文件
    compareNcExportSetting(current, ori) {
      // 当前数据铺平
      let currentFlat = flatObj(current)
      // 原始数据铺平
      let oriFlat = flatObj(ori)

      currentFlat = {
        ...currentFlat,
        nameArr: this.genNameArrFromTemplateString(
          currentFlat.customizeSetting.templateString
        ),
        beforePlankAddCustomizeInfo:
          currentFlat?.beforePlankAddCustomizeInfo?.template_string,
      }
      oriFlat = {
        ...oriFlat,
        nameArr: this.genNameArrFromTemplateString(
          oriFlat.customizeSetting.templateString
        ),
        beforePlankAddCustomizeInfo:
          oriFlat?.beforePlankAddCustomizeInfo?.template_string,
      }

      // const record = []
      const compareMsg = [
        {
          title: '开料机文件名设置',
          keys: [
            'doubleStationSortName',
            'is_generate_process_sort_number',
            'stockNumLength',
            'labelMachineName',
            'machineCenterName',
            'front',
            'back',
            'nameArr',
            'hasDelimiter',
            'front_info',
            'back_info',
          ],
        },
        {
          title: '开料机文件合并',
          keys: [
            'doubleEdgeCombine',
            'bEnd1',
            'fBegin1',
            'displayfbInfo',
            'doubleStationCombine',
            'bBegin2',
            'bEnd2',
            'fBegin2',
            'fEnd2',
            'singleStationCombine',
            'bBegin3',
            'bEnd3',
            'fBegin3',
            'fEnd3',
          ],
        },
        {
          title: '开料机文件中注释',
          keys: [
            'beforePlankAddInfo',
            'beforePlankAddCustomizeInfo',
            'beforePlank',
            'beforeStartKnife',
          ],
        },
        {
          title: '开料机文件中类型',
          keys: [
            'alwaysZ',
            'semicolon',
            'exportUsingBanlu',
            'exportFormat',
            'exportFormatAnnotation',
            'fileNameType',
            'always_back_nc',
            'fileExtensionName',
          ],
        },
        {
          title: '开料机文件夹',
          keys: ['useCategoryFolder', 'same_size_sheet_cutting_files'],
        },
        {
          title: '加工指令设置',
          keys: ['GCommandShorthand', 'z_to_w_normal', 'z_to_w_gang_drill'],
        },
        {
          title: '文件格式设置',
          keys: ['platform', 'fileEncoding', 'fileSuffix'],
        },
        {
          title: '板件条码设置',
          keys: ['genSimpleLabelBarcode', 'uniqueBarCode'],
        },
        {
          title: '代码位置设置',
          keys: ['stationEndCodeBeforeEndCodeB'],
        },
        {
          title: '应急设置',
          keys: ['settingKey', 'param1', 'param2', 'param3'],
        },
        {
          title: '打孔文件设置',
          keys: ['output56FDrillSortByCode'],
        },
      ]

      // 比较form的不同项
      // const formChangeArr = compareDiffForm(
      //   currentFlat,
      //   oriFlat,
      //   compareMsg,
      //   NcExportSettingFormTextObj,
      //   NcExportSettingFormValueObj
      // )
      // if (formChangeArr.length) {
      //   record.push(...formChangeArr)
      // }
      // return record

      return {
        key: 'NC导出设置',
        dataArr: [
          {
            type: 'form',
            current: currentFlat,
            ori: oriFlat,
            compareMsg,
            formTextObj: NcExportSettingFormTextObj,
            formValueObj: NcExportSettingFormValueObj,
          },
        ],
      }
    },
    // 比较一拖二雕刻机
    compareOneDragTwoEngraving(current, ori) {
      const compareMsg = [
        {
          title: '输出一拖二雕刻机加工文件',
          keys: ['enableOneForTwoDevice'],
        },
        {
          title: '文件格式',
          keys: ['oneForTwoMachineName'],
        },
        {
          title: '工位起始点',
          keys: [
            'enable_one_for_two_start_position',
            'one_for_two_start_position',
          ],
        },
        {
          title: '修边设置',
          keys: [
            'one_for_two_single_panel_trim',
            'one_for_two_single_panel_trim_direction',
            'one_for_two_trimming_setting',
          ],
        },
        {
          title: '其他',
          keys: ['secondAutoSendStockCode', 'secondPauseCode'],
        },
        {
          title: '工位开始',
          keys: ['secondOddBeginCode', 'secondEvenBeginCode'],
        },
        {
          title: '正反面开始',
          keys: ['secondBeginCodeF', 'secondBeginCodeB'],
        },
        {
          title: '正反面结束',
          keys: ['secondEndCodeF', 'secondEndCodeB'],
        },
        {
          title: '板件翻面',
          keys: ['secondRollCode', 'secondEvenRollCode'],
        },
        {
          title: '工位结束',
          keys: ['secondOddEndCode', 'secondEvenEndCode'],
        },
      ]
      return {
        key: '一拖二雕刻机',
        dataArr: [
          {
            type: 'form',
            current,
            ori,
            compareMsg,
            formTextObj: OneDragTwoEngravingTextObj,
            formValueObj: OneDragTwoEngravingValueObj,
          },
        ],
      }
    },
    // 比较一体机
    compareAioMachineSetting(current, ori) {
      const compareMsg = [
        {
          title: '输出一体机加工文件',
          keys: ['enable_integration_device'],
        },
        {
          title: '文件格式',
          keys: ['yitiji_machine_name'],
        },
        {
          title: '修边刀具直径',
          keys: ['trimming_knife_diameter'],
        },
        {
          title: '显示数据样例',
          keys: ['is_display_sample'],
        },
        {
          title: '程序开始代码',
          keys: ['anc_setting'],
        },
      ]
      return {
        key: '一体机设置',
        dataArr: [
          {
            type: 'form',
            current: {
              ...current,
              yitiji_machine_name: current.aioOptions.find(
                (item) => item.value == current.yitiji_machine_name
              ).label,
              anc_setting: {
                ...current.anc_setting,
                knives: '',
              },
            },
            ori: {
              ...ori,
              yitiji_machine_name: current.aioOptions.find(
                (item) => item.value == ori.yitiji_machine_name
              ).label,
              anc_setting: {
                ...ori.anc_setting,
                knives: '',
              },
            },
            compareMsg,
            formTextObj: AioMachineSettingTextObj,
            formValueObj: AioMachineSettingValueObj,
          },
          {
            type: 'table',
            list: current.anc_setting.knives
              ? Object.values(current.anc_setting.knives)
              : [],
            oriList: ori.anc_setting.knives
              ? Object.values(ori.anc_setting.knives)
              : [],
            keys: [
              'name',
              'diameter',
              'use',
              'moveZF',
              'cutZF',
              'moveF',
              'cutF',
              'xOffset',
              'yOffset',
              'zOffset',
              'startCode',
              'stopCode',
            ],
            tableType: operateRecordTableType.MODEL_AIO_TABLE,
            key: '刀具',
          },
        ],
      }
    },
    judgeangleDeceleration(list) {
      let isAbleSave = true
      if (!list.length) {
        isAbleSave = false
      } else if (list.length == 1) {
        isAbleSave = list[0].every((item) => Number(item))
      } else {
        isAbleSave =
          list.flat(1).every((item) => Number(item)) &&
          list[0][0] < list[1][0] &&
          list[0][1] < list[1][1]
      }
      if (
        !this.$refs.cutting_params.formData.processingSpeedSetting
          .angle_deceleration_enabled
      ) {
        isAbleSave = true
      }
      if (!isAbleSave) {
        this.$message({
          message: this.$t('cuttingDock.slowDownSpeedTip'),
          type: 'error',
        })
      }
      return isAbleSave
    },
    saveDataJsonToOssForRecordFn(record, option, cb) {
      saveDataJsonToOssForRecord(record, 'equipment_docking', option, cb)
    },
    /**
     *
     * @param {*} current // 修改后的生产线设置
     * @param {*} ori // 上一次保存的生产线设置
     * @param {*} productionLineName // 当前生产线名
     */
    async genFinalRecordFn(
      current,
      ori,
      productionLineName,
      saveData,
      callback = null
    ) {
      this.reSetLabelPictureDelimiter(current)
      const recordData = {
        productionLineName,
        key: '雕刻机', // 操作记录最外层的名字，比如：雕刻机
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          this.compareCuttingDock(current.cutDockData, ori.knivesSetting),
          this.compareCuttingParams(current.cutParam, ori.cuttingSetting),
          this.compareLabellingSetting(
            current.labelingSetting,
            ori.labelingSetting
          ),
          this.compareGangDrill(current.drillingSetting, ori.drillingSetting),
          this.compareNcExportSetting(
            current.ncOutputSetting,
            ori.ncOutputSetting
          ),
          this.compareOneDragTwoEngraving(
            current.oneForTwoDeviceSetting,
            ori.oneForTwoDeviceSetting
          ),
          this.compareAioMachineSetting(
            current.integrationDeviceSetting,
            ori.integrationDeviceSetting
          ),
        ],
      }
      try {
        const res = await genFinalRecordPromise(recordData, 'equipment_docking')
        if (res) {
          const { record, finalId, action_type } = res
          saveDataJsonToOssForRecord(
            record,
            action_type,
            {
              id: finalId,
              operate_equipment: 'engraving',
              save_data: saveData,
              setting_id: this.$route.query.setting_id,
            },
            () => {
              callback && callback()
              this.loadEngraving()
            }
          )
        }
      } catch (error) {
        console.error('error', error)
        this.$message({
          message: '生成操作记录失败',
          type: 'error',
        })
      }
    },
    // 保存配置
    saveEngraving() {
      // 判断锁定状态，打开弹窗
      // 根据获取的状态 锁定状态
      this.lockStatus = this.tempStatus
      if (this.showTiangong) {
        this.$message({
          message: this.$t('cuttingDock.permissionErr'),
          type: 'info',
          duration: 1500,
          offset: 60,
        })
        return
      }
      const cutForm = this.$refs.cutting_params.formData

      if (!cutForm.trimmingSetting.trimSideInBluenNcSettings) {
        modalMsg(
          this.$t('cuttingDock.firstSave'),
          'warning',
          this.$t('common.confirm'),
          this.saveFun
        )
        return
      } else {
        this.saveFun()
      }
    },
    // 打开修改记录弹窗
    handleOpenChangeRecord() {
      this.showRecordModal = true
    },
    // 保存处理数据
    saveFun() {
      this.$nextTick(async () => {
        // 处理开料对接的数据
        const cutDockData = this.dealCuttingDockData()
        if (!cutDockData) {
          return
        }
        // 处理切割参数
        const cutParam = this.dealCuttingParamsData()
        if (!cutParam) {
          return
        }

        if (
          !this.$refs.labelling_setting.formData.labelFormat &&
          this.$refs.labelling_setting.formData.labelingMachine
        ) {
          this.$message({
            message: this.$t('cuttingDock.labellingTempErr'),
            type: 'error',
          })
          return
        }

        this.$refs.nc_export.dealNameArr()
        // 处理nc导出设置
        let ncExportData = this.dealNcExportSettingData()
        // 处理贴标设置
        const labellingSetting = this.dealLabellingSettingData()

        if (!labellingSetting) {
          return
        }
        // 检查是否可以保存
        const canSave = this.checkIsCanSaveSetting()
        if (!canSave) {
          if (
            this.$refs.nc_export.isContainsSpecialChars ||
            this.$refs.nc_export.isNullFileSide
          ) {
            this.nowPage = 'NcExportSetting'
          }
          if (
            !this.$refs.labelling_setting.formData.label_image_name_type.length
          ) {
            this.nowPage = 'labellingSetting'
          }
          window.localStorage.setItem('engravingNav', this.nowPage)
          this.$nextTick(() => {
            this.scrollToElement()
          })
          return
        }
        if (
          labellingSetting.rotateLabelSetting.rotateLabel &&
          !Number(labellingSetting.rotateLabelSetting.criticalValue)
        ) {
          this.$refs.labelling_setting.focusInput()
          return this.$message.error(this.$t('cuttingDock.autoRollInputTip'))
        }
        labellingSetting.rotateLabelSetting.criticalValue = Number(
          labellingSetting.rotateLabelSetting.criticalValue
        )
        // 一体机设置
        const aioMachineData = this.dealAioMachineData()
        if (!aioMachineData) return
        // 排钻设置数据
        const drillData = this.dealDrillData()
        // 一拖二数据
        const oneDragTwoData = this.dealOneDragTwoData()
        // 获取到了切割参数和一拖二的数据，需要判断它们是否开启单面加工板生成正面修边路径且先修边，如果开启了，修边方向不能为空
        if (this.checkIsCanSaveSettingSingle(cutParam, oneDragTwoData)) {
          if (
            cutParam.trimmingSetting.is_first_trim_single_panel &&
            !cutParam.trimmingSetting.single_panel_trim_direction
          ) {
            this.nowPage = 'cuttingParams'
          } else if (
            oneDragTwoData.enableOneForTwoDevice &&
            oneDragTwoData.one_for_two_trimming_setting.one_for_two_trim &&
            oneDragTwoData.one_for_two_trimming_setting
              .one_for_two_single_panel_trim &&
            !oneDragTwoData.one_for_two_trimming_setting
              .one_for_two_single_panel_trim_direction
          ) {
            this.nowPage = 'oneDragTwoEngraving'
          }
          window.localStorage.setItem('engravingNav', this.nowPage)
          this.$nextTick(() => {
            let dom = document.getElementById(`singlePanelTrim_${this.nowPage}`)
            // dom.scrollIntoView({
            //   behavior: 'smooth',
            //   block: 'start',
            // })
            scrollToElementFn(dom)
          })
          return this.$message({
            message: '单面加工板正面生成修边路径且先修边方向不能为空！',
            type: 'warning',
          })
        }
        // 切割参数 开料分层切割的数据验证
        if (this.checkIsCanSaveSettingCutting(cutParam)) {
          this.nowPage = 'cuttingParams'
          window.localStorage.setItem('engravingNav', this.nowPage)
          this.$nextTick(() => {
            let dom = document.getElementById(
              'engraving_setting_plankTable_div'
            )
            scrollToElementFn(dom)
          })
          return this.$message({
            message:
              this.checkIsCanSaveSettingCutting(cutParam) == 'repeat'
                ? this.$t('cuttingDock.cuttingParams.saveLayeredRepeatTip')
                : this.$t('cuttingDock.cuttingParams.saveLayeredCuttingTip'),
            type: 'warning',
          })
        }
        labellingSetting.layoutImageSetting.layoutImage.forEach(
          (item, index) => {
            item.way = index + 1
          }
        )
        // 是否可以保存 切割参数-分层切割-铣槽拉槽
        if (this.checkIsCanSaveSettingSlots(cutParam)) {
          // 存在部分数据不符合规范，滑动定位到有问题的地方
          this.nowPage = 'cuttingParams'
          window.localStorage.setItem('engravingNav', this.nowPage)
          // 定位不符合填写规范的元素
          let dom = document.getElementById('hierarchy-cutting')
          this.$nextTick(() => {
            scrollToElementFn(dom)
          })
          let message =
            Number(cutParam.layeredCuttingSetting.layered_slot_gt_slot_deep) <=
            Number(cutParam.layeredCuttingSetting.layered_slot_second_slot_deep)
              ? '当铣槽拉槽分层切割开启时，切割深度不能大于槽深度！'
              : '当拉槽铣槽分层切割开启时，填写数据不能为空！'
          return this.$message({
            message: message,
            type: 'warning',
          })
        }
        const obj = {
          knivesSetting: cutDockData,
          cuttingSetting: cutParam,
          labelingSetting: labellingSetting,
          drillingSetting: drillData,
          ncOutputSetting: ncExportData,
          integrationDeviceSetting: aioMachineData,
          oneForTwoDeviceSetting: oneDragTwoData,
        }
        let data = JSON.stringify({
          data: obj,
          setting_id: this.$route.query.setting_id,
          ...store.state.saveInfo,
          platform: 'bluen',
        })
        this.ableSaveSetting = false
        this.$store.commit('setCurrentProductLineName', this.$route.query.line)
        this.$token('/save_engraving_setting', data, async (res) => {
          this.ableSaveSetting = true
          if (res.status === 1) {
            // 保存成功后，工位1，2双面加工正面注释代码增加埋点（修改次数）
            this.handleAddAnnotationCodeBuryPoint(cutDockData, this.oriAllData)
            // 埋点处理
            this.handleAddXYSwapBuryPoint(cutParam, this.oriAllData)
            // 生成最终的操作记录
            try {
              await this.genFinalRecordFn(
                {
                  cutDockData,
                  cutParam,
                  labelingSetting: labellingSetting,
                  drillingSetting: drillData,
                  ncOutputSetting: ncExportData,
                  integrationDeviceSetting: aioMachineData,
                  oneForTwoDeviceSetting: oneDragTwoData,
                },
                this.oriAllData,
                this.$route.query.line,
                obj
              )
            } catch (error) {
              console.error('error', error)
            }
            this.isShowVisible = false
            if (res.data.phone && res.data.request_ip) {
              let recordData = {
                key: '保存设置',
                operateArr: [
                  {
                    key: '生产线设置',
                    dataArr: [
                      {
                        type: 'form',
                        current: {
                          phone: res.data.phone,
                          time: new Date().toLocaleString(),
                          id: store.state.userInfo.id,
                          ip: res.data.request_ip,
                        },
                        ori: {
                          phone: '',
                          time: '',
                          id: '',
                          ip: '',
                        },
                        compareMsg: [
                          {
                            title: '',
                            keys: ['phone', 'time', 'id', 'ip'],
                          },
                        ],
                        formTextObj: {
                          phone: '手机号',
                          time: '保存时间',
                          id: '用户id',
                          ip: '物理地址',
                        },
                        formValueObj: {},
                      },
                    ],
                  },
                ],
              }
              genFinalRecord(recordData, 'equipment_docking', '', () => {})
            }
            this.$message({
              type: 'success',
              message: this.$t('common.saveSuccess'),
              duration: 1500,
              offset: 70,
            })
            // 由于在保存成功后，没有重新去获取数据，所以需要手动更新原始数据（建议调用接口，重新获取数据）
            this.oriAllData = JSON.parse(JSON.stringify(obj))
            // 如果保存成功用户修改了贴标起始值直接去贴标管理页数据
            if (
              this.ncSetting.process_setting_id ||
              this.$route.query.setting_id == this.ncSetting.process_setting_id
            ) {
              // 更新贴标起始值
              this.$store.commit(
                'setFrontLabelingStartNum',
                labellingSetting.front_labeling_start_num
              )
            }
          } else if (res.status == -1) {
            if (store.state.saveInfo.phone) {
              this.$message({
                message: res.msg,
                type: 'error',
                duration: 1500,
                offset: 70,
              })
            }
            this.showSaveModal()
          } else {
            this.$message({
              type: 'error',
              message: res?.msg || this.$t('lineManager.permissionTip'),
              duration: 1500,
              offset: 70,
            })
          }
          store.commit('setSaveInfo', {})
        })
      })
    },
    // 检测是否可以保存 切割参数-分层切割-铣槽拉槽
    checkIsCanSaveSettingSlots(cutParam) {
      return (
        (cutParam.layeredCuttingSetting.layered_slot_cutting &&
          (!cutParam.layeredCuttingSetting.layered_slot_lt_knife_diameter ||
            !cutParam.layeredCuttingSetting.layered_slot_gt_slot_deep ||
            !cutParam.layeredCuttingSetting.layered_slot_second_slot_deep)) ||
        (cutParam.layeredCuttingSetting.layered_slot_cutting &&
          Number(cutParam.layeredCuttingSetting.layered_slot_gt_slot_deep) <=
            Number(
              cutParam.layeredCuttingSetting.layered_slot_second_slot_deep
            ))
      )
    },
    // 切割参数-开料分层切割
    checkIsCanSaveSettingCutting(cutParam) {
      // cutParam.layeredThickSetting.layeredThicks
      // 当开关是关闭状态
      if (!cutParam.layeredThickSetting.isLayeredCut) return
      const compareStringList = []
      for (
        let i = 0;
        i < cutParam.layeredThickSetting.layeredThicks.length;
        i++
      ) {
        let { matCode, highlight, pThick } =
          cutParam.layeredThickSetting.layeredThicks[i]
        let item = cutParam.layeredThickSetting.layeredThicks[i]
        if (!matCode) {
          matCode = ''
        }
        let key = `${pThick}_${highlight}_${matCode}`
        // 如果能在数组中找到数据，结束循环
        if (compareStringList.includes(key)) return 'repeat'
        compareStringList.push(key)
        if (
          Math.abs(
            Number(item['pThick']) -
              (Number(item['firstCut']) +
                Number(item['secondCut']) +
                Number(item['thirdCut']))
          ) > Number.EPSILON
        )
          return 'verify'
      }
    },
    showSaveModal() {
      this.lockStatus = this.tempStatus
      this.verifyPassword = true
      this.isShowVisible = true
      return
    },
    // 排钻设置数据处理
    dealDrillData() {
      const gangDrill = this.$refs['gang_drill']
      let formData = { ...gangDrill.formData }
      const drillTable = gangDrill.drillTableData
      const mainDrill = drillTable.find((e) => e.name === '主孔')
      formData.gangDrillCombinationAuxiliaryList = drillTable
        .filter((e) => e.name.includes('从孔'))
        .map((e) => ({
          knifePrefix: e.prefix,
          knifeSuffix: e.suffix,
          webId: e.webId,
          name: e.name,
        }))
      formData.gangDrillCombinationEnable = gangDrill.isDriliingChecked
      formData.gangDrillCombinationMainPrefix = mainDrill.prefix
      formData.gangDrillCombinationMainSuffix = mainDrill.suffix
      formData.gangDrillCombinationComment = drillTable.find(
        (e) => e.name === '组合注释'
      ).prefix
      formData.gangDrillCombinationuCommentFirstSign = drillTable.find(
        (e) => e.name === '首次启用注释'
      ).prefix
      return formData
    },
    // 切换导航栏
    async changeNav(item) {
      if (item.val === 'labellingSetting' && !thirdPermission('/labeling'))
        return
      if (item.val === 'aioMachineSetting') {
        const oneMacRes = await getOneMacLimit({
          setting_id: this.$route.query.setting_id,
        })
        // 接口data返回false 代表一体机没有权限
        if (oneMacRes?.data === false) {
          this.$message({
            type: 'info',
            // 暂无此权限, 请升级账号版本!
            message: this.translateLang('lineManager.permissonErr'),
            duration: 1500,
            offset: 70,
          })
          return
        }
      }
      this.nowPage = item.val
      window.localStorage.setItem('engravingNav', this.nowPage)
    },
    // 为数据添加所需要的id
    addIdToNeedDataForAllData(allData) {
      // 为设备对接-门套机-开料对接-门套机列表 添加唯一id
      // addIdForItems(Object.values(allData.knivesSetting.mentaoji_saws))
      addIdForItems(allData.knivesSetting.mentaoji_saws)
      // 为设备对接-雕刻机-开料对接-刀库列表 添加唯一id
      addIdForItems(Object.values(allData.knivesSetting.knives))
      // 为设备对接-雕刻机-切割参数-板件列表 添加唯一id
      addIdForItems(allData.cuttingSetting.layeredThickSetting.layeredThicks)
      // 为设备对接-雕刻机-贴标设置-排版图设置 添加唯一id
      addIdForItems(allData.labelingSetting.layoutImageSetting.layoutImage)
      // 为设备对接-雕刻机-排钻设置-刀具 添加唯一id
      addIdForItems(allData.drillingSetting.drills)
      // 为设备对接-雕刻机-排钻设置-排钻组合下刀-从孔 添加唯一id
      addIdForItems(allData.drillingSetting.gangDrillCombinationAuxiliaryList)
    },
    // 读取配置
    loadEngraving() {
      this.$getByToken(
        '/labeling_list',
        {
          from: 'user',
        },
        (result) => {
          if (result.status == 1) {
            this.ableSaveSetting = false
            this.$token(
              '/load_engraving_setting',
              {
                setting_id: this.$route.query.setting_id,
              },
              (res) => {
                if (res.status == 1) {
                  this.ableSaveSetting = true
                  this.allLabelingList = result.data.model_list
                  let data = this.allLabelingList.find(
                    (v) => v.labelFormat == res.data.labelingSetting.labelFormat
                  )
                  if (!data) {
                    this.labelNotOnline = true
                    this.$message({
                      message: this.$t('cuttingDock.offlineTempFormat'),
                      type: 'info',
                    })
                    res.data.labelingSetting.labelFormat = ''
                  }
                  this.reSetLabelPictureDelimiter(res.data)
                  // if (!onlyRefreshOriAllData) {
                  this.allData = res.data
                  // 兼容切割转角速度
                  if (
                    !this.allData.cuttingSetting.processingSpeedSetting
                      .angle_deceleration_list
                  ) {
                    this.allData.cuttingSetting.processingSpeedSetting.angle_deceleration_list =
                      [
                        [
                          this.allData.cuttingSetting.processingSpeedSetting
                            .angle_deceleration_dis,
                          this.allData.cuttingSetting.processingSpeedSetting
                            .angle_deceleration_speed,
                        ],
                      ]
                  }
                  this.addIdToNeedDataForAllData(this.allData)
                  this.oriAllData = JSON.parse(JSON.stringify(this.allData))
                } else {
                  this.ableSaveSetting = false
                }
              }
            )
          }
        }
      )
    },
    // 重置 label_picture_delimiter
    reSetLabelPictureDelimiter(data) {
      // 后端数据为字符串，需要用checkbox 需要转换为boolean类型
      if (data.labelingSetting.label_picture_delimiter == 'underline') {
        data.labelingSetting.label_picture_delimiter = false
      } else if (data.labelingSetting.label_picture_delimiter == 'none') {
        data.labelingSetting.label_picture_delimiter = true
      }
    },
    scrollToElement() {
      const engravingNav = localStorage.getItem('engravingNav')
      const scrollToElementMap = {
        NcExportSetting: {
          domFileNameType: 'ncExportSetting_fileNameType_input',
          domFileSide: 'engraving_setting_exportSetting_head_side',
        },
        labellingSetting: {
          domLabelImage: 'labelling_setting_labelImageName',
        },
      }
      const isNcExportSetting = engravingNav === 'NcExportSetting'
      const isLabellingSetting = engravingNav === 'labellingSetting'
      const ncExportDomFileNameType = document.getElementById(
        scrollToElementMap[engravingNav].domFileNameType
      )
      const ncExportDomFileSide = document.getElementById(
        scrollToElementMap[engravingNav].domFileSide
      )
      const labellingSettingDom = document.getElementById(
        scrollToElementMap[engravingNav].domLabelImage
      )

      if (isNcExportSetting) {
        if (this.$refs.nc_export.isContainsSpecialChars) {
          scrollToElementFn(ncExportDomFileNameType)
        }
        if (this.$refs.nc_export.isNullFileSide) {
          scrollToElementFn(ncExportDomFileSide)
        }
      }

      if (
        isLabellingSetting &&
        !this.$refs.labelling_setting.formData.label_image_name_type.length
      ) {
        scrollToElementFn(labellingSettingDom)
      }
    },
    handleGetLockStatus() {
      //TODO 进入页面，获取生产线设置锁的状态
      getLockStatus().then((res) => {
        // 拿到的锁状态赋值，当前是锁定还是解锁状态
        this.lockStatus = res?.data?.is_lock || 0
        this.tempStatus = res?.data?.is_lock || 0
      })
    },
    // 工位1，2双面正面加工注释代码埋点
    handleAddAnnotationCodeBuryPoint(data, oriData) {
      const { first_front_annotation_code, second_front_annotation_code } = data
      const {
        first_front_annotation_code: ori_first_front_annotation_code,
        second_front_annotation_code: ori_second_front_annotation_code,
      } = oriData.knivesSetting
      if (first_front_annotation_code !== ori_first_front_annotation_code) {
        // TODO: 增加工位1修改次数埋点
        buryPointApi(
          'equipment_dock',
          'engraving_first_front_annotation_code_change'
        )
      }
      if (second_front_annotation_code !== ori_second_front_annotation_code) {
        // TODO: 增加工位2修改次数埋点
        buryPointApi(
          'equipment_dock',
          'engraving_second_front_annotation_code_change'
        )
      }
    },
    // 大板反面xy基准互调埋点
    handleAddXYSwapBuryPoint(data, oriData) {
      const { x_reverse_reference_swap, y_reverse_reference_swap } =
        data.countertopSetting
      const {
        x_reverse_reference_swap: ori_X_reverse_reference_swap,
        y_reverse_reference_swap: ori_Y_reverse_reference_swap,
      } = oriData.cuttingSetting.countertopSetting
      if (x_reverse_reference_swap !== ori_X_reverse_reference_swap) {
        buryPointApi('equipment_dock', 'x_reverse_reference_swap')
      }
      if (y_reverse_reference_swap !== ori_Y_reverse_reference_swap) {
        buryPointApi('equipment_dock', 'y_reverse_reference_swap')
      }
    },
    // 还原、重置参数成功后的回调
    async handleSuccess(data) {
      const {
        cuttingSetting: cutParam,
        drillingSetting: drillData,
        integrationDeviceSetting: aioMachineData,
        knivesSetting: cutDockData,
        labelingSetting: labellingSetting,
        ncOutputSetting: ncExportData,
        oneForTwoDeviceSetting: oneDragTwoData,
      } = data
      const obj = {
        knivesSetting: cutDockData,
        cuttingSetting: cutParam,
        labelingSetting: labellingSetting,
        drillingSetting: drillData,
        ncOutputSetting: ncExportData,
        integrationDeviceSetting: aioMachineData,
        oneForTwoDeviceSetting: oneDragTwoData,
      }
      // 生成最终的操作记录
      try {
        await this.genFinalRecordFn(
          {
            cutDockData,
            cutParam,
            labelingSetting: labellingSetting,
            drillingSetting: drillData,
            ncOutputSetting: ncExportData,
            integrationDeviceSetting: aioMachineData,
            oneForTwoDeviceSetting: oneDragTwoData,
          },
          this.oriAllData,
          this.$route.query.line,
          data,
          () => {
            // 生产记录成功后,重新回去操作记录数据
            this.$refs['recordModalRef']?.getData({ isReset: true })
          }
        )
      } catch (error) {}
      // 由于在保存成功后，没有重新去获取数据，所以需要手动更新原始数据（建议调用接口，重新获取数据）
      this.oriAllData = JSON.parse(JSON.stringify(obj))
      // 如果保存成功用户修改了贴标起始值直接去贴标管理页数据
      if (
        this.ncSetting.process_setting_id ||
        this.$route.query.setting_id == this.ncSetting.process_setting_id
      ) {
        // 更新贴标起始值
        this.$store.commit(
          'setFrontLabelingStartNum',
          labellingSetting.front_labeling_start_num
        )
      }
    },
  },
  mounted() {
    this.handleGetLockStatus()
    // 不使用缓存里的设备菜单
    // if (window.localStorage.getItem('engravingNav')) {
    //   this.nowPage = window.localStorage.getItem('engravingNav')
    // }
    if (this.$route.query.from) {
      if (this.$route.query.from == 'tiangong') {
        this.showTiangong = true
      }
    } else {
      this.showTiangong = false
      this.loadEngraving()
      // 读取二级目录展示请求
      getPreferencesSetting(
        window.sessionStorage.getItem('thinkerx_material'),
        store.state.userInfo.platform
      ).then((res) => {
        if (res.status == 1) {
          // 不存在的时候使用默认值
          this.isShowSecondaryCatalog =
            res.data.engravSwitchCloseAlwaysShow ?? true
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
.engraving {
  min-width: 720px;
  height: 100%;

  .top-nav {
    .nav-item {
      display: inline-block;
      box-sizing: border-box;
      margin-right: 16px;
      padding: 13px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      cursor: pointer;
    }

    .active-nav {
      box-sizing: border-box;
      color: #18a8c7;
      border-bottom: 3px solid #18a8c7;
    }
  }
  .wrap {
    height: 0;
    padding: 16px;
    // min-width: 1703px;
    // overflow-x: auto;
  }
}
</style>
