<template>
  <div class="cutting-params h100">
    <div class="w100 h100 flex">
      <AnchorNav :getContainer="() => container" :navs="navs" />
      <div class="wrap h100 y-auto" ref="wrap">
        <div class="hole-slot-focus" id="hole-slot-focus">
          <span class="head-title">
            {{ $t('cuttingDock.cuttingParams.holeSlotMainSide') }}
            <span
              style="
                margin-left: 20px;
                color: #8c8c8c;
                font-weight: 500;
                font-size: 14px;
              "
            >
              {{ $t('cuttingDock.cuttingParams.workPriority') }}
            </span>
          </span>
          <div class="content ml8">
            <div class="flex flex-cross--center">
              <span class="base_title">{{
                $t('cuttingDock.cuttingParams.holeMainSide')
              }}</span>
              <a-radio-group
                v-model="formData.holeFaceSetting.holeConcentrated"
                :disabled="
                  formData.holeFaceSetting.prorate_holes_slots_of_both_side
                "
              >
                <a-radio
                  value="front"
                  class="ml20"
                  id="engraving_setting_holeConcentrated_front_radio"
                  >{{ $t('common.front') }}</a-radio
                >
                <a-radio
                  value="back"
                  id="engraving_setting_holeConcentrated_back_radio"
                  >{{ $t('common.back') }}</a-radio
                >
              </a-radio-group>
            </div>
            <div class="flex flex-cross--center">
              <span class="base_title">{{
                $t('cuttingDock.cuttingParams.slotMainSide')
              }}</span>
              <a-radio-group
                v-model="formData.holeFaceSetting.apertureSlotConcentrated"
                :disabled="
                  formData.holeFaceSetting.prorate_holes_slots_of_both_side
                "
              >
                <a-radio
                  value="front"
                  class="ml20"
                  id="engraving_setting_apertureSlotConcentrated_front_radio"
                  >{{ $t('common.front') }}</a-radio
                >
                <a-radio
                  value="back"
                  id="engraving_setting_apertureSlotConcentrated_back_radio"
                  >{{ $t('common.back') }}</a-radio
                >
              </a-radio-group>
            </div>
            <div>
              <span class="base_title mr14">{{
                $t('cuttingDock.cuttingParams.hingeRollWork')
              }}</span>
              <a-checkbox
                v-model="
                  formData.holeFaceSetting.processChainAndHoleOnBigPlaneBack
                "
                id="engraving_setting_processChainAndHoleOnBigPlaneBack_checkbox"
              ></a-checkbox>
            </div>
            <div>
              <span class="base_title mr14">{{
                $t('cuttingDock.cuttingParams.followWork')
              }}</span>
              <a-checkbox
                v-model="formData.holeFaceSetting.throughHoleSlotSameSide"
                id="engraving_setting_throughHoleSlotSameSide_checkbox"
              ></a-checkbox>
            </div>
            <div class="allocation flex flex-cross--center color-o-9">
              <span class="base_title">{{
                $t('cuttingDock.cuttingParams.autoMatchSide')
              }}</span>
              <a-switch
                v-model="
                  formData.holeFaceSetting.prorate_holes_slots_of_both_side
                "
                id="engraving_setting_prorate_holes_slots_of_both_side_switch"
                size="small"
              ></a-switch>
              <div
                v-show="
                  isShowSecondaryCatalog ||
                  formData.holeFaceSetting.prorate_holes_slots_of_both_side
                "
              >
                <span class="mr8">{{
                  $t('cuttingDock.cuttingParams.backHSRate')
                }}</span>
                <input-number-suffix
                  min="0"
                  max="100"
                  suffix="%"
                  v-model="
                    formData.holeFaceSetting.percentage_of_back_hole_slot
                  "
                  id="engraving_setting_percentage_of_back_hole_slot_input"
                  :disabled="
                    !formData.holeFaceSetting.prorate_holes_slots_of_both_side
                  "
                ></input-number-suffix>
                <span class="edge-again-info">{{
                  $t('cuttingDock.cuttingParams.matchTip')
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="board-slot-place" id="board-slot-place">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.highGlossSetting')
          }}</span>
          <div class="content ml8">
            <div class="flex">
              <div class="base_title mr8">
                {{ $t('cuttingDock.cuttingParams.heighGlossFront') }}
              </div>
              <a-radio-group
                name="radioGroup"
                v-model="formData.highgloss_setting.highgloss_direction"
              >
                <a-radio
                  v-for="option in plateGighGlossOptions"
                  :id="`newPaiban_select_highlight_surface_radio${option.label}`"
                  :value="option.value"
                  :key="option.value"
                  >{{ $t(option.label) }}</a-radio
                >
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="board-slot-place" id="board-slot-place">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.sideSlotPut')
          }}</span>
          <div class="flex content">
            <span class="base_title">{{
              $t('cuttingDock.cuttingParams.sideSlotPut')
            }}</span>
            <template slot="title">{{
              $t('cuttingDock.cuttingParams.optimize')
            }}</template>
            <a-radio-group
              v-model="formData.edgePlankSlotDirection.sideBoardPlacing"
              class="radioBox flex"
            >
              <span class="mo"
                ><a-radio
                  value="default"
                  id="engraving_setting_sideBoardPlacing_default_radio"
                  >{{ $t('cuttingDock.cuttingParams.sysDefault') }}</a-radio
                ></span
              >
              <span class="mo"
                ><a-radio
                  value="inside"
                  id="engraving_setting_sideBoardPlacing_inside_radio"
                  >{{ $t('cuttingDock.cuttingParams.plankInSide') }}</a-radio
                ></span
              >
              <span class="mo"
                ><a-radio
                  value="outside"
                  id="engraving_setting_sideBoardPlacing_outside_radio"
                  >{{ $t('cuttingDock.cuttingParams.plankOutSide') }}</a-radio
                ></span
              >
            </a-radio-group>

            <span style="color: #ed7b2f"
              >({{ $t('cuttingDock.cuttingParams.specialNotWork') }})</span
            >
          </div>
          <div class="flex mt16 content">
            <img
              src="../../assets/image/insideSlot.png"
              class="imgWrap mr20"
              style="display: none"
            />
            <div class="flex flex-dir--top img-box p13 mr20">
              <img src="../../assets/image/inside.png" class="imgWrap" />
              <span class="align-center mt11 color-o-6">{{
                $t('cuttingDock.cuttingParams.plankInSide')
              }}</span>
            </div>
            <div class="flex flex-dir--top img-box p13">
              <img src="../../assets/image/outside.png" class="imgWrap" />
              <span class="align-center mt11 color-o-6">{{
                $t('cuttingDock.cuttingParams.plankOutSide')
              }}</span>
            </div>
          </div>
        </div>
        <div class="common-side-cut" id="common-side-cut">
          <div class="head-title flex flex-cross--center">
            <span class="mr8">{{
              $t('cuttingDock.cuttingParams.commonSide')
            }}</span>
            <a-switch
              v-model="formData.coedgeCuttingSetting.coedgeCutting"
              id="engraving_setting_coedgeCutting_switch"
            />
          </div>

          <div
            class="content-box ml8"
            v-show="
              isShowSecondaryCatalog ||
              formData.coedgeCuttingSetting.coedgeCutting
            "
          >
            <div class="common-side-limit mb16">
              <span class="base_title mr8">{{
                $t('cuttingDock.cuttingParams.plankThickRange')
              }}</span>
              <input-number-suffix
                v-model="formData.coedgeCuttingSetting.coedgeThick"
                :disabled="!formData.coedgeCuttingSetting.coedgeCutting"
                id="engraving_setting_coedgeThick_input"
              ></input-number-suffix>
              <span class="ml8">{{
                $t('cuttingDock.cuttingParams.useCommonSide')
              }}</span>
              <span>{{ $t('cuttingDock.cuttingParams.zeroTip') }}</span>
              <span style="color: #ed7b2f"
                >({{ $t('cuttingDock.cuttingParams.useTip') }})</span
              >
            </div>
          </div>
        </div>
        <div class="common-side-accelerate" id="common-side-accelerate">
          <span class="head-title">
            <span class="mr8">{{
              $t('cuttingDock.cuttingParams.commonSpeed')
            }}</span>
            <a-switch
              id="engraving_setting_coedgeAccelerate_switch"
              v-model="formData.coedgeCuttingSetting.coedgeAccelerate"
            />
          </span>
          <div
            class="content-box ml8"
            v-show="
              isShowSecondaryCatalog ||
              formData.coedgeCuttingSetting.coedgeAccelerate
            "
          >
            <div class="speed-limit mb16">
              <span class="base_title mr8">{{
                $t('cuttingDock.cuttingParams.highSpeed')
              }}</span>
              <input-number-suffix
                width="140px"
                id="engraving_setting_coedgeSpeed_input"
                v-model="formData.coedgeCuttingSetting.coedgeSpeed"
                :disabled="!formData.coedgeCuttingSetting.coedgeAccelerate"
                :suffix="`mm/${$t('cuttingDock.cuttingParams.min')}`"
              >
              </input-number-suffix>
              <span></span>
              <span>{{ $t('cuttingDock.cuttingParams.notWorkTip') }}</span>
            </div>
          </div>
        </div>
        <div class="table-board" id="table-board">
          <span class="head-title">{{
            $t('cuttingDock.labelingSettings.countertopSetting.title')
          }}</span>
          <div class="base_title ml8">
            <div class="start-point flex flex-cross--center">
              <span class="base_title mr8"
                >{{
                  $t(
                    'cuttingDock.labelingSettings.countertopSetting.startPoint'
                  )
                }}
              </span>
              <div class="table-board-radio base_title">
                <span
                  :class="
                    formData.countertopSetting.startPosition == item
                      ? 'active-radio'
                      : ''
                  "
                  class="ellipsis"
                  v-for="(item, index) in startPoint"
                  :key="index"
                  :id="`engraving_setting_startPosition_${index}_btn`"
                  @click="chosePoint(item)"
                  :title="translateLang(startPointMap[index])"
                  >{{ $t(startPointMap[index]) }}
                </span>
              </div>
              <span class="ml10 mr10"> （ {{ positionDisValue }} ） </span>
            </div>
            <div class="toggle-XY">
              <span class="base_title mr14">{{ $t('common.xyReverse') }}</span>
              <a-checkbox
                id="engraving_setting_xyReverse_checkbox"
                v-model="formData.countertopSetting.xyReverse"
              ></a-checkbox>
            </div>
            <div class="double-start-point" v-if="showConfig">
              <span class="base_title mr14">{{
                $t('cuttingDock.cuttingParams.changeStartPoint')
              }}</span>
              <a-checkbox
                id="engraving_setting_doubleStationReverse_checkbox"
                v-model="formData.countertopSetting.doubleStationReverse"
              ></a-checkbox>
              <span style="color: #ed7b2f" class="ml10"
                >({{
                  $t('cuttingDock.cuttingParams.changeStartPointTip')
                }})</span
              >
            </div>
            <div class="machine-board">
              <div class="board-switch">
                <span class="base_title mr8">{{
                  $t('cuttingDock.cuttingParams.flatOffset')
                }}</span>
                <a-switch
                  id="engraving_setting_planeOffset_switch"
                  v-model="formData.countertopSetting.planeOffset"
                  size="small"
                ></a-switch>
              </div>
            </div>
            <div
              class="flex"
              v-if="
                isShowSecondaryCatalog || formData.countertopSetting.planeOffset
              "
            >
              <div
                v-for="(item, index) in planeOffsetOptions"
                class="board-offset flex flex-cross--center"
                :key="index"
                style="width: 130px"
                :style="{ margin: index === 1 ? '0 40px' : '' }"
              >
                <span class="fs14 mr12">{{ item.label }}: </span>
                <a-input
                  :placeholder="$t('common.inputPlaceholder')"
                  v-model="formData.countertopSetting[item.key]"
                  :disabled="!formData.countertopSetting.planeOffset"
                  :id="`engraving_setting_${item.key}_input`"
                  @input="
                    inputCheck('countertopSetting', 'planeXOffset', false, 1)
                  "
                ></a-input>
              </div>
            </div>
            <div class="toggle-XY">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.xRowChange')
              }}</span>
              <a-checkbox
                id="engraving_setting_X_reference_swap_switch"
                v-model="formData.countertopSetting.X_reference_swap"
              ></a-checkbox>
              <span class="ml10 color-8"
                >({{ $t('cuttingDock.cuttingParams.plankMirrorTip') }})</span
              >
            </div>
            <div class="flex switch-class">
              <span class="mr14" style="white-space: nowrap"
                >{{
                  $t('cuttingDock.cuttingParams.bigplankPointChange')
                }}:</span
              >
              <a-checkbox
                style="white-space: nowrap"
                id="engraving_setting_X_reference_swap_switch"
                v-model="formData.countertopSetting.x_reverse_reference_swap"
              >
                {{ $t('cuttingDock.cuttingParams.reverseXRowChange') }}
              </a-checkbox>
              <a-checkbox
                style="white-space: nowrap"
                id="engraving_setting_Y_reference_swap_switch"
                v-model="formData.countertopSetting.y_reverse_reference_swap"
              >
                {{ $t('cuttingDock.cuttingParams.reverseYRowChange') }}
              </a-checkbox>
              <span style="color: #ed7b2f">
                ({{ $t('cuttingDock.cuttingParams.changebigplankPointTips') }})
              </span>
            </div>
            <div class="mr15 flex switch-class" v-if="showConfig">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.notSameSize')
              }}</span>
              <a-switch
                id="engraving_setting_doubleDifferentSize_switch"
                v-model="formData.countertopSetting.doubleDifferentSize"
                size="small"
              ></a-switch>
            </div>
            <div
              v-show="
                isShowSecondaryCatalog ||
                formData.countertopSetting.doubleDifferentSize
              "
            >
              <div
                class="flex dual-input mb20 flex-cross--center"
                v-if="showConfig"
              >
                <span class="mr10"
                  >{{ $t('cuttingDock.cuttingParams.maxSize1') }}:
                </span>
                <a-input
                  suffix="mm"
                  placeholder="请输入"
                  id="engraving_setting_stationOneMax_input"
                  @input="
                    handleDualInputValue(
                      formData.countertopSetting,
                      'stationOneMax'
                    )
                  "
                  :disabled="!formData.countertopSetting.doubleDifferentSize"
                  v-model="formData.countertopSetting.stationOneMax"
                ></a-input>
                <span style="color: #ed7b2f">{{
                  $t('cuttingDock.cuttingParams.notSameSizeTip')
                }}</span>
              </div>
              <div class="flex dual-input flex-cross--center" v-if="showConfig">
                <span class="mr10"
                  >{{ $t('cuttingDock.cuttingParams.maxSize2') }}:
                </span>
                <a-input
                  suffix="mm"
                  :placeholder="$t('common.inputPlaceholder')"
                  id="engraving_setting_stationTwoMax_input"
                  :disabled="!formData.countertopSetting.doubleDifferentSize"
                  v-model="formData.countertopSetting.stationTwoMax"
                  @input="
                    handleDualInputValue(
                      formData.countertopSetting,
                      'stationTwoMax'
                    )
                  "
                ></a-input>
                <span class="color-8">{{
                  $t('cuttingDock.cuttingParams.autoSelectTip')
                }}</span>
              </div>
            </div>
            <div
              class="small-title"
              style="font-size: 14px"
              v-show="
                (formData.countertopSetting.doubleDifferentSize ||
                  isShowSecondaryCatalog) &&
                showConfig
              "
            >
              {{ $t('cuttingDock.cuttingParams.bigFlatLimit') }}
              <a-checkbox
                id="cuttingParams_doubleDifferentSize_checkbox"
                v-model="formData.countertopSetting.smallStationFirst"
                :disabled="!formData.countertopSetting.doubleDifferentSize"
              >
              </a-checkbox>
            </div>
          </div>
        </div>

        <MTrimSetting
          style="padding-left: 24px"
          :trimming-setting="formData.trimmingSetting"
          :propertyName="propertyName"
          source="cuttingParams"
          @onUpdateTrimSetting1="onUpdateTrimSetting"
        ></MTrimSetting>

        <div class="lave-off" id="surplus-setting">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.surplusSetting')
          }}</span>
          <div class="lave-off-setting-img ml8">
            <div class="lave-off-setting">
              <div class="start-point flex flex-cross--center color-o-9">
                <span class="base_title mr14"
                  >{{ $t('cuttingDock.cuttingParams.surplusPosition') }}
                </span>
                <div class="table-board-radio">
                  <span
                    :class="
                      formData.surplusSetting.surplusPosition == item.value
                        ? 'active-radio'
                        : ''
                    "
                    class="ellipsis"
                    v-for="(item, index) in lavePoints"
                    :key="index"
                    @click="choselavePoint(item.value)"
                    :id="`engraving_setting_lavePoint_${index}_btn`"
                    :title="translateLang(item.label)"
                    >{{ $t(item.label) }}</span
                  >
                </div>
              </div>
            </div>
            <div class="lave-off-img">
              <img
                :src="laveImg(formData.surplusSetting.surplusPosition)"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="cut-hole-way" id="cut-hole-way">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.cutHoleWay')
          }}</span>
          <div class="cut-hole ml14">
            <div class="base_title">
              <span class="mr20"
                >{{ $t('cuttingDock.cuttingParams.knifeFlat') }}
              </span>
              <a-radio-group
                v-model="formData.cuttingPunchingSetting.zReference"
              >
                <a-radio
                  value="counter_top"
                  id="engraving_setting_zReference_counter_top_radio"
                  ><span
                    id="engraving_setting_zReference_counter_top_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.flat') }}</span
                  ></a-radio
                >
                <a-radio
                  value="plank_surface"
                  id="engraving_setting_zReference_plank_surface_radio"
                  ><span
                    id="engraving_setting_zReference_plank_surface_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.plankFlat') }}</span
                  ></a-radio
                >
              </a-radio-group>
            </div>
            <div class="base_title">
              <span class="mr8"
                >{{ $t('cuttingDock.cuttingParams.freeHeightZ') }}(H)</span
              >
              <a-input
                suffix="mm"
                style="width: 100px"
                id="engraving_setting_idleZ_input"
                v-model="formData.cuttingPunchingSetting.idleZ"
                @input="inputCheck('cuttingPunchingSetting', 'idleZ', false, 1)"
              ></a-input>
              <a-checkbox
                id="engraving_setting_knifeBasedOnPlank_checkbox"
                class="knifeBasedOnPlank"
                v-model="formData.cuttingPunchingSetting.knifeBasedOnPlank"
                >{{
                  $t('cuttingDock.cuttingParams.relativeHeight')
                }}</a-checkbox
              >
            </div>
            <div class="base_title">
              <span class="mr8"
                >{{ $t('cuttingDock.cuttingParams.cutDeepZ') }}(D)</span
              >
              <a-input
                suffix="mm"
                style="width: 100px"
                id="engraving_setting_cutZ_input"
                v-model="formData.cuttingPunchingSetting.cutZ"
                @input="inputCheck('cuttingPunchingSetting', 'cutZ', false, 1)"
              ></a-input>
            </div>
            <div class="base_title">
              <span class="mr20"
                >{{ $t('cuttingDock.cuttingParams.cutDeepH') }}
              </span>
              <a-radio-group
                v-model="formData.cuttingPunchingSetting.throughTowSideToCut"
              >
                <a-radio
                  :value="false"
                  id="engraving_setting_throughTowSideToCut_false_radio"
                  ><span
                    id="engraving_setting_throughTowSideToCut_false_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.onceThrough') }}</span
                  ></a-radio
                >
                <a-radio
                  :value="true"
                  id="engraving_setting_throughTowSideToCut_true_radio"
                  ><span
                    id="engraving_setting_throughTowSideToCut_true_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.rollThrough') }}</span
                  ></a-radio
                >
              </a-radio-group>
            </div>
            <div class="base_title">
              <span class="mr20"
                >{{ $t('cuttingDock.cuttingParams.cutDeepS') }}
              </span>
              <a-radio-group
                v-model="
                  formData.cuttingPunchingSetting.through_slot_two_side_cut
                "
              >
                <a-radio
                  :value="false"
                  id="engraving_setting_throughSlotToCut_false_radio"
                  ><span
                    id="engraving_setting_throughSlotToCut_false_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.onceThrough') }}</span
                  ></a-radio
                >
                <a-radio
                  :value="true"
                  id="engraving_setting_throughSlotToCut_true_radio"
                  ><span
                    id="engraving_setting_throughSlotToCut_true_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.rollThrough') }}</span
                  ></a-radio
                >
              </a-radio-group>
            </div>
            <div class="base_title">
              <span class="mr20"
                >{{ $t('cuttingDock.cuttingParams.plankCutDirection') }}
              </span>
              <a-radio-group
                v-model="formData.cuttingPunchingSetting.cutDirection"
              >
                <a-radio
                  value="顺时针"
                  id="engraving_setting_cutDirection_s_radio"
                  ><span id="engraving_setting_cutDirection_s_radio_label">{{
                    $t('cuttingDock.cuttingParams.sDirection')
                  }}</span></a-radio
                >
                <a-radio
                  value="逆时针"
                  id="engraving_setting_cutDirection_n_radio"
                  ><span id="engraving_setting_cutDirection_n_radio_label">{{
                    $t('cuttingDock.cuttingParams.nDirection')
                  }}</span></a-radio
                >
              </a-radio-group>
            </div>

            <div class="base_title">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.curveReplenish')
              }}</span>
              <a-checkbox
                v-model="
                  formData.cuttingPunchingSetting
                    .cuttingCurvedInterpolationCommand
                "
                id="engraving_setting_cuttingCurvedInterpolationCommand_checkbox"
              ></a-checkbox>
              <span class="ml14 color-o-6" style="font-weight: 400"
                >({{ $t('cuttingDock.cuttingParams.curveReplenishTip') }})</span
              >
            </div>
            <div class="base_title">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.noGen')
              }}</span>
              <a-checkbox
                v-model="formData.cuttingPunchingSetting.noG17G18G19"
                :disabled="
                  !formData.cuttingPunchingSetting
                    .cuttingCurvedInterpolationCommand
                "
                id="engraving_setting_noG17G18G19_checkbox"
              ></a-checkbox>
            </div>
            <div class="base_title">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.curveCutTip')
              }}</span>
              <a-checkbox
                id="engraving_setting_arcCuttingCode_checkbox"
                v-model="formData.cuttingPunchingSetting.arcCuttingCode"
              ></a-checkbox>
            </div>
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.coverRate') }}</span>
              <a-input
                suffix="%"
                style="width: 100px"
                v-model="millingSlotOverlapRate"
                :max-length="2"
                defaultValue="0"
                id="engraving_setting_millingSlotOverlapRate_input"
              ></a-input>
              <span style="margin-left: 16px; color: #8c8c8c"
                >({{ $t('cuttingDock.cuttingParams.roughTip') }})</span
              >
            </div>
            <!--  铣槽时未拉穿的槽自动清理圆角 -->
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.clearCurve') }}</span>
              <a-checkbox
                id="cuttingParams_autoClearFilletSlot_checkbox"
                v-model="formData.cuttingPunchingSetting.autoClearFilletSlot"
              ></a-checkbox>
              <span style="margin-left: 16px; color: #8c8c8c"
                >({{ $t('cuttingDock.cuttingParams.curveUseTip') }})</span
              >
            </div>
            <div class="base_title">
              <span class="mr20"
                >{{ $t('cuttingDock.cuttingParams.slotCutWay') }}
              </span>
              <a-radio-group
                v-model="formData.cuttingPunchingSetting.mill_slots_way"
                class="m0"
              >
                <a-radio
                  value="out_to_inner"
                  id="engraving_setting_millSlotsWay_out2inner_radio"
                  ><span
                    id="engraving_setting_millSlotsWay_out2inner_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.outToIn') }}</span
                  ></a-radio
                >
                <a-radio
                  value="inner_to_out"
                  id="engraving_setting_millSlotsWay_inner2out_radio"
                  ><span
                    id="engraving_setting_millSlotsWay_inner2out_radio_label"
                    >{{ $t('cuttingDock.cuttingParams.inToOut') }}</span
                  ></a-radio
                >
              </a-radio-group>
              <span style="color: #8c8c8c">{{
                $t('cuttingDock.cuttingParams.slotWorkLimit')
              }}</span>
            </div>
          </div>
        </div>
        <div class="optimize-speed" id="optimize-speed">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.workSpeedOp')
          }}</span>
          <div class="content ml8">
            <div class="base_title">
              <span class="small-plank mr14">{{
                $t('cuttingDock.cuttingParams.obliqueCut')
              }}</span>
              <a-switch
                id="engraving_setting_noOblique_checkbox"
                v-model="formData.processingSpeedSetting.noOblique"
                size="small"
              ></a-switch>
            </div>
            <div class="base_title">
              <span class="small-plank mr14">{{
                $t('cuttingDock.cuttingParams.backOblique')
              }}</span>
              <a-switch
                id="engraving_setting_backTrimOblique_checkbox"
                v-model="formData.processingSpeedSetting.back_trim_oblique"
                size="small"
              ></a-switch>
            </div>
            <div class="base_title">
              <span class="small-plank">{{
                $t('cuttingDock.cuttingParams.obliqueSpeed')
              }}</span>
              <a-input
                id="engraving_setting_obliqueSpeed_input"
                v-model="formData.processingSpeedSetting.obliqueSpeed"
                @input="
                  inputCheck('processingSpeedSetting', 'obliqueSpeed', false)
                "
              ></a-input>
            </div>

            <div class="base_title">
              <span class="small-plank">{{
                $t('cuttingDock.cuttingParams.arcSpeed')
              }}</span>
              <a-input
                id="engraving_setting_arcSpeed_input"
                v-model="formData.processingSpeedSetting.arcSpeed"
                @input="inputCheck('processingSpeedSetting', 'arcSpeed', true)"
              ></a-input>
            </div>
            <div class="base_title">
              <span class="small-plank">{{
                $t('cuttingDock.cuttingParams.cuttingPreDistance')
              }}</span>
              <a-input
                id="engraving_setting_cuttingPreDistance_input"
                v-model="formData.processingSpeedSetting.cuttingPreDistance"
                @input="
                  inputCheck(
                    'processingSpeedSetting',
                    'cuttingPreDistance',
                    false,
                    2,
                    true
                  )
                "
              ></a-input>
            </div>
            <div class="base_title">
              <span class="small-plank">{{
                $t('cuttingDock.cuttingParams.backKnife')
              }}</span>
              <a-switch
                id="engraving_setting_openknifeSpeed_switch"
                v-model="formData.processingSpeedSetting.openknifeSpeed"
                size="small"
              ></a-switch>
              <div
                v-show="
                  isShowSecondaryCatalog ||
                  formData.processingSpeedSetting.openknifeSpeed
                "
              >
                <span>{{
                  $t('cuttingDock.cuttingParams.openKnifeSpeed')
                }}</span>
                <a-input
                  :disabled="!formData.processingSpeedSetting.openknifeSpeed"
                  v-model="formData.processingSpeedSetting.knifeSpeed"
                  id="engraving_setting_openknifeSpeed_input"
                  @input="
                    inputCheck('processingSpeedSetting', 'knifeSpeed', true)
                  "
                ></a-input>
              </div>
            </div>
            <div class="base_title mb16">
              <span class="small-plank">{{
                $t('cuttingDock.cuttingParams.cutCornerSlow')
              }}</span>
              <a-switch
                id="engraving_setting_angle_deceleration_enabled_switch"
                v-model="
                  formData.processingSpeedSetting.angle_deceleration_enabled
                "
                size="small"
              ></a-switch>
              <span
                class="base-btn pointer"
                @click="handelAddAngleDeceleration"
                v-show="
                  !isShowBaseBtn &&
                  (isShowSecondaryCatalog ||
                    formData.processingSpeedSetting.angle_deceleration_enabled)
                "
              >
                {{ $t('cuttingDock.cuttingParams.addRow') }}
              </span>
            </div>
            <template
              v-if="
                isShowSecondaryCatalog ||
                formData.processingSpeedSetting.angle_deceleration_enabled
              "
            >
              <div
                class="base_title mb16"
                v-for="(item, index) in formData.processingSpeedSetting
                  .angle_deceleration_list"
                :key="index"
              >
                <span>{{
                  $t('cuttingDock.cuttingParams.bettwenDistance')
                }}</span>
                <a-input
                  id="engraving_setting_angle_deceleration_dis_input"
                  suffix="mm"
                  class="mr20"
                  v-model="item[0]"
                  :disabled="
                    !formData.processingSpeedSetting.angle_deceleration_enabled
                  "
                  style="width: 100px"
                  @change="
                    handleInputVal(
                      'processingSpeedSetting',
                      'angle_deceleration_list',
                      index,
                      0
                    )
                  "
                ></a-input>
                <span>{{ $t('cuttingDock.cuttingParams.cuttingSpeed') }}</span>
                <a-input
                  id="engraving_setting_angle_deceleration_speed_input"
                  :disabled="
                    !formData.processingSpeedSetting.angle_deceleration_enabled
                  "
                  v-model="item[1]"
                  @change="
                    handleInputVal(
                      'processingSpeedSetting',
                      'angle_deceleration_list',
                      index,
                      1
                    )
                  "
                ></a-input>
                <span
                  class="base-btn pointer ml16"
                  @click="handelDeleteAngleDeceleration(index)"
                  v-show="isShowBaseBtn"
                >
                  {{ $t('common.delete') }}
                </span>
              </div>
            </template>
          </div>
        </div>
        <div class="hierarchy-cut" id="hierarchy-cutting">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.stepCutting')
          }}</span>
          <div class="hierarchy-cut-content ml8">
            <div class="base_title">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.splitSlot')
              }}</span>
              <a-checkbox
                id="engraving-setting_repeatedSlot_checkbox"
                v-model="formData.layeredCuttingSetting.repeatedSlot"
              ></a-checkbox>
            </div>
            <div class="hierarchy-cut-twice base_title mt20 mb16">
              <div class="pt8">
                <span class="mr14">{{
                  $t('cuttingDock.cuttingParams.splitPlank')
                }}</span>
                <a-switch
                  id="engraving_setting_repeatedCut_switch"
                  v-model="formData.layeredCuttingSetting.repeatedCut"
                  size="small"
                ></a-switch>
              </div>
              <div
                class="hierarchy-cut-limit"
                v-show="
                  isShowSecondaryCatalog ||
                  formData.layeredCuttingSetting.repeatedCut
                "
              >
                <div>
                  <span class="mr8">{{
                    $t('cuttingDock.cuttingParams.maxWH')
                  }}</span>
                  <a-input
                    suffix="mm"
                    style="width: 100px"
                    id="engraving_setting_minSafeLength_input"
                    v-model="formData.layeredCuttingSetting.minSafeLength"
                    @input="
                      inputCheck('layeredCuttingSetting', 'minSafeLength', true)
                    "
                    :disabled="!formData.layeredCuttingSetting.repeatedCut"
                  ></a-input>
                  <span
                    >&nbsp;{{ $t('cuttingDock.cuttingParams.areaLimit') }}</span
                  >
                  <a-input
                    suffix="m²"
                    style="width: 100px"
                    id="engraving_setting_minSafeArea_input"
                    v-model="formData.layeredCuttingSetting.minSafeArea"
                    @input="
                      inputCheck(
                        'layeredCuttingSetting',
                        'minSafeArea',
                        false,
                        1
                      )
                    "
                    :disabled="!formData.layeredCuttingSetting.repeatedCut"
                  ></a-input>
                  <span
                    >&nbsp;{{
                      $t('cuttingDock.cuttingParams.splitPlank')
                    }}</span
                  >
                </div>
                <div class="secondCutting mb16">
                  <span class="mr8">{{
                    $t('cuttingDock.cuttingParams.secondDeep')
                  }}</span>
                  <a-input
                    id="engraving_setting_secondCuttingDepth_input"
                    style="width: 100px"
                    v-model="formData.layeredCuttingSetting.secondCuttingDepth"
                    @input="
                      inputDepth('layeredCuttingSetting', 'secondCuttingDepth')
                    "
                    :disabled="!formData.layeredCuttingSetting.repeatedCut"
                    :maxLength="7"
                    suffix="mm"
                  ></a-input>
                </div>
              </div>
            </div>
            <!-- 拉槽铣槽分层切割 -->
            <div class="hierarchy-cut-twice base_title mt20 mb16">
              <div class="pt8">
                <span class="mr14">拉槽铣槽分层切割</span>
                <a-switch
                  v-model="formData.layeredCuttingSetting.layered_slot_cutting"
                  id="engraving_setting_layered_slot_cutting_switch"
                  size="small"
                ></a-switch>
              </div>
              <div
                class="hierarchy-cut-limit"
                v-show="
                  formData.layeredCuttingSetting.layered_slot_cutting ||
                  isShowSecondaryCatalog
                "
              >
                <div>
                  <span class="mr8">刀径小于等于</span>
                  <a-input
                    suffix="mm"
                    style="width: 100px"
                    :value="
                      formData.layeredCuttingSetting
                        .layered_slot_lt_knife_diameter
                    "
                    @input="
                      handleCheckInput('layered_slot_lt_knife_diameter', $event)
                    "
                    :disabled="
                      !formData.layeredCuttingSetting.layered_slot_cutting
                    "
                  ></a-input>
                  <span>&nbsp;且槽深度大于等于</span>
                  <a-input
                    suffix="mm"
                    style="width: 100px"
                    :value="
                      formData.layeredCuttingSetting.layered_slot_gt_slot_deep
                    "
                    @input="
                      handleCheckInput('layered_slot_gt_slot_deep', $event)
                    "
                    :disabled="
                      !formData.layeredCuttingSetting.layered_slot_cutting
                    "
                    @blur="handleInputBlur"
                  ></a-input>
                  <span>&nbsp;时，分层切割槽</span>
                </div>
                <div class="secondCutting mb16">
                  <span class="mr8">第二层切割深度为</span>
                  <a-input
                    style="width: 100px"
                    suffix="mm"
                    :value="
                      formData.layeredCuttingSetting
                        .layered_slot_second_slot_deep
                    "
                    @input="
                      handleCheckInput('layered_slot_second_slot_deep', $event)
                    "
                    :disabled="
                      !formData.layeredCuttingSetting.layered_slot_cutting
                    "
                    @blur="handleInputBlur"
                  ></a-input>
                  <div class="mt5" v-show="isShowWarning">
                    <!-- 提示 感觉不太对-->
                    <span class="warning">切割深度不能大于槽深度</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hierarchy-setting" id="hierarchy-setting">
          <div class="head-title">
            <span>{{ $t('cuttingDock.cuttingParams.splitPlankThick') }}</span>
            <a-switch
              id="engraving_setting_isLayeredCut_switch"
              v-model="formData.layeredThickSetting.isLayeredCut"
            ></a-switch>
          </div>
          <div
            class="plank-table ml8"
            v-show="
              isShowSecondaryCatalog ||
              formData.layeredThickSetting.isLayeredCut
            "
          >
            <div class="manage-plank">
              <a-button
                @click="addNewPlank"
                :disabled="!formData.layeredThickSetting.isLayeredCut"
                class="mr12"
                id="engraving_setting_addNewPlank_btn"
                >{{ $t('materialPage.addPlank') }}</a-button
              >
              <a-button
                type="danger"
                ghost
                @click="deletePlank"
                :disabled="!formData.layeredThickSetting.isLayeredCut"
                id="engraving_setting_deletePlank_btn"
                >{{ $t('arrangedPage.deletePlank') }}</a-button
              >
            </div>
            <div class="plank-table-main" id="engraving_setting_plankTable_div">
              <!-- cell-click 当某个单元格被点击时会触发该事件 -->
              <el-table
                :data="formData.layeredThickSetting.layeredThicks"
                :header-cell-style="changeHeaderStyle"
                :cell-style="changeCellStyle"
                :row-class-name="tableRowClassName"
                :cell-class-name="tableCellClassName"
                @cell-click="cellClick"
              >
                <el-table-column
                  v-for="(item, index) in plankListColumn"
                  :key="index"
                  :label="translateLang(item.label)"
                  :prop="item.prop"
                  class="ellipsis"
                >
                  <template slot-scope="scope">
                    <div
                      v-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex &&
                        item.prop !== 'highlight'
                      "
                    >
                      <el-input
                        :id="`engraving_setting_layeredThicks_table_${scope.row.index}_${item.prop}_input`"
                        v-model="scope.row[item.prop]"
                        ref="editInput"
                        type="string"
                        size="mini"
                        :disabled="disableddRules(scope.row, item.prop)"
                        @blur="inputBlur(scope.row, item.prop)"
                        @change="inputBlur(scope.row, item.prop)"
                        @input="verifyInput(scope.row, item.prop)"
                      />
                    </div>
                    <!-- 高光属性 下拉框 -->
                    <div
                      v-else-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex &&
                        scope.row.matCode &&
                        item.prop === 'highlight'
                      "
                    >
                      <!-- 下拉框 默认是非高光 材质未选择的时候不允许选择-->
                      <el-select
                        :value="
                          scope.row[item.prop] ? 'heighGloss' : 'notHeighGloss'
                        "
                        :disabled="!scope.row.matCode"
                        @change="
                          (optionValue) =>
                            handleChangeHighGloss(
                              optionValue,
                              scope.row,
                              item.prop
                            )
                        "
                        placeholder=""
                        :id="`cuttingParams_highlight_select_${scope.row.index}`"
                      >
                        <el-option
                          value="notHeighGloss"
                          :label="$t('common.notHeighGloss')"
                          :id="`cuttingParams_notHeighGloss_selectOption_${scope.row.index}`"
                        >
                        </el-option>
                        <el-option
                          value="heighGloss"
                          :label="$t('common.heighGloss')"
                          :id="`cuttingParams_heighGloss_selectOption_${scope.row.index}`"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div
                      class="knives-type"
                      :id="`engraving_setting_layeredThicks_table_${scope.row.index}_${item.prop}`"
                      v-else
                    >
                      {{
                        item.prop == 'highlight' &&
                        [true, false].includes(scope.row[item.prop])
                          ? !scope.row[item.prop]
                            ? $t('common.notHeighGloss')
                            : $t('common.heighGloss')
                          : scope.row[item.prop]
                      }}
                    </div>
                    <div
                      v-if="
                        item.prop == 'thirdCut' &&
                        !onDealHierarchyCutting(scope.row)
                      "
                    >
                      <div class="tip" id="cutting_Params_totalThickLimit_tip">
                        ({{ $t('cuttingDock.cuttingParams.totalThickLimit') }})
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="edge-hierarchy mt20 mb16">
              <span class="mr14">{{
                $t('cuttingDock.cuttingParams.offsetFollowStep')
              }}</span>
              <a-checkbox
                id="engraving_setting_trimmingFollowsLayeredCutting_checkbox"
                v-model="
                  formData.layeredThickSetting.trimmingFollowsLayeredCutting
                "
                :disabled="!formData.layeredThickSetting.isLayeredCut"
              ></a-checkbox>
            </div>
          </div>
        </div>
        <div class="optimize-speed" id="move-plank">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.movePlankSetting')
          }}</span>
          <div class="content ml8">
            <div class="base_title">
              <span class="small-plank mr14">{{
                $t('cuttingDock.cuttingParams.newCutSequence')
              }}</span>
              <a-switch
                id="engraving_setting_newCutSequence_checkbox"
                v-model="formData.movePlankSetting.newCutSequence"
                size="small"
                class="mr10"
              ></a-switch>
              <span style="color: #ed7b2f">
                （{{ $t('cuttingDock.cuttingParams.newCutSequenceText') }}）
              </span>
            </div>
          </div>
        </div>
        <div class="long-door-plank" id="long-door-plank">
          <div class="head-title flex flex-cross--center">
            <span class="mr8">{{
              $t('cuttingDock.cuttingParams.longCut')
            }}</span>
            <a-switch
              id="engraving_setting_longPlankCuttingEnable_switch"
              v-model="formData.longPlankCuttingSetting.longPlankCuttingEnable"
            ></a-switch>
          </div>

          <div
            class="base_title ml8"
            v-show="
              formData.longPlankCuttingSetting.longPlankCuttingEnable ||
              isShowSecondaryCatalog
            "
          >
            <div class="long-door">
              <span style="color: #ed7b2f"
                >{{ $t('cuttingDock.cuttingParams.longPlankTip1') }}
                {{ $t('cuttingDock.cuttingParams.longPlankTip2') }}</span
              >
            </div>
            <div class="long-door secondary-cutting">
              <span class="secondary-cutting-label mr8">{{
                $t('cuttingDock.cuttingParams.secondCutting')
              }}</span>
              <a-checkbox-group
                v-model="secondaryCuttingList"
                @change="handleSelectCutting"
                :disabled="
                  !formData.longPlankCuttingSetting.longPlankCuttingEnable
                "
              >
                <a-checkbox
                  v-for="(option, index) in secondaryCuttingOptions"
                  :value="option.value"
                  :key="option.value"
                  :id="`engraving_setting_${index}_checkbox`"
                  ><span :id="`engraving_setting_${index}_checkbox_label`">{{
                    translateLang(option.label)
                  }}</span></a-checkbox
                >
              </a-checkbox-group>
              <a-input
                suffix="mm"
                style="width: 100px; margin-left: -20px"
                v-model="formData.longPlankCuttingSetting.generalPlankThick"
                @input="
                  inputCheck(
                    'longPlankCuttingSetting',
                    'generalPlankThick',
                    false,
                    1,
                    false,
                    3000
                  )
                "
                :disabled="
                  !formData.longPlankCuttingSetting.longPlankCuttingEnable
                "
                id="engraving_setting_generalPlankThick_input"
              ></a-input>
              {{ $t('cuttingDock.cuttingParams.normalPlank') }}
              <div class="cutting-str">
                （
                {{ $t('cuttingDock.cuttingParams.normalPlankTip') }}
                ）
              </div>
            </div>
            <div class="long-door">
              <span>
                {{ $t('cuttingDock.cuttingParams.plankLongLimit') }}
                <a-input
                  suffix="mm"
                  style="width: 100px"
                  v-model="formData.longPlankCuttingSetting.longPlankMinLength"
                  @input="
                    inputCheck(
                      'longPlankCuttingSetting',
                      'longPlankMinLength',
                      false,
                      1,
                      false,
                      3000
                    )
                  "
                  :disabled="
                    !formData.longPlankCuttingSetting.longPlankCuttingEnable
                  "
                  id="engraving_setting_longPlankMinLength_input"
                ></a-input>
                {{ $t('cuttingDock.cuttingParams.plankShortLimit') }}
                <a-input
                  suffix="mm"
                  style="width: 100px"
                  v-model="
                    formData.longPlankCuttingSetting.longPlankShortMinLength
                  "
                  @input="
                    inputCheck(
                      'longPlankCuttingSetting',
                      'longPlankShortMinLength',
                      false,
                      1,
                      false,
                      3000
                    )
                  "
                  :disabled="
                    !formData.longPlankCuttingSetting.longPlankCuttingEnable
                  "
                ></a-input>
                {{ $t('cuttingDock.cuttingParams.cuttingSplit') }}
              </span>
            </div>
            <div class="long-door">
              <span>
                {{ $t('cuttingDock.cuttingParams.longExpand') }}
                <a-input
                  suffix="mm"
                  style="width: 100px"
                  v-model="
                    formData.longPlankCuttingSetting.longPlankExpandedSize
                  "
                  @input="
                    inputCheck(
                      'longPlankCuttingSetting',
                      'longPlankExpandedSize',
                      false,
                      1,
                      false,
                      20
                    )
                  "
                  :disabled="
                    !formData.longPlankCuttingSetting.longPlankCuttingEnable
                  "
                  id="engraving_setting_longPlankExpandedSize_input"
                ></a-input>
                {{ $t('cuttingDock.cuttingParams.shortExpand') }}
                <a-input
                  suffix="mm"
                  style="width: 100px"
                  v-model="
                    formData.longPlankCuttingSetting.longPlankShortExpandedSize
                  "
                  @input="
                    inputCheck(
                      'longPlankCuttingSetting',
                      'longPlankShortExpandedSize',
                      false,
                      1,
                      false,
                      20
                    )
                  "
                  :disabled="
                    !formData.longPlankCuttingSetting.longPlankCuttingEnable
                  "
                  id="engraving_setting_longPlankShortExpandedSize_input"
                ></a-input>
                {{ $t('cuttingDock.cuttingParams.expandTip') }}
              </span>
            </div>
            <div class="long-door mb16">
              <span>
                {{ $t('cuttingDock.cuttingParams.pumpOffWay') }}
                <a-radio-group
                  v-model="formData.longPlankCuttingSetting.vacuumPumpPauseWay"
                  :disabled="
                    !formData.longPlankCuttingSetting.longPlankCuttingEnable
                  "
                >
                  <a-radio
                    value="after_one_plank"
                    class="ml20"
                    id="engraving_setting_vacuumPumpPauseWay_after_one_plank_radio"
                    >{{
                      $t('cuttingDock.cuttingParams.afterOnePlank')
                    }}</a-radio
                  >
                  <a-radio
                    value="after_all_plank"
                    id="engraving_setting_vacuumPumpPauseWay_after_all_plank_radio"
                    >{{
                      $t('cuttingDock.cuttingParams.afterAllPlank')
                    }}</a-radio
                  >
                </a-radio-group>
              </span>
            </div>
          </div>
        </div>
        <div class="decimals" id="decimals">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.numberFixed')
          }}</span>
          <div class="decimals-num ml8 mb16">
            <span class="base_title mr8">{{
              $t('cuttingDock.cuttingParams.numberFixed')
            }}</span>
            <a-input
              id="engraving_setting_decimal_input"
              v-model="formData.decimalSetting.decimal"
              @input="inputCheck('decimalSetting', 'decimal', true)"
            ></a-input>
            <span
              class="inline-block pl14"
              style="color: rgb(237, 123, 47); font-weight: 500"
            >
              （{{ $t('cuttingDock.cuttingParams.fixedTip') }}）
            </span>
          </div>
        </div>
        <div class="optimize-speed" id="buffer-code">
          <div class="head-title">
            {{ $t('cuttingDock.cuttingParams.bufferCode') }}
          </div>
          <div class="content ml8">
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.cuttingSlow') }}</span>
              <a-checkbox
                v-model="formData.plankSurfaceBufferCommand"
                id="`engraving_setting_plankSurfaceBufferCommand_checkbox"
              ></a-checkbox>
            </div>
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.holeSlow') }}</span>
              <a-checkbox
                v-model="formData.hole_surface_buffer"
                id="`engraving_setting_hole_surface_buffer_checkbox"
              ></a-checkbox>
            </div>
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.slotSlow') }}</span>
              <a-checkbox
                v-model="formData.hole_slot_surface_buffer"
                id="`engraving_setting_hole_slot_surface_buffer_checkbox"
              ></a-checkbox>
            </div>
            <div class="base_title">
              <span>{{ $t('cuttingDock.cuttingParams.slowCode') }}</span>
              <a-radio-group v-model="formData.buffer_way">
                <a-radio value="G00" id="engraving_buffer_way_G00_radio">
                  G00{{ $t('cuttingDock.cuttingParams.code') }}
                </a-radio>
                <a-radio value="G01" id="engraving_buffer_way_G01_radio">
                  G01{{ $t('cuttingDock.cuttingParams.code') }}
                </a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="interval-setting mb16" id="interval-setting">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.layoutGap')
          }}</span>
          <div class="interval-setting-content ml8">
            <span class="base_title mr12">{{
              $t('cuttingDock.cuttingParams.layoutGap')
            }}</span>
            <a-input
              id="engraving_setting_boardGap_input"
              v-model="formData.boardGapsSetting.boardGap"
              @input="inputDepth('boardGapsSetting', 'boardGap')"
            ></a-input>
            <span
              class="inline-block pl14"
              style="color: rgb(237, 123, 47); font-weight: 500"
              >（{{ $t('cuttingDock.cuttingParams.layoutGapTip') }}）</span
            >
          </div>
        </div>
        <div class="mb16" id="waste_auto_cutting">
          <div class="head-title flex flex-cross--center">
            <span class="mr8">{{
              $t('cuttingDock.cuttingParams.wasteCutting')
            }}</span>
            <a-switch
              id="engraving_waste_setting_checkbox"
              v-model="formData.waste_setting.waste_cutting"
            ></a-switch>
          </div>
          <div
            class="ml12 mb16 flex flex-cross--center"
            v-if="
              formData.waste_setting.waste_cutting || isShowSecondaryCatalog
            "
          >
            <span class="ml20">{{
              $t('cuttingDock.cuttingParams.cuttingOrder')
            }}</span>
            <a-radio-group
              v-model="formData.waste_setting.waste_order"
              name="radioGroup"
              :disabled="!formData.waste_setting.waste_cutting"
            >
              <a-radio
                value="plank_first"
                id="engraving_waste_setting_plank_first_radio"
              >
                {{ $t('cuttingDock.cuttingParams.plankFirst') }}
              </a-radio>
              <a-radio
                value="waste_first"
                id="engraving_waste_setting_waste_first_radio"
              >
                {{ $t('cuttingDock.cuttingParams.wasteFirst') }}
              </a-radio>
              <span style="color: #ed7b2f; margin-left: -12px"
                >{{ $t('cuttingDock.cuttingParams.cuttingOrderTip') }}!</span
              >
            </a-radio-group>
          </div>
          <div
            class="ml12 flex flex-cross--center"
            v-if="
              formData.waste_setting.waste_cutting || isShowSecondaryCatalog
            "
          >
            <span class="ml20">{{
              $t('cuttingDock.cuttingParams.cuttingLimit')
            }}</span>
            <a-input
              id="engraving_waste_setting_input"
              class="mh10"
              ref="wasteSizeRef"
              @input="handleWasterSize('waste_setting', 'waste_size')"
              @blur="handleWasterSizeBlur"
              v-model="formData.waste_setting.waste_size"
              suffix="mm"
              style="width: 100px"
              :disabled="!formData.waste_setting.waste_cutting"
            >
            </a-input>
            <span>{{ $t('cuttingDock.cuttingParams.waste') }}</span>
            <span style="color: #ed7b2f" class="ml10">{{
              $t('cuttingDock.cuttingParams.wasteSizeLimit')
            }}</span>
          </div>
        </div>
        <div class="mb16 flex" id="ZK_datum_point_customize">
          <div>
            <div class="head-title flex flex-cross--center">
              <span class="mr8">{{
                $t('cuttingDock.cuttingParams.zkPointCustomize')
              }}</span>
              <a-switch
                id="engraving_setting_ZKDatumPoint_switch"
                v-model="formData.ZK_setting.ZK_datum_point_customize"
              ></a-switch>
            </div>
            <!-- css权重不够，使用v-show隐藏不生效 -->
            <template
              v-if="
                isShowSecondaryCatalog ||
                formData.ZK_setting.ZK_datum_point_customize
              "
            >
              <div class="ml12 mb16 flex flex-cross--center">
                <span class="ml20"
                  >{{
                    $t('cuttingDock.cuttingParams.zkRotatePoint')
                  }}(A0)：</span
                >
                <a-select
                  style="width: 180px"
                  v-model="formData.ZK_setting.rotate_base_point"
                  id="engraving_setting_basePoint_select"
                  class="ml20"
                  showArrow
                  :disabled="!formData.ZK_setting.ZK_datum_point_customize"
                >
                  <a-select-option
                    v-for="(item, index) in basePointOptions"
                    :key="item.value"
                    :value="item.value"
                    :id="`engraving_setting_basePoint_select_option_${index}`"
                    :title="translateLang(item.label)"
                    class="all-labeling ellipsis"
                    >{{ $t(item.label) }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="ml12 mb16 flex flex-cross--center">
                <span class="ml20"
                  >{{ $t('cuttingDock.cuttingParams.zkRotateDir') }}：</span
                >
                <a-select
                  style="width: 180px"
                  v-model="formData.ZK_setting.rotate_angle_direction"
                  id="engraving_setting_angleDirection_select"
                  class="ml20"
                  showArrow
                  :disabled="!formData.ZK_setting.ZK_datum_point_customize"
                >
                  <a-select-option
                    v-for="(item, index) in angleDirectionOptions"
                    :key="item.value"
                    :value="item.value"
                    :id="`engraving_setting_angleDirection_select_option_${index}`"
                    :title="translateLang(item.label)"
                    class="all-labeling ellipsis"
                    >{{ $t(item.label) }}
                  </a-select-option>
                </a-select>
              </div>
            </template>
          </div>
          <div
            class="zk-customize pt20 relative"
            v-show="
              isShowSecondaryCatalog ||
              formData.ZK_setting.ZK_datum_point_customize
            "
          >
            <span
              :class="[
                'absolute',
                item.value == 'top'
                  ? 'img-top'
                  : item.value == 'left'
                  ? 'img-left'
                  : item.value == 'right'
                  ? 'img-right'
                  : 'img-bottom',
                item.value == formData.ZK_setting.rotate_base_point
                  ? 'customize-style'
                  : '',
              ]"
              class="ellipsis"
              v-for="item in basePointList"
              :key="item.value"
              :title="translateLang(item.label)"
              >{{ $t(item.label) }}</span
            >
            <div class="inline-block">
              <img
                v-if="formData.ZK_setting.rotate_angle_direction == 'clockwise'"
                :src="img.clockwise"
              />
              <img v-else :src="img.counterclockwise" />
            </div>
          </div>
        </div>
        <div class="interval-setting mb16" id="saw-setting">
          <span class="head-title">{{
            $t('cuttingDock.cuttingParams.sawSetting')
          }}</span>
          <div class="interval-setting-context">
            <div class="mr20">
              <span class="base_title mr12">{{
                $t('cuttingDock.cuttingParams.restSizeLimit')
              }}</span>
              <a-input
                id="engraving_setting_mentaojiSizeSetting_input"
                v-model="formData.mentaojiSizeSetting.mentaojiSize"
                suffix="mm"
                class="mr12"
                style="width: 100px"
                @input="
                  inputCheck('mentaojiSizeSetting', 'mentaojiSize', false, 1)
                "
              ></a-input>
              <span class="base_title mr12">{{
                $t('cuttingDock.cuttingParams.useSaw')
              }}</span>
              <a-switch
                id="engraving_setting_mentaojiSizeSetting_switch"
                v-model="formData.mentaojiSizeSetting.mentaojiSizeSetting"
              ></a-switch>
              <div class="base_title mt16">
                <span class="base_title mr12">{{
                  $t('cuttingDock.cuttingParams.unGenSawPath')
                }}</span>
                <a-checkbox
                  id="engraving_setting_mentaojiSizeSetting_checkbox"
                  v-model="formData.mentaojiSizeSetting.noVerticalSaw"
                ></a-checkbox>
                <span class="cutting-tip color-a"
                  >（{{ $t('cuttingDock.cuttingParams.suitMachine') }}）</span
                >
              </div>
            </div>
            <div class="inline-block">
              <img :src="img.sawSetting" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clockwise from '@/assets/image/clockwise.png'
import counterclockwise from '@/assets/image/counterclockwise.png'
import insideSlot from '@/assets/image/insideSlot.png'
import outsideSlot from '@/assets/image/outsideSlot.png'
import sawSetting from '@/assets/image/saw_setting.png'
import AnchorNav from '@/components/anchorNav/index.vue'
import inputNumber from '@/components/common/input-number'
import MTrimSetting from '@/components/engraving/m-trim-setting.vue'
import inputNumberSuffix from '@/components/g-input-number-suffix.vue'
import { cuttingParamsNavs } from '@/util/anchorNavData.ts'
import { translate } from '@/util/commonFun'
import { genUniId } from '@/util/commonFuncs'
import { verifyInputNumber } from '@/util/commonFuncs'
import { webId } from '@/util/configure'
import {
  regReplaceCuttingParamsHandleInputVal,
  regReplaceCuttingParamsInputCheck,
  regReplaceCuttingParamsInputDepth,
} from '@/util/regReplace'
import { mapGetters } from 'vuex'

const lavePoingImgObj = {
  左上角: require('../../assets/fiveSixSet/laveLT.png'),
  右上角: require('../../assets/fiveSixSet/laveRT.png'),
  左下角: require('../../assets/fiveSixSet/laveLB.png'),
  右下角: require('../../assets/fiveSixSet/laveRB.png'),
}
export default {
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  components: { inputNumber, AnchorNav, inputNumberSuffix, MTrimSetting },
  data() {
    return {
      propertyName: {
        TRIM_SIDE: 'trimSide',
        TRIM_L_SINGLE: 'trimLSingle',
        TRIM_L_MOMENT: 'trimLMoment',
        SECOND_TRIM_VALUE: 'secondTrimValue',
        NEW_TRIM: 'new_trim',
        SINGLE_PANEL_TRIM_DIRECTION: 'single_panel_trim_direction',
        IS_FIRST_TRIM_SINGLE_PANEL: 'is_first_trim_single_panel',
      },
      navs: cuttingParamsNavs,
      startPoint: ['左上角', '右上角', '左下角', '右下角'], // 记录工位起始点可选项
      startPointMap: [
        'cuttingDock.cuttingParams.topLeftD',
        'cuttingDock.cuttingParams.topRightD',
        'cuttingDock.cuttingParams.bottomLeftD',
        'cuttingDock.cuttingParams.bottomRightD',
      ], // 记录工位起始点可选项
      paringPoint: [
        {
          label: this.$t('cuttingDock.cuttingParams.topLeft'),
          value: 'topLeft',
        },
        {
          label: this.$t('cuttingDock.cuttingParams.bottomLeft'),
          value: 'bottomLeft',
        },
        {
          label: this.$t('cuttingDock.cuttingParams.topRight'),
          value: 'topRight',
        },
        {
          label: this.$t('cuttingDock.cuttingParams.bottomRight'),
          value: 'bottomRight',
        },
      ], // 记录修边方向可选项
      lavePoints: [
        {
          label: 'cuttingDock.cuttingParams.topLeftD',
          value: 'topLeft',
        },
        {
          label: 'cuttingDock.cuttingParams.bottomLeftD',
          value: 'bottomLeft',
        },
        {
          label: 'cuttingDock.cuttingParams.topRightD',
          value: 'topRight',
        },
        {
          label: 'cuttingDock.cuttingParams.bottomRightD',
          value: 'bottomRight',
        },
      ], // 记录余料摆放位置可选项
      basePointOptions: [
        { label: 'cuttingDock.cuttingParams.plankBack', value: 'top' },
        { label: 'cuttingDock.cuttingParams.plankLeft', value: 'left' },
        { label: 'cuttingDock.cuttingParams.plankRight', value: 'right' },
        { label: 'cuttingDock.cuttingParams.plankFront', value: 'bottom' },
      ],
      basePointList: [
        { label: 'cuttingDock.cuttingParams.backSide', value: 'top' },
        { label: 'cuttingDock.cuttingParams.leftSide', value: 'left' },
        { label: 'cuttingDock.cuttingParams.rightSide', value: 'right' },
        { label: 'cuttingDock.cuttingParams.frontSide', value: 'bottom' },
      ],
      angleDirectionOptions: [
        {
          label: 'cuttingDock.cuttingParams.nDirection',
          value: 'counter_clockwise',
        },
        { label: 'cuttingDock.cuttingParams.sDirection', value: 'clockwise' },
      ],
      activePoint: '左下角', // 记录选中的工位起始点
      plankListColumn: [
        // 板材材质
        {
          label: 'common.plankMatCode',
          prop: 'matCode',
        },
        // 高光属性
        {
          label: 'common.plankHighGloss',
          prop: 'highlight',
        },
        { label: 'common.plankThick', prop: 'pThick', unit: '(mm)' },
        {
          label: 'cuttingDock.cuttingParams.firstTime',
          prop: 'firstCut',
        },
        {
          label: 'cuttingDock.cuttingParams.secondTime',
          prop: 'secondCut',
        },
        {
          label: 'cuttingDock.cuttingParams.thirdTime',
          prop: 'thirdCut',
        },
      ], // 记录表格列属性
      formData: {
        // 孔槽集中面
        holeFaceSetting: {
          apertureSlotConcentrated: 'front',
          holeConcentrated: 'front',
          processChainAndHoleOnBigPlaneBack: false,
          throughHoleSlotSameSide: false,
          percentage_of_back_hole_slot: 50,
          prorate_holes_slots_of_both_side: false,
        },
        //靠边板槽放置
        edgePlankSlotDirection: {
          sideBoardPlacing: 'default',
        },

        // 共边切割和共边加速
        coedgeCuttingSetting: {
          coedgeAccelerate: false, // 是否选中共边加速
          coedgeCutting: false, // 是否选中共边切割
          coedgeSpeed: '', // 高速擦铣速度
          coedgeThick: '', // 共边切割厚度条件
        },
        // 台面设置
        countertopSetting: {
          startPosition: '左下角', // 工位起始点
          xyReverse: false, // XY轴互换
          doubleStationReverse: false, // 是否选中双工位起始点切换
          planeOffset: false, // 是否选中机台平面偏移
          planeXOffset: '', // 机台平面偏移X轴
          planeYOffset: '', // 机台平面偏移Y轴
          planeZOffset: '', // 机台平面偏移Z轴
          X_reference_swap: false, //X轴坐标基准对调
          x_reverse_reference_swap: false, //大板反面X轴坐标基准对调
          y_reverse_reference_swap: false, //大板反面Y轴坐标基准对调
          doubleDifferentSize: false, //双工位台面不同尺寸
          stationOneMax: 2440, //工位一最大尺寸
          stationTwoMax: 2740, //工位二最大尺寸
          smallStationFirst: false, //大工位只加工小工位无法加工的大板
        },
        // 切割打孔方式
        cuttingPunchingSetting: {
          arcCuttingCode: false, // 圆弧切割用I, J表示
          cutDirection: this.$t('cuttingDock.cuttingParams.nDirection'), // 小板切割方向
          cutZ: 0.1, // 记录切透量Z深度(D)
          cuttingCurvedInterpolationCommand: false, // 记录是否选中圆弧插补指令切割弧形板
          idleZ: 38, // 记录空闲时Z高度(H)
          throughTowSideToCut: false, // 通孔打孔方式
          zReference: 'counter_top', // 对刀基准面
          knifeBasedOnPlank: false, // 相对大板表面高度
          noG17G18G19: false, //不生成G19面指令
          millingSlotOverlapRate: 0, //铣槽重叠率
          autoClearFilletSlot: false, //铣槽时未拉穿的槽自动清理圆角
          mill_slots_way: 'out_to_inner',
          through_slot_two_side_cut: false, // 通槽打穿方式
        },
        // 长板加工
        longPlankCuttingSetting: {
          longPlankCuttingEnable: false,
          longPlankMinLength: 1500,
          longPlankExpandedSize: 2,
          longPlankShortMinLength: 200,
          longPlankShortExpandedSize: 2,
        },
        // 小数保留位数
        decimalSetting: {
          decimal: 2, // 记录小数保留位数
        },
        // 下刀板件表面缓冲指令
        plankSurfaceBufferCommand: true,
        hole_surface_buffer: true,
        hole_slot_surface_buffer: true,
        buffer_way: 'G00',
        //排版间隙设置
        boardGapsSetting: {
          boardGap: 0.1,
        },
        // 分层切割
        layeredCuttingSetting: {
          minSafeArea: '0.2', // 分层切割面积条件
          minSafeLength: '300', // 分层切割长宽条件
          secondCuttingDepth: '2', //小板第二次切割深度
          repeatedCut: true, // 是否选中小板切割两次
          repeatedSlot: true, // 是否选中槽切割两次
          layered_slot_cutting: false, //拉槽铣槽分层切割
          layered_slot_lt_knife_diameter: '', //刀径
          layered_slot_gt_slot_deep: '', //槽深
          layered_slot_second_slot_deep: '', //第二次切割深度
        },
        // 开料分层切割设置
        layeredThickSetting: {
          isLayeredCut: false, // 记录是否选中开料分层切割设置
          layeredThicks: [], // 记录表格渲染数据
          trimmingFollowsLayeredCutting: false, // 记录是否选中修边也跟随分层切割
        },
        // 防跑板设置
        movePlankSetting: {
          new_cut_sequence: true, //新版切割算法
        },
        // 加工速度优化
        processingSpeedSetting: {
          arcSpeed: '8000', // 曲线加工速度
          knifeSpeed: '3000', // 收刀速度
          noOblique: false, // 切割时斜下刀
          back_trim_oblique: false, //反面修边斜下刀
          angle_deceleration_enabled: false,
          angle_deceleration_dis: 30,
          angle_deceleration_speed: 3000,
          openknifeSpeed: true,
          angle_deceleration_list: [[30, 3000]],
        },

        // 修边设置
        trimmingSetting: {
          secondTrimValue: 0, // 正面再修边
          trimLMoment: this.$t('cuttingDock.cuttingParams.startCutting'), // 反面修边顺序
          trimLSingle: false, // 只修长边
          trimSide: '',
          new_trim: false, //是否开启新版修边
        },
        // 余料设置
        surplusSetting: {
          surplusPosition: '',
        },
        // 高光设置
        highgloss_setting: {
          highgloss_direction: 'back', //back反面 | front正面
        },
        waste_setting: {
          // 是否开启废料自动截断
          waste_cutting: false,
          // 切割顺序
          waste_order: 'plank_first',
          // 废料裁剪尺寸
          waste_size: 400,
        },
        ZK_setting: {
          ZK_datum_point_customize: false,
          rotate_base_point: 'right',
          rotate_angle_direction: 'counter_clockwise',
        },
        mentaojiSizeSetting: {
          mentaojiSize: 20,
          mentaojiSizeSetting: true,
          noVerticalSaw: false,
        },
      },
      rowIndex: -1, // 记录表格行索引
      columnIndex: -1, // 记录表格列索引
      currentRow: {}, // 记录当前点击的行
      lavePointImg: '',
      secondaryCuttingOptions: [
        { label: 'common.isDoorPlank', value: 'cutSingleDoor' },
        {
          label: 'cuttingDock.cuttingParams.thickRange',
          value: 'cutGeneralPlank',
        },
      ],
      secondaryCuttingList: [],
      millingSlotOverlapRate: 0,
      plateGighGlossOptions: [
        {
          label: 'common.front',
          value: 'front',
        },
        {
          label: 'common.back',
          value: 'back',
        },
      ],
      // 机台平面偏移配置项
      planeOffsetOptions: [
        {
          label: 'X',
          key: 'planeXOffset',
        },
        {
          label: 'Y',
          key: 'planeYOffset',
        },
        {
          label: 'Z',
          key: 'planeZOffset',
        },
      ],
      positionDis: [
        {
          position: '左下角',
          value: '+x,+y',
        },
        {
          position: '左上角',
          value: '+x,- y',
        },
        {
          position: '右下角',
          value: '- x,+y',
        },
        {
          position: '右上角',
          value: '- x,- y',
        },
      ],
      positionDisMap: [
        'cuttingDock.cuttingParams.bottomLeftD',
        'cuttingDock.cuttingParams.topLeftD',
        'cuttingDock.cuttingParams.bottomRightD',
        'cuttingDock.cuttingParams.topRightD',
      ],
      img: {
        insideSlot,
        outsideSlot,
        counterclockwise,
        clockwise,
        sawSetting,
      },
      isShowWarning: false,
    }
  },
  computed: {
    ...mapGetters('equipEnvironment', ['showConfig']),
    laveImg() {
      return (val) => {
        switch (val) {
          case 'bottomLeft':
            return require('../../assets/fiveSixSet/laveLB.png')
            break
          case 'topLeft':
            return require('../../assets/fiveSixSet/laveLT.png')
            break
          case 'bottomRight':
            return require('../../assets/fiveSixSet/laveRB.png')
            break
          case 'topRight':
            return require('../../assets/fiveSixSet/laveRT.png')
            break
        }
      }
    },
    container() {
      return this.$refs.wrap
    },
    positionDisValue() {
      return this.positionDis.filter(
        (dis) => dis.position == this.formData.countertopSetting.startPosition
      )[0]?.value
    },
    isShowBaseBtn() {
      return (
        this.formData.processingSpeedSetting.angle_deceleration_list &&
        this.formData.processingSpeedSetting.angle_deceleration_list.length > 1
      )
    },
    // 开料分层切割顺序填写规则
    disableddRules() {
      return (row, prop) => {
        // 判断只对第一二三次厚度填写生效
        let verifyList = ['firstCut', 'secondCut', 'thirdCut']
        let flag
        if (verifyList.includes(prop)) {
          switch (prop) {
            case 'secondCut':
              flag = row['firstCut'] !== ''
              break
            case 'thirdCut':
              flag = row['secondCut'] !== '' && row['firstCut'] !== ''
              break
            default:
              flag = true
              break
          }
          return !flag
        }
        // 其余的数据正常输入
        return false
      }
    },
  },
  watch: {
    obj: {
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          let s = JSON.parse(JSON.stringify(newVal))
          this.formData = { ...this.formData, ...s }
          this.millingSlotOverlapRate =
            s.cuttingPunchingSetting.millingSlotOverlapRate
          this.formData.processingSpeedSetting.noOblique =
            !this.formData.processingSpeedSetting.noOblique
          let selectedCutting = [
            {
              label: this.$t('common.isDoorPlank'),
              key: 'cutSingleDoor',
              checked: this.formData.longPlankCuttingSetting.cutSingleDoor,
            },
            {
              label: this.$t('cuttingDock.cuttingParams.thickRange'),
              key: 'cutGeneralPlank',
              checked: this.formData.longPlankCuttingSetting.cutGeneralPlank,
            },
          ]
          this.secondaryCuttingList = selectedCutting
            .filter((e) => e.checked)
            .map((i) => i.key)
        }
      },
      immediate: true,
    },
    millingSlotOverlapRate: {
      handler(val, preVal) {
        this.formatInputSlotCoverRate(val, preVal)
      },
    },
  },
  methods: {
    // 分层切割--铣槽拉槽
    handleInputBlur() {
      // 失去焦点的时候校验第二层切割深度必须小于设置的槽深度
      if (
        Number(
          this.formData.layeredCuttingSetting.layered_slot_second_slot_deep
        ) <
        Number(this.formData.layeredCuttingSetting.layered_slot_gt_slot_deep)
      ) {
        // 关闭提示
        this.isShowWarning = false
      } else {
        // 开启提示 并且不能保存
        this.isShowWarning = true
      }
    },
    handleCheckInput(name, e) {
      // 输入框的限制 输入为纯数字 3+2 整数+小数
      // 正则表达式
      const reg = /^(\d{0,3})(\.(\d{0,2}))?$/g
      if (reg.test(e.target.value) || e.target.value === '') {
        // 符合验证规范，直接赋值
        this.formData.layeredCuttingSetting[name] = e.target.value
      }
      // 不符合规范的时候不修改数据，将输入框的值改回原始数据
      e.target.value = this.formData.layeredCuttingSetting[name]
    },
    translateLang(key) {
      return translate(key)
    },
    onUpdateTrimSetting(val) {
      this.formData.trimmingSetting = JSON.parse(JSON.stringify(val))
    },

    handleWasterSize(val1, val2) {
      this.formData[val1][val2] = regReplaceCuttingParamsInputDepth(
        this.formData[val1][val2]
      )
      this.formData[val1][val2] =
        this.formData[val1][val2].indexOf('.') > 0
          ? this.formData[val1][val2].split('.')[0].substring(0, 3) +
            '.' +
            this.formData[val1][val2].split('.')[1].substring(0, 2)
          : this.formData[val1][val2].split('.')[0].substring(0, 3)
    },
    handleWasterSizeBlur() {
      if (this.formData.waste_setting.waste_size < 100) {
        this.formData.waste_setting.waste_size = 100
      }
    },
    handleDualInputValue(row, prop) {
      verifyInputNumber(row[prop], row, [prop], '4')
    },
    // 铣槽重叠率输入
    formatInputSlotCoverRate(val, preVal) {
      const reg = /^-?\d*(\.\d*)?$/
      if ((!isNaN(+val) && reg.test(val) && +val <= 50) || val === '') {
        this.formData.cuttingPunchingSetting.millingSlotOverlapRate =
          Number(val)
        this.millingSlotOverlapRate = Number(val)
      } else {
        this.formData.cuttingPunchingSetting.millingSlotOverlapRate =
          Number(preVal)
        this.millingSlotOverlapRate = Number(preVal)
      }
    },
    // 选择二次切割
    handleSelectCutting(e) {
      this.formData.longPlankCuttingSetting.cutSingleDoor =
        e.includes('cutSingleDoor')
      this.formData.longPlankCuttingSetting.cutGeneralPlank =
        e.includes('cutGeneralPlank')
    },
    // 选择起始工位
    chosePoint(val) {
      this.formData.countertopSetting.startPosition = val
    },
    choselavePoint(val) {
      this.formData.surplusSetting.surplusPosition = val
    },
    // 选择余料摆放位置
    changeHeaderStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'plank_thick') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa'
      }
      return 'background: #fafafa;'
    },
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'plank_thick') {
        if (!this.formData.layeredThickSetting.isLayeredCut) {
          return 'padding-left: 16px; height: 53px; box-sizing: border-box; color: rgba(0, 0, 0, .25)'
        } else {
          return 'padding-left: 16px; height: 53px; box-sizing: border-box;'
        }
      }
      if (!this.formData.layeredThickSetting.isLayeredCut) {
        return 'color: rgba(0, 0, 0, .25); height: 53px;'
      }
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    cellClick(row, column, cell, event) {
      this.currentRow = row
      if (this.formData.layeredThickSetting.isLayeredCut) {
        this.rowIndex = row.index
        this.columnIndex = column.index
        // 不知道什么时候property可能为type，高光属性也不需要聚焦
        if (column.property != 'type' && column.property != 'highlight') {
          this.$nextTick(() => {
            this.$refs['editInput'] &&
              this.$refs['editInput'].length &&
              this.$refs['editInput'][0].focus()
          })
        }
      }
    },
    handleChangeHighGloss(optionValue, row, prop) {
      row[prop] = optionValue == 'notHeighGloss' ? false : true
      this.rowIndex = -1
      this.columnIndex = -1
    },
    inputBlur(row, prop) {
      this.rowIndex = -1
      this.columnIndex = -1
      // 如果修改的是材质，当材质被清空的时候，高光也需要被清空
      if (prop == 'matCode') {
        if (!row[prop]) {
          row.highlight = ''
        } else {
          row.highlight = false
        }
      }
      // 如果修改的是第二次的厚度，需要自动计算出第三次的值
      if (prop == 'secondCut') {
        if (row.secondCut !== '' && row.thirdCut === '') {
          const { firstCut, secondCut, pThick } = row
          row.thirdCut = (
            pThick - firstCut - secondCut < 0
              ? 0
              : pThick - firstCut - secondCut
          ).toString()
        }
      }
    },
    verifyInput(row, prop) {
      // 不需要对高光属性和板件材质进行验证
      const noVerifyList = ['matCode']
      if (!noVerifyList.includes(prop)) {
        verifyInputNumber(row[prop], row, [prop], '2-2')
      }
    },
    // 添加板件
    addNewPlank() {
      this.formData.layeredThickSetting.layeredThicks.push({
        highlight: '',
        matCode: '',
        pThick: '',
        firstCut: '',
        secondCut: '',
        thirdCut: '',
        [webId]: genUniId(), // 添加唯一id
      })
      this.rowIndex = this.formData.layeredThickSetting.layeredThicks.length - 1
      this.columnIndex = 0
      this.$nextTick(() => {
        this.$refs['editInput'] &&
          this.$refs['editInput'].length &&
          this.$refs['editInput'][0].focus()
      })
    },
    // 删除板件
    deletePlank() {
      this.formData.layeredThickSetting.layeredThicks.splice(
        this.formData.layeredThickSetting.layeredThicks.findIndex(
          (v) => v.index === this.currentRow.index
        ),
        1
      )
    },
    // 输入检验
    inputCheck(val1, val2, type, decimal, positive, max) {
      // 只为正整数
      if (type) {
        this.formData[val1][val2] = this.formData[val1][val2].replace(
          /[^\d]/g,
          ''
        ) // 去除数字和小数点以外的数字
      } else {
        // 可以为正负数
        this.formData[val1][val2] = regReplaceCuttingParamsInputCheck(
          this.formData[val1][val2]
        )

        // 第五个参数加上是为了控制只能输入正数及小数不能输入负数,并返回，走小数限制会只能输入两位
        if (positive) {
          this.formData[val1][val2] = this.formData[val1][val2].replace(
            /[^\d*\.]/g,
            ''
          )
          return
        }
        if (decimal) {
          this.formData[val1][val2] = this.formData[val1][val2].replace(
            /^(\-)*(\d+)\.(\d\d).*$/,
            '$1$2.$3'
          )
        }
        if (max) {
          if (this.formData[val1][val2] > max) {
            this.formData[val1][val2] = max
          }
        }
      }
    },
    //小板第二次切割深度输入限制
    inputDepth(val1, val2) {
      this.formData[val1][val2] = regReplaceCuttingParamsInputDepth(
        this.formData[val1][val2]
      )
      this.formData[val1][val2] =
        this.formData[val1][val2].indexOf('.') > 0
          ? this.formData[val1][val2].split('.')[0].substring(0, 3) +
            '.' +
            this.formData[val1][val2].split('.')[1]
          : this.formData[val1][val2].split('.')[0].substring(0, 3)
    },
    handleInputVal(obj, key, deep, index) {
      this.formData[obj][key][deep][index] = this.formData[obj][key][deep][
        index
      ].replace(/[^0-9]/g, '')
    },
    //组织锚点改变url
    handlePrevent(e, link) {
      e.preventDefault()
    },
    //处理不同版厚分层切割的数据判断
    onDealHierarchyCutting(scope) {
      // 使用Number.EPSILON做容差比较
      let verifyFlag =
        Math.abs(
          Number(scope['pThick']) -
            (Number(scope['firstCut']) +
              Number(scope['secondCut']) +
              Number(scope['thirdCut']))
        ) < Number.EPSILON
      return verifyFlag
    },
    handelAddAngleDeceleration() {
      if (!this.formData.processingSpeedSetting.angle_deceleration_enabled)
        return
      this.formData.processingSpeedSetting.angle_deceleration_list.push([
        '',
        '',
      ])
    },
    handelDeleteAngleDeceleration(index) {
      if (!this.formData.processingSpeedSetting.angle_deceleration_enabled)
        return
      if (!index) {
        this.formData.processingSpeedSetting.angle_deceleration_list =
          this.formData.processingSpeedSetting.angle_deceleration_list.slice(1)
      } else {
        this.formData.processingSpeedSetting.angle_deceleration_list.pop()
      }
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/style/engraving.less';

.cutting-params {
  position: relative;
  // margin: 16px;
  background: #fff;

  span {
    cursor: default;
  }
  .base-btn {
    color: #18a8c7;
    cursor: pointer;
  }
  .wrap {
    .navRightWrapWidth();

    .switch-box {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      margin-right: 40px;
    }

    .content-box {
      display: flex;
      align-items: center;
      padding: 0px 24px;
    }

    .common-side-cut {
      .common-side-limit {
        display: flex;
        align-items: center;

        .ant-input {
          width: 64px;
          margin: 0 8px;
        }
      }
    }

    .hole-slot-focus {
      .content {
        padding: 0px 24px;

        > div {
          margin-bottom: 16px;
        }

        .allocation {
          display: flex;
          align-items: center;
          .ant-switch {
            margin: 0 15px;
          }

          .ant-input {
            width: 60px;
            margin-left: 5px;
          }

          .edge-again-info {
            color: #8c8c8c;
            font-size: 14px;
          }
        }
      }
    }

    .board-slot-place {
      .content {
        padding: 0px 24px;

        > div:first-child {
          margin-bottom: 16px;
        }
      }
    }

    .mo {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .img-wrap {
      margin-top: 20px;
    }

    .radio-box {
      display: flex;
    }

    .common-side-accelerate {
      .speed-limit {
        display: flex;
        align-items: center;

        .ant-input {
          width: 64px;
          margin: 0 8px;
        }
      }
    }

    .table-board {
      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 0 24px;

        & > div {
          width: 100%;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .start-point {
        display: flex;
        margin-right: 40px;

        .small-title {
          margin-right: 13px;
        }

        .table-board-radio {
          display: flex;

          span {
            display: inline-block;
            width: 74px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
              z-index: 2;
              color: #18a8c7;
              border-color: #18a8c7;
            }

            &:nth-child(1) {
              margin-right: -1px;
              border-radius: 4px 0 0 4px;
            }

            &:nth-child(2) {
              margin-right: -1px;
            }

            &:nth-child(3) {
              margin-right: -1px;
            }

            &:nth-child(4) {
              border-radius: 0 4px 4px 0;
            }
          }

          .active-radio {
            z-index: 2;
            color: #18a8c7;
            border-color: #18a8c7;
          }
        }
      }

      .toggle-XY {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }

      .double-start-point {
        display: flex;
        align-items: center;
        margin-right: 40px;
      }

      .machine-board {
        display: flex;
        align-items: flex-start;

        .board-switch {
          display: flex;
          align-items: center;
          margin-right: 8px;
        }

        .board-offset {
          display: flex;
          align-items: center;

          .small-title {
            margin-right: 8px;
          }

          &:first-child {
            margin-bottom: 8px;
          }

          .ant-input {
            width: 83px;
            height: 32px;
          }
        }
      }
      // .change-dual {
      //   display: flex;
      //   // .dual-input-wrapper {
      //   //   flex: 1;
      //   // }
      // }
      .dual-input {
        /deep/ .ant-input,
        .ant-input-affix-wrapper {
          width: 200px;
        }
      }
    }

    .lave-off {
      .lave-off-setting-img {
        display: flex;

        .lave-off-setting {
          padding: 0 24px;

          .start-point {
            display: flex;
            margin-right: 40px;

            .small-title {
              margin-right: 13px;
              font-weight: 500;
            }

            .table-board-radio {
              display: flex;

              span {
                display: inline-block;
                width: 74px;
                height: 38px;
                line-height: 38px;
                text-align: center;
                border: 1px solid #d9d9d9;
                cursor: pointer;
                transition: all 0.5s;

                &:hover {
                  z-index: 2;
                  color: #18a8c7;
                  border-color: #18a8c7;
                }

                &:nth-child(1) {
                  margin-right: -1px;
                  border-radius: 4px 0 0 4px;
                }

                &:nth-child(2) {
                  margin-right: -1px;
                }

                &:nth-child(3) {
                  margin-right: -1px;
                }

                &:nth-child(4) {
                  border-radius: 0 4px 4px 0;
                }
              }

              .active-radio {
                z-index: 2;
                color: #18a8c7;
                border-color: #18a8c7;
              }
            }
          }
        }

        .lave-off-img {
          width: 200px;
        }
      }
    }

    .cut-hole-way {
      .cut-hole {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;

        & > div {
          display: flex;
          align-items: center;
          width: 100%;
          margin-right: 40px;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          .ant-input {
            width: 51px;
          }

          .knifeBasedOnPlank {
            margin-left: 16px;
          }
        }

        .symbol {
          span {
            margin-right: 16px;
          }

          .slot-cover-rate {
            width: 60px;
            height: 32px;
          }
        }

        .G02-cure {
          span:first-child {
            margin-right: 16px;
          }

          span:last-child {
            margin-left: 16px;
            color: #8c8c8c;
          }
        }
      }
    }

    .optimize-speed {
      .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 24px;

        & > div {
          width: 100%;
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        span {
          margin-right: 16px;
        }

        div {
          display: flex;
          align-items: center;
          margin-right: 32px;

          .ant-input {
            width: 80px;
          }

          .ant-switch {
            margin-right: 20px;
          }
        }
      }
    }

    .hierarchy-cut {
      .hierarchy-cut-twice {
        display: flex;
      }

      .hierarchy-cut-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;

        & > div {
          display: flex;
          // margin-right: 40px;
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }

          .ant-switch {
            margin-right: 40px;
          }

          .hierarchy-cut-limit {
            align-items: center;
            // display: flex;

            .secondCutting {
              margin-top: 16px;

              .el-input {
                width: 56px;

                /deep/.el-input__inner {
                  height: 32px;
                  padding: 0px 5px;
                  line-height: 32px;
                  text-align: center;
                }
              }
            }

            .ant-input {
              width: 51px;
              margin: 0 8px;
            }
          }
        }
      }
    }

    .hierarchy-setting {
      .head-title {
        display: flex;
        align-items: center;

        span {
          margin-right: 8px;
        }
      }

      .plank-table {
        padding: 0 24px;

        .plank-table-main {
          max-height: 500px;
          // overflow: hidden;
        }

        /deep/.el-table {
          width: 597px;
          overflow: visible !important;
          .el-table__body-wrapper {
            overflow: visible;
          }
          .tip {
            position: absolute;
            left: 170px;
            top: 0;
            width: 300px;
            color: rgb(255, 0, 0);
            line-height: 48px;
            height: 48px;
          }
        }

        .manage-plank {
          margin-bottom: 16px;
        }
      }
    }

    .long-door-plank {
      .long-door {
        display: flex;
        align-items: center;
        padding: 10px 24px;

        .ant-input {
          width: 51px;
        }

        .cutting-number {
          margin-left: -20px;
        }

        .cutting-str {
          margin-left: 20px;
          color: gray;
          font-size: 12px;
        }

        /deep/.ant-input-number-handler-wrap {
          display: none;
        }
      }
    }

    .decimals {
      .decimals-num {
        display: flex;
        align-items: center;
        padding: 0 24px;

        .ant-input {
          width: 51px;
        }
      }
    }

    .buffer-code {
      .buffer-code-check {
        display: flex;
        align-items: center;
        padding: 0 24px;

        .right-margin {
          margin-right: 24px;
        }
      }
    }

    .interval-setting {
      .interval-setting-context {
        display: flex;
        // align-items: center;
        padding: 0 24px;

        .ant-input {
          width: 51px;
          margin: 0 5px;
        }
      }
      .interval-setting-content {
        display: flex;
        align-items: center;
        padding: 0 24px;

        .ant-input {
          width: 51px;
          margin: 0 8px;
        }
      }
    }
    .zk-customize {
      margin-left: 90px;
      margin-top: 45px;
      .img-top {
        top: -10px;
        left: 15px;
      }
      .img-left {
        top: 55px;
        left: -40px;
      }
      .img-right {
        top: 55px;
        right: -40px;
      }
      .img-bottom {
        bottom: -20px;
        left: 15px;
      }
      .customize-style {
        color: #18a8c7;
      }
    }
  }
  .img-box {
    background: #f3f3f3;
  }
  .imgWrap {
    width: 175px;
  }
  .switch-class {
    display: flex;
    align-items: center;
  }
  .warning {
    color: #e90e0e;
  }
  .ant-select {
    width: 100%;
  }
}
</style>
