<template>
  <div class="cutting-dock h100 hide-scrollbar">
    <div class="w100 h100 flex">
      <AnchorNav :getContainer="() => container" :navs="navs" />
      <div class="wrap y-auto x-auto h100" ref="wrap">
        <div style="min-width: 1445px">
          <!-- 单双工位设置 -->
          <div class="station-setup">
            <span class="head-title">{{
              translateLang('dock56Drill.equipment')
            }}</span>
            <span class="second-title">{{
              translateLang('dock56Drill.equipType')
            }}</span>
            <a-radio-group
              v-model="formData.single_double_station"
              @change="handleChangeStationType"
            >
              <template v-for="item in cuttingDockStationSetup">
                <a-radio
                  class="ml20"
                  :key="item.value"
                  :value="item.value"
                  :id="`engraving_setting_station_setup_${item.value}__radio`"
                  >{{ $t(item.label) }}</a-radio
                >
              </template>
            </a-radio-group>
          </div>
          <div class="knife-table" id="knife-table">
            <span class="head-title">{{ $t('common.knivesStore') }}</span>
            <div class="operation-btn flex flex-cross--center">
              <a-button
                @click="handleAddNewKnife"
                class="mr12"
                id="engraving_setting_knife_table_add_row"
                >{{ $t('cuttingDock.knives.insert') }}</a-button
              >
              <a-button
                @click="handleDeleteKnife"
                class="danger-btn"
                id="engraving_setting_knife_table_delete_row"
                >{{ $t('cuttingDock.knives.delete') }}</a-button
              >
              <div class="ml10 custom-color">
                {{ $t('cuttingDock.knives.tip') }}
              </div>
            </div>
            <div class="main-table">
              <el-table
                :data="formData.knives"
                :row-style="changeStyle"
                :cell-style="columnStyle"
                :header-cell-style="headerStyle"
                :header-row-style="headerRowStyle"
                @row-click="choseKnife"
                :row-class-name="tableRowClassName"
                :cell-class-name="tableCellClassName"
                @cell-click="cellClick"
                :max-height="340"
                class="w100"
              >
                <el-table-column
                  v-for="(knife, indexs) in knivesColumns"
                  :key="indexs + '1'"
                  :label="translateLang(knife.label)"
                  :prop="knife.props"
                  :width="knife.width"
                  :fixed="[0, 1, 2, 3].includes(indexs)"
                >
                  <template slot-scope="scope">
                    <div v-if="knife.props === 'use'" class="knife-purpose">
                      <a-checkbox-group v-model="scope.row.knifePurpose">
                        <a-checkbox
                          value="holeable"
                          class="mb10 mr8"
                          :id="`engraving_setting_knife_table_${scope.row.index}_use_holeable_checkbox`"
                        >
                          <span
                            style="margin: 0"
                            :id="`engraving_setting_knife_table_${scope.row.index}_use_holeable_checkbox_label`"
                            >{{ $t('cuttingDock.knives.punchHoles') }}</span
                          >
                        </a-checkbox>
                        <a-checkbox
                          value="slotable"
                          :id="`engraving_setting_knife_table_${scope.row.index}_use_slotable_checkbox`"
                        >
                          <span
                            style="margin: 0"
                            :id="`engraving_setting_knife_table_${scope.row.index}_use_slotable_checkbox_label`"
                            >{{ $t('cuttingDock.knives.pullSlots') }}</span
                          >
                        </a-checkbox>
                        <a-checkbox
                          value="millableHole"
                          :id="`engraving_setting_knife_table_${scope.row.index}_use_millableHole_checkbox`"
                        >
                          <span
                            style="margin: 0"
                            :id="`engraving_setting_knife_table_${scope.row.index}_use_millableHole_checkbox_label`"
                            >{{ $t('cuttingDock.knives.millHoles') }}</span
                          >
                        </a-checkbox>
                        <a-checkbox
                          value="millableSlot"
                          :id="`engraving_setting_knife_table_${scope.row.index}_use_millableSlot_checkbox`"
                        >
                          <span
                            style="margin: 0"
                            :id="`engraving_setting_knife_table_${scope.row.index}_use_millableSlot_checkbox_label`"
                            >{{ $t('cuttingDock.knives.millSlots') }}</span
                          >
                        </a-checkbox>
                      </a-checkbox-group>
                    </div>
                    <div v-else-if="knife.props == 'cutable'">
                      <a-checkbox
                        v-model="scope.row[knife.props]"
                        @change="onlyOneChecked(scope.row)"
                        :id="`engraving_setting_knife_table_${scope.row.index}_cutable_checkbox`"
                      ></a-checkbox>
                    </div>
                    <div
                      v-else-if="
                        scope.row.index === rowIndex &&
                        scope.column.index === columnIndex &&
                        knife.props !== 'cutable' &&
                        knife.props !== 'use'
                      "
                    >
                      <el-input
                        v-model="scope.row[knife.props]"
                        ref="editInput"
                        type="textarea"
                        size="mini"
                        resize="none"
                        autosize
                        @blur="inputBlur(scope.row, knife.props)"
                        @change="inputBlur(scope.row, knife.props)"
                        @input="
                          handleInputCheck(
                            scope.row,
                            knife.props,
                            knife.isCheck
                          )
                        "
                        :id="`engraving_setting_knife_table_${scope.row.index}_${knife.props}_input`"
                      />
                    </div>
                    <div
                      class="knives_type"
                      style="white-space: pre-line"
                      v-else-if="
                        knife.props !== 'desc' &&
                        knife.props !== 'cutable' &&
                        knife.props !== 'use'
                      "
                      :id="`engraving_setting_knife_table_${scope.row.index}_${knife.props}`"
                    >
                      {{ scope.row[knife.props] }}
                      <span
                        :class="[
                          'iconfont',
                          'icon-revise',
                          { 'icon-revise-show': scope.row[knife.props] },
                        ]"
                        style="color: #18a8c7"
                      ></span>
                    </div>
                    <div
                      class="knives_type"
                      style="white-space: pre-line"
                      v-else
                    >
                      {{ scope.row[knife.props] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 双工位换刀代码复选框 -->
            <div class="changeKnifeCodeCheckbox" v-if="showConfig">
              <span class="text">{{ $t('cuttingDock.knives.doubleSaw') }}</span>
              <a-checkbox
                v-model="formData.open_two_station_code"
                @change="handleChangeShowCode2"
                :id="`engraving_setting_knife_table_double_saw_enable_checkbox`"
              ></a-checkbox>
            </div>
            <!-- 门套机 start -->
            <div class="main-table">
              <a-checkbox v-model="formData.mentaoji_enable" class="jupian">{{
                $t('cuttingDock.knives.sawBlade')
              }}</a-checkbox
              ><span class="toast-span">{{
                $t('cuttingDock.knives.sawBladeTip')
              }}</span>
              <page-table
                class="mentaoji"
                :dataFrom="'mentaoji'"
                :tableConfig="tableConfig"
                :data="formData.mentaoji_saws"
                @clickRow="clickRow"
                @tableInputChange="tableInputChange"
                v-show="isShowSecondaryCatalog || formData.mentaoji_enable"
              >
                <template #saw_direction="{ row }">
                  <a-radio-group
                    v-model="row.saw_direction"
                    id="engraving_setting_mentaoji_saws_saw_direction_radio"
                  >
                    <a-radio value="H">{{
                      $t('cuttingDock.knives.sawHor')
                    }}</a-radio>
                    <br />
                    <a-radio value="V">{{
                      $t('cuttingDock.knives.sawVer')
                    }}</a-radio>
                  </a-radio-group>
                </template>
                <template #cut_from="{ row }">
                  <a-radio-group
                    v-model="row.cut_from"
                    id="engraving_setting_mentaoji_saws_cut_from_radio"
                  >
                    <a-radio value="0000">{{
                      $t('cuttingDock.knives.towardsZero')
                    }}</a-radio>
                    <br />
                    <a-radio value="9999">{{
                      $t('cuttingDock.knives.awayFromZero')
                    }}</a-radio>
                  </a-radio-group>
                </template>
              </page-table>
            </div>
            <div
              class="operation-btn"
              v-show="isShowSecondaryCatalog || formData.mentaoji_enable"
            >
              <a-button
                @click="addLineMentaoji"
                class="mr12"
                id="engraving_setting_mentaoji_saws_table_add_row"
                >{{ $t('cuttingDock.knives.insert') }}</a-button
              >
              <a-button
                @click="deleteLineMentaoji"
                id="engraving_setting_mentaoji_saws_table_delete_row"
                >{{ $t('cuttingDock.knives.delete') }}</a-button
              >
            </div>
            <!-- 门套机 end -->
          </div>
          <div class="code-box">
            <div class="code-content">
              <div>
                <div>
                  <span
                    class="head-title b"
                    style="width: auto"
                    id="code-box"
                    >{{ $t('cuttingDock.other.title') }}</span
                  ><span class="feeding-tips" v-if="showConfig"
                    >（{{ $t('cuttingDock.pumpPauseTip') }}）</span
                  >
                </div>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.autoStockCode1') }}</span>
                    <a-textarea
                      v-model="formData.autoSendStockCode"
                      :placeholder="$t('cuttingDock.autoStockCode1PlaceHolder')"
                      :maxLength="200"
                      id="cuttingDock_autoSendStockCode_textarea"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.autoSendStockCode?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.autoStockCode2') }}</span>
                    <a-textarea
                      v-model="formData.evenAutoSendStockCode"
                      :placeholder="$t('cuttingDock.autoStockCode2PlaceHolder')"
                      :maxLength="200"
                      id="cuttingDock_autoSendStockCode_textarea"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenAutoSendStockCode?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.annotationCode1') }}</span>
                    <span class="text-area-tip"
                      >（{{ $t('cuttingDock.annotationCode1Tip') }}）</span
                    >
                    <a-textarea
                      v-model="formData.first_front_annotation_code"
                      id="engraving_setting_pauseCode_textarea"
                      :placeholder="
                        $t('cuttingDock.annotationCode1Placeholder')
                      "
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{
                        formData.first_front_annotation_code?.length ?? 0
                      }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.annotationCode2') }}</span>
                    <span class="text-area-tip"
                      >（{{ $t('cuttingDock.annotationCode1Tip') }}）</span
                    >
                    <a-textarea
                      v-model="formData.second_front_annotation_code"
                      id="engraving_setting_pauseCode_textarea"
                      :placeholder="
                        $t('cuttingDock.annotationCode2Placeholder')
                      "
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{
                        formData.second_front_annotation_code?.length ?? 0
                      }}/200
                    </div>
                  </div>
                </div>
              </div>
              <!-- 暂停代码 -->
              <div class="text-area">
                <div>
                  <span>{{ $t('cuttingDock.pauseCode') }}：</span>
                  <a-textarea
                    v-model="formData.pauseCode"
                    id="engraving_setting_pauseCode_textarea"
                    :placeholder="$t('cuttingDock.pauseCodePlaceHolder')"
                    :maxLength="200"
                  ></a-textarea>
                  <div class="textarea-length">
                    {{ formData.pauseCode?.length ?? 0 }}/200
                  </div>
                </div>
              </div>
              <div id="operation-starting">
                <span class="head-title code-content-title">{{
                  $t('cuttingDock.stationStart')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.stationStartCode1') }}：</span>
                    <a-textarea
                      v-model="formData.oddBeginCode"
                      id="engraving_setting_oddBeginCode_textarea"
                      :placeholder="
                        $t('cuttingDock.stationStartCode1Placeholder')
                      "
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.oddBeginCode?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.stationStartCode2') }}：</span>
                    <a-textarea
                      v-model="formData.evenBeginCode"
                      id="engraving_setting_evenBeginCode_textarea"
                      :placeholder="
                        $t('cuttingDock.stationStartCode2Placeholder')
                      "
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenBeginCode?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div id="both-sides-starting">
                <span class="head-title code-content-title">{{
                  $t('cuttingDock.frontBackStart')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.frontStartCode') }}：</span>
                    <a-textarea
                      v-model="formData.beginCodeF"
                      id="engraving_setting_beginCodeF_textarea"
                      :placeholder="$t('cuttingDock.frontStartCodePlaceholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.beginCodeF?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div>
                    <span>{{ $t('cuttingDock.backStartCode') }}：</span>
                    <a-textarea
                      v-model="formData.beginCodeB"
                      id="engraving_setting_beginCodeB_textarea"
                      :placeholder="$t('cuttingDock.backStartCodePlaceholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.beginCodeB?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div id="both-sides-ending">
                <span class="head-title code-content-title">{{
                  $t('cuttingDock.frontBackEnd')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.frontEndCode') }}：</span>
                    <a-textarea
                      v-model="formData.endCodeF"
                      id="engraving_setting_endCodeF_textarea"
                      :placeholder="$t('cuttingDock.frontEndCodePlaceholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.endCodeF?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div>
                    <span>{{ $t('cuttingDock.backEndCode') }}：</span>
                    <a-textarea
                      v-model="formData.endCodeB"
                      id="engraving_setting_endCodeB_textarea"
                      :placeholder="$t('cuttingDock.backEndCodePlaceholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.endCodeB?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div id="plank-turn-over">
                <span class="head-title code-content-title">{{
                  $t('common.roll')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.plankRollCode1') }}：</span>
                    <a-textarea
                      v-model="formData.rollCode"
                      id="engraving_setting_rollCode_textarea"
                      :placeholder="$t('cuttingDock.plankRollCode1Placeholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.rollCode?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.plankRollCode2') }}：</span>
                    <a-textarea
                      v-model="formData.evenRollCode"
                      id="engraving_setting_evenRollCode_textarea"
                      :placeholder="$t('cuttingDock.plankRollCode2Placeholder')"
                      :maxLength="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenRollCode?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div id="plank-auto-material">
                <span class="head-title code-content-title">{{
                  $t('cuttingDock.autoBaitingCode')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.autoBaitingCode1') }}：</span>
                    <a-textarea
                      v-model="formData.oddAutoBaitingCode"
                      id="engraving_setting_oddAutoBaitingCode_textarea"
                      :placeholder="
                        $t('cuttingDock.autoBaitingCode1Placeholder')
                      "
                      :max-length="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.oddAutoBaitingCode?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.autoBaitingCode2') }}：</span>
                    <a-textarea
                      v-model="formData.evenAutoBaitingCode"
                      id="engraving_setting_evenAutoBaitingCode_textarea"
                      :placeholder="
                        $t('cuttingDock.autoBaitingCode2Placeholder')
                      "
                      :max-length="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenAutoBaitingCode?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div id="operation-ending">
                <span class="head-title code-content-title">{{
                  $t('cuttingDock.stationEnd')
                }}</span>
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.stationEndCode1') }}：</span>
                    <a-textarea
                      v-model="formData.oddEndCode"
                      id="engraving_setting_oddEndCode_textarea"
                      :placeholder="
                        $t('cuttingDock.stationEndCode1Placeholder')
                      "
                      :max-length="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.oddEndCode?.length ?? 0 }}/200
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.stationEndCode2') }}：</span>
                    <a-textarea
                      v-model="formData.evenEndCode"
                      id="engraving_setting_evenEndCode_textarea"
                      :placeholder="
                        $t('cuttingDock.stationEndCode2Placeholder')
                      "
                      :max-length="200"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenEndCode?.length ?? 0 }}/200
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="head-title code-content-title" id="hierarchy-cut">
                  <span>{{ $t('cuttingDock.preStartEquipment') }}</span>
                  <a-switch
                    v-model="isPre"
                    class="mh15"
                    id="engraving_setting_preStart_switch"
                    @change="handleChangeNum()"
                  />
                </div>

                <div class="text-area" v-show="isShowSecondaryCatalog || isPre">
                  <div class="preStartEquipmentContent">
                    <div>
                      <div class="preLines">
                        <p>{{ $t('cuttingDock.firstKnifePreCode') }}</p>
                        <el-input
                          v-model.number="formData.firstKnifPreStartCodeInsert"
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          @change="handleChangeNum()"
                          :disabled="!isPre"
                          id="engraving_setting_firstKnifPreStartCodeInsert_input"
                        />
                        <p>{{ $t('cuttingDock.rowInsert') }}</p>
                      </div>
                      <div class="preLines">
                        <p>{{ $t('cuttingDock.secondKnifePreCode') }}</p>
                        <el-input
                          v-model.number="formData.preStartCodeInsert"
                          oninput="value=value.replace(/[^0-9]/g,'')"
                          @change="handleChangeNum()"
                          :disabled="!isPre"
                          id="engraving_setting_preStartCodeInsert_input"
                        />
                        <p>{{ $t('cuttingDock.rowInsert') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="pump-suspend">
                <span class="head-title code-content-title"
                  >{{ $t('cuttingDock.pumpPause')
                  }}<span
                    v-if="showConfig"
                    class="ml15 fs14"
                    style="color: red; font-weight: normal"
                    >({{ $t('cuttingDock.pumpPause2Tip') }})</span
                  ></span
                >
                <div class="text-area">
                  <div>
                    <span>{{ $t('cuttingDock.pumpPauseCode1') }}：</span>
                    <a-textarea
                      v-model="formData.oddVacuumPumpPauseCode"
                      :placeholder="$t('cuttingDock.pumpPauseCode1Placeholder')"
                      id="cuttingDock_oddvacuumPumpPauseCode"
                      :max-length="100"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.oddVacuumPumpPauseCode?.length ?? 0 }}/100
                    </div>
                  </div>
                  <div v-if="showConfig">
                    <span>{{ $t('cuttingDock.pumpPauseCode2') }}：</span>
                    <a-textarea
                      v-model="formData.evenVacuumPumpPauseCode"
                      :placeholder="$t('cuttingDock.pumpPauseCode2Placeholder')"
                      id="cuttingDock_evenvacuumPumpPauseCode"
                      :max-length="100"
                    ></a-textarea>
                    <div class="textarea-length">
                      {{ formData.evenVacuumPumpPauseCode?.length ?? 0 }}/100
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorNav from '@/components/anchorNav/index.vue'
import newContent from '@/components/newContent.vue'
import pageTable from '@/components/page-table'
import { cuttingDockNavs } from '@/util/anchorNavData.ts'
import { translate } from '@/util/commonFun'
import { buryPointApi, genUniId } from '@/util/commonFuncs'
import { verifyInputNumber } from '@/util/commonFuncs.js'
import { webId } from '@/util/configure'
import {
  regReplaceCuttingDockInputCheck,
  regReplaceCuttingParamsDiameter,
} from '@/util/regReplace'
import { mapGetters, mapState } from 'vuex'

import { cuttingDockStationSetup } from './config/cuttingDock.config'
import {
  knifeStoreConfigList as knifeStoreConfig,
  mentaojiConfig,
} from './config/tableConfig'

export default {
  components: {
    pageTable,
    AnchorNav,
    newContent,
    // knifeTable,
  },
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    ncExportSetting: {
      type: [Array, Object],
      default: () => ({}),
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 冻结 不可修改
      tableConfig: Object.freeze(mentaojiConfig),
      isPre: false,
      formData: {
        // 设备类型
        single_double_station: '',
        // 记录刀库
        knives: [],
        // 工位1自动上料代码
        autoSendStockCode: '',
        // 工位2自动上料代码
        evenAutoSendStockCode: '',
        // 翻面代码
        // 翻面工位1
        rollCode: '',
        // 翻面工位2
        evenRollCode: '',
        // 自动下料代码1
        oddAutoBaitingCode: '',
        // 自动下料代码2
        evenAutoBaitingCode: '',
        // 工位1开始代码
        oddBeginCode: '',
        // 工位2开始代码
        evenBeginCode: '',
        // 正面开始代码
        beginCodeF: '',
        // 反面开始代码
        endCodeF: '',
        // 正面结束代码
        beginCodeB: '',
        // 反面结束代码
        endCodeB: '',
        // 工位1结束代码
        oddEndCode: '',
        // 工位2结束代码
        evenEndCode: '',
        // 暂停代码
        pauseCode: '',
        //首把刀预启动指令向前插入行数
        firstKnifPreStartCodeInsert: '',
        //第二把及后面的刀预启动指令向前插入行数
        preStartCodeInsert: '',
        // 真空泵1暂停代码
        oddVacuumPumpPauseCode: '',
        // 真空泵2暂停代码
        evenVacuumPumpPauseCode: '',
        // 真空泵暂停代码
        vacuumPumpPauseCode: '',
        // 门套机
        mentaoji_saws: [],
        // 工位1正面注释代码
        first_front_annotation_code: '',
        // 工位2正面注释代码
        second_front_annotation_code: '',
      },
      // 标签池
      tagList: ['成品宽度', '成品长度', '主轴刀编号', '垂直钻编号', '侧钻编号'],
      dragWord: '',
      // 记录行索引
      rowIndex: -1,
      // 记录列索引
      columnIndex: -1,
      // 记录当前选中的行
      currentRow: {},
      navs: cuttingDockNavs,
      // 门套机选中行
      mentaojiCurrentRow: {},
      // 门套机锯片
      mentaoji_enable: false,
      cuttingDockStationSetup,
    }
  },
  watch: {
    obj() {
      if (Object.keys(this.obj).length > 0) {
        let s = JSON.parse(JSON.stringify(this.obj))
        let a = []
        Object.keys(s.knives).forEach((v) => {
          a.push(s.knives[v])
        })
        s.knives = a
        let ss = ['millableHole', 'millableSlot', 'slotable', 'holeable']
        s.knives.forEach((v) => {
          v.knifePurpose = []
          ss.forEach((x) => {
            if (v[x] == true) {
              v.knifePurpose.push(x)
            }
          })
        })
        //将后端传来的-1显示为空
        if (s.firstKnifPreStartCodeInsert === -1) {
          s.firstKnifPreStartCodeInsert = ''
        }
        if (s.preStartCodeInsert === -1) {
          s.preStartCodeInsert = ''
        }
        //若参数中值不为-1且不为空，复选框为选中状态
        if (
          (s.firstKnifPreStartCodeInsert &&
            s.firstKnifPreStartCodeInsert > -1) ||
          (s.preStartCodeInsert && s.preStartCodeInsert > -1)
        ) {
          this.isPre = true
        } else {
          // 解决在通过还原参数时，没有将isPre置为false的问题
          this.isPre = false
        }
        // 将雕刻机类型保存在store中，便于其他子组件使用
        this.$store.commit(
          'equipEnvironment/changeStationType',
          s.single_double_station
        )
        this.$store.commit(
          'equipEnvironment/changeShowCode2',
          s.open_two_station_code
        )
        this.formData = s
      }
    },
  },
  computed: {
    ...mapGetters('equipEnvironment', ['showConfig']),
    ...mapState('equipEnvironment', ['showCode2']),
    container() {
      return this.$refs.wrap
    },
    knivesColumns() {
      if (!this.showConfig || !this.showCode2) {
        return knifeStoreConfig.concat({
          label: 'cuttingDock.knives.preStartCode',
          props: 'preStartCode',
          width: 140,
          isCheck: false,
        })
      }
      return knifeStoreConfig.concat([
        {
          label: 'cuttingDock.knives.startCode2',
          props: 'startCode2',
          width: 160,
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.stopCode2',
          props: 'stopCode2',
          width: 160,
          isCheck: false,
        },
        {
          label: 'cuttingDock.knives.preStartCode',
          props: 'preStartCode',
          width: 140,
          isCheck: false,
        },
      ])
    },
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    go(selector) {
      document.querySelector(selector).scrollIntoView({ block: 'center' })
    },
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改单元格样式
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px'
      }
    },
    // 更改表头单元格样式
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa'
      }
      return 'background: #fafafa'
    },
    // 更改表头行样式
    headerRowStyle({ row, rowIndex }) {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    // 点击单元格切换成输入框
    cellClick(row, column, cell, event) {
      if (column.property == 'id' || column.property == 'desc') {
        return
      }
      this.rowIndex = row.index
      this.columnIndex = column.index
      if (column.property != 'use' && column.property != 'cutable') {
        this.$nextTick(() => {
          this.$refs['editInput'][0].focus()
        })
      }
    },
    // 修改表格中的值
    inputBlur(row, prop) {
      row['desc'] = row['name']
      this.rowIndex = -1
      this.columnIndex = -1
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
      if (['startCode2', 'stopCode2'].includes(prop)) {
        buryPointApi(
          'equipment_dock',
          prop == 'startCode2' ? 'start_code2' : 'stop_code2'
        )
      }
    },
    // 添加刀具
    handleAddNewKnife() {
      this.formData.knives.push({
        desc: '',
        name: '',
        diameter: '',
        cutable: false,
        use: '',
        moveZF: '',
        cutZF: '',
        moveF: '',
        cutF: '',
        startCode: '',
        startCode2: '',
        stopCode: '',
        stopCode2: '',
        preStartCode: '',
        knifePurpose: [],
        [webId]: genUniId(),
      })
      this.rowIndex = this.formData.knives.length - 1
      this.columnIndex = 1
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    // 删除刀具
    handleDeleteKnife() {
      this.formData.knives.splice(
        this.formData.knives.findIndex((v) => v.index == this.currentRow.index),
        1
      )
    },
    // 选中刀具
    choseKnife(row, column, event) {
      this.currentRow = row
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    // 只能有一把刀选中开料
    onlyOneChecked(row) {
      if (row.cutable == false) {
        this.formData.knives.forEach((v) => {
          v.cutable = false
        })
        row.cutable = true
      }
      if (row.cutable == true) {
        this.formData.knives.forEach((v) => {
          v.cutable = false
        })
        row.cutable = true
      }
    },
    // scope.row, knife.props, knife.isCheck
    handleInputCheck(row, prop, flag) {
      row['desc'] = row['name']
      if (flag) {
        row[prop] = regReplaceCuttingDockInputCheck(row[prop])
      }
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      }
    },
    handleChangeNum() {
      if (!this.isPre) {
        this.formData.firstKnifPreStartCodeInsert = ''
        this.formData.preStartCodeInsert = ''
      }
    },
    //门套机插入行
    addLineMentaoji() {
      const initData = {
        serial_num: this.formData.mentaoji_saws.length + 1,
        saw_width: '',
        saw_direction: 'H',
        cut_from: '0000',
        move_speed: '',
        cut_speed: '',
        start_code: '',
        end_code: '',
        saw_down_code: '',
        saw_up_code: '',
        abscissa: 'X',
        ordinate: 'Y',
        webId: genUniId(),
      }
      this.formData.mentaoji_saws.push(initData)
    },
    //门套机删除行
    deleteLineMentaoji() {
      let deleteRowIndex = this.formData.mentaoji_saws.findIndex(
        (item) => item == this.mentaojiCurrentRow
      )
      if (this.formData.mentaoji_saws.length != 0) {
        this.formData.mentaoji_saws.splice(deleteRowIndex, 1)
        // 删除数据后需要对所有的数据进行编号重新排序
        // 判断删除的数据是否是最后一个，避免多余的循环排序，删除数据是最后一个时不需要走重新编号逻辑
        if (deleteRowIndex !== this.formData.mentaoji_saws.length) {
          for (
            let index = 0;
            index < this.formData.mentaoji_saws.length;
            index++
          ) {
            this.formData.mentaoji_saws[index].serial_num = index + 1
          }
        }
      }
    },
    // 空走速度 || 加工速度 输入校验
    tableInputChange(row, prop) {
      const oldValue = row[prop] + ''
      if (prop == 'move_speed' || prop == 'cut_speed') {
        verifyInputNumber(row[prop], row, [prop], '5-3')
      }
      if (
        (prop === 'move_speed' && row[prop] == 0 && oldValue != 0) ||
        (prop === 'cut_speed' && row[prop] == 0 && oldValue != 0)
      ) {
        row[prop] = ''
      }
      if (!oldValue) {
        row[prop] = ''
      }
    },
    // 选中门套机选项
    clickRow(row) {
      this.mentaojiCurrentRow = row
    },
    // 设备类型切换
    handleChangeStationType(val) {
      // 将新值保存到store中
      const { target } = val
      this.$store.commit('equipEnvironment/changeStationType', target.value)
      // 添加埋点
      buryPointApi('engraving', 'toggle_single_double_station')
    },
    handleChangeShowCode2(e) {
      const { checked } = e.target
      this.$store.commit('equipEnvironment/changeShowCode2', checked)
      // TODO: 添加埋点
      buryPointApi('engraving', 'toggle_show_code2')
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/style/engraving.less';

p {
  height: 42px;
  line-height: 42px;
}
.preStartEquipmentContent {
  display: flex;
}
/deep/.el-checkbox {
  height: 32px;
  margin-right: 20px;
  padding-top: 8px;
}

.cutting-dock {
  overflow: auto;
  //padding会导致滚动条的高度发生变化
  background-color: #fff;
  .wrap {
    .navRightWrapWidth();
  }

  .head-title {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    color: #000;
    font-weight: 600;
    font-size: 18px;
  }
  // 单双工位设置
  .station-setup {
    .second-title {
      padding-left: 24px;
    }
  }
  .knife-table {
    background: #fff;
  }
  .code-box {
    width: 100%;
    // margin: 24px auto 0 auto;
    background: #fff;
    .tag-lake {
      box-sizing: border-box;
      width: 100%;
      padding: 32px 24px 32px 24px;
      .tag-lake-title {
        display: flex;
        display: inline-block;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding: 11px 24px;
        background: #e9e9e9;
        span:first-child {
          margin-right: 8px;
          color: #000;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .tag-list {
        box-sizing: border-box;
        width: 100%;
        padding: 24px 24px 17px 24px;
        border: 1px solid #e9e9e9;
        span {
          display: inline-block;
          width: 102px;
          height: 32px;
          margin-right: 8px;
          color: #5a5a5a;
          line-height: 32px;
          text-align: center;
          background: #eaf4f6;
          cursor: pointer;
          user-select: none;
        }
      }
    }
    .code-content {
      .code-content-title {
        // display: block;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 24px 16px 24px;
        // border-bottom: 1px solid #e9e9e9;
      }
      .text-area {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0px 24px 0 24px;
        div {
          margin-bottom: 24px;
        }
        div:nth-child(2n + 1) {
          margin-right: 24px;
        }
        span {
          color: rgba(0, 0, 0, 0.8);
          font-weight: 600;
          cursor: default;
        }
        textarea {
          display: block;
          width: 466px;
          height: 113px;
          margin-top: 8px;
          border: 1px solid #dddddd;
          border-radius: 4px;
          resize: none;
        }
        .text-area-tip {
          font-size: 12px;
          color: #999999;
        }
      }
      .preLines {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        /deep/.el-input {
          width: 60px;
          margin-right: 6px;
          margin-bottom: 12px;
          margin-left: 6px;
          input {
            text-align: center;
          }
        }
      }
    }
  }
}
.knife-table {
  .changeKnifeCodeCheckbox {
    display: flex;
    padding-left: 24px;
    .text {
      margin-right: 5px;
    }
  }
  .main-table {
    box-sizing: border-box;
    // max-height: 407px;
    padding: 24px;
    /deep/.el-table {
      &::before {
        background: transparent;
      }
      .has-gutter tr {
        background-color: #fafafa;
      }
      .el-table__cell.gutter {
        background-color: rgb(250, 250, 250);
      }
      .hidden-columns {
        display: none;
      }
      .el-table__body-wrapper {
        // max-height: 340px;
        // overflow: scroll;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
      }
      .knives_type {
        .icon-revise-show {
          display: none;
        }
        &:hover .icon-revise-show {
          display: inline-block;
        }
      }
    }
  }
  .operation-btn {
    box-sizing: border-box;
    padding: 0 24px 0 24px;
    .danger-btn {
      color: #ff4d4f;
      &.ant-btn {
        border-color: #ff4d4f;
      }
    }
  }
  .sawCheckbox-wrap {
    margin-left: 24px;
  }
  .knife-purpose {
    display: flex;
    flex-wrap: wrap;
    /deep/.ant-checkbox-wrapper {
      margin-left: 0;
      &:nth-child(3) {
        margin-right: 8px;
      }
    }
    /deep/.ant-checkbox + span {
      padding-right: 0;
    }
  }
}
.custom-color {
  color: #8c8c8c;
}
.feeding-tips {
  color: red;
}
.textarea-length {
  text-align: right;
  margin: 0 !important;
  /deep/ .ant-radio-wrapper {
    width: 100% !important;
    text-align: left !important;
  }
}
#engraving_setting_mentaoji_saws_saw_direction_radio,
#engraving_setting_mentaoji_saws_cut_from_radio {
  line-height: 0.5;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.toast-span {
  color: red;
}
</style>
