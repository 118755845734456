export interface Nav {
  href: string
  title: string
}
export const ncExportSettingNavs: Array<Nav> = [
  {
    href: 'filename-setting',
    title: 'cuttingDock.NCExportSettings.tab1',
  },
  {
    href: 'merge-box',
    title: 'cuttingDock.NCExportSettings.tab2',
  },
  {
    href: 'annotation',
    title: 'cuttingDock.NCExportSettings.tab3',
  },
  {
    href: 'format-type',
    title: 'cuttingDock.NCExportSettings.tab4',
  },
  {
    href: 'format-file',
    title: 'cuttingDock.NCExportSettings.tab5',
  },
  {
    href: 'g-order',
    title: 'cuttingDock.NCExportSettings.tab6',
  },
  {
    href: 'fileFormatSetting',
    title: 'cuttingDock.NCExportSettings.tab7',
  },
]

export const cuttingDockNavs: Array<Nav> = [
  {
    href: 'knife-table',
    title: 'common.knivesStore',
  },
  {
    href: 'code-box',
    title: 'common.other',
  },
  {
    href: 'operation-starting',
    title: 'cuttingDock.stationStart',
  },
  {
    href: 'both-sides-starting',
    title: 'cuttingDock.frontBackStart',
  },
  {
    href: 'both-sides-ending',
    title: 'cuttingDock.frontBackEnd',
  },
  {
    href: 'plank-turn-over',
    title: 'common.roll',
  },
  {
    href: 'plank-auto-material',
    title: 'cuttingDock.autoBaitingCode',
  },
  {
    href: 'operation-ending',
    title: 'cuttingDock.stationEnd',
  },
  {
    href: 'suspend',
    title: 'common.pause',
  },
  {
    href: 'hierarchy-cut',
    title: 'cuttingDock.preStartEquipment',
  },
  {
    href: 'pump-suspend',
    title: 'cuttingDock.pumpPause',
  },
]

export const cuttingParamsNavs: Array<Nav> = [
  {
    href: 'hole-slot-focus',
    title: 'cuttingDock.cuttingParams.holeSlotMainSide',
  },
  {
    href: 'board-slot-place',
    title: 'cuttingDock.cuttingParams.sideSlotPut',
  },
  {
    href: 'common-side-cut',
    title: 'cuttingDock.cuttingParams.commonSide',
  },
  {
    href: 'common-side-accelerate',
    title: 'cuttingDock.cuttingParams.commonSpeed',
  },
  {
    href: 'table-board',
    title: 'cuttingDock.labelingSettings.countertopSetting.title',
  },
  {
    href: 'paring-off',
    title: 'cuttingDock.cuttingParams.edgeSetting',
  },
  {
    href: 'surplus-setting',
    title: 'cuttingDock.cuttingParams.surplusSetting',
  },
  {
    href: 'cut-hole-way',
    title: 'cuttingDock.cuttingParams.cutHoleWay',
  },
  {
    href: 'optimize-speed',
    title: 'cuttingDock.cuttingParams.workSpeedOp',
  },
  {
    href: 'hierarchy-cutting',
    title: 'cuttingDock.cuttingParams.stepCutting',
  },
  {
    href: 'hierarchy-setting',
    title: 'cuttingDock.cuttingParams.splitPlankThick',
  },
  {
    href: 'move-plank',
    title: 'cuttingDock.cuttingParams.movePlankSetting',
  },
  {
    href: 'long-door-plank',
    title: 'cuttingDock.cuttingParams.longCut',
  },
  {
    href: 'decimals',
    title: 'cuttingDock.cuttingParams.numberFixed',
  },
  {
    href: 'buffer-code',
    title: 'cuttingDock.cuttingParams.bufferCode',
  },
  {
    href: 'interval-setting',
    title: 'cuttingDock.cuttingParams.layoutGap',
  },
  {
    href: 'waste_auto_cutting',
    title: 'cuttingDock.cuttingParams.wasteCutting',
  },
  {
    href: 'ZK_datum_point_customize',
    title: 'cuttingDock.cuttingParams.zkPointCustomize',
  },
  {
    href: 'saw-setting',
    title: 'cuttingDock.cuttingParams.sawSetting',
  },
]

export const frontWorkingNavs: Array<Nav> = [
  {
    href: 'front-knife-table',
    title: 'common.knivesStore',
  },
  {
    href: 'front-punch-setting',
    title: 'sideHoleMachine.holeSetting',
  },
  {
    href: 'front-lot-broaching',
    title: 'sideHoleMachine.slotSetting',
  },
]

export const sideWorkingNavs: Array<Nav> = [
  {
    href: 'knife-table',
    title: 'common.knivesStore',
  },
  {
    href: 'device-parameter-settings',
    title: 'sideHoleMachine.equipParamsSetting',
  },
  {
    href: 'punch-setting',
    title: 'sideHoleMachine.holeSetting',
  },
  {
    href: 'slot-broaching',
    title: 'sideHoleMachine.slotSetting',
  },
  {
    href: 'ramino-connection',
    title: 'sideHoleMachine.raminoSetting',
  },
  {
    href: 'mortise-tenon-connection',
    title: 'sideHoleMachine.mortiseTenonSetting',
  },
  {
    href: 'ym-connection',
    title: 'sideHoleMachine.ymSetting',
  },
  {
    href: 'coordinate',
    title: 'sideHoleMachine.coordinateValue',
  },
  {
    href: 'coord-switch',
    title: 'sideHoleMachine.coordSwitch',
  },
  {
    href: 'encoding-format',
    title: 'sideHoleMachine.codeFormat',
  },
  {
    href: 'suffix-format',
    title: 'sideHoleMachine.suffixSetting',
  },
]
