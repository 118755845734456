<template>
  <div class="export-setting h100">
    <div class="w100 h100 flex">
      <AnchorNav :getContainer="() => container" :navs="navs" />
      <div class="wrap h100 y-auto" ref="wrap">
        <span class="head-title" id="filename-setting">{{
          $t('cuttingDock.NCExportSettings.tab1')
        }}</span>
        <div class="main-setting">
          <div class="auto-sort" v-if="showConfig">
            <span class="mr8">{{
              $t(
                'cuttingDock.NCExportSettings.fileNameSettings.twoStationAutoSort'
              )
            }}</span>
            <a-switch
              v-model="formData.fileNameSetting.doubleStationSortName"
              id="engraving_setting_exportSetting_doubleStationSortName_checkbox"
              size="small"
            ></a-switch>
          </div>
          <div
            class="custom-head"
            v-show="
              (formData.fileNameSetting.doubleStationSortName ||
                isShowSecondaryCatalog) &&
              showConfig
            "
          >
            <span class="mr8">{{
              $t('cuttingDock.NCExportSettings.machineOrder')
            }}</span>
            <a-checkbox
              v-model="formData.fileNameSetting.is_generate_process_sort_number"
              id="engraving_setting_exportSetting_isGenerateProcessSortNumber_checkbox"
              :disabled="!formData.fileNameSetting.doubleStationSortName"
            ></a-checkbox>
          </div>
          <div class="custom-head">
            <div class="plank-num-length">
              <span>{{
                $t('cuttingDock.NCExportSettings.fileNameSettings.reparation')
              }}</span>
              <a-input
                v-model="formData.fileNameSetting.stockNumLength"
                @input="inputCheck"
                @change="autoFileName"
                @dragover="handleDragOver"
                id="engraving_setting_exportSetting_stockNumLength_input"
              ></a-input>
              <span>{{
                $t('cuttingDock.NCExportSettings.fileNameSettings.figure')
              }}</span>
            </div>
          </div>
          <div class="custom-head">
            <div class="custom-label-head">
              <span
                >{{
                  $t(
                    'cuttingDock.NCExportSettings.fileNameSettings.labelerBegin'
                  )
                }}:</span
              >
              <div class="has-error-info">
                <a-input
                  v-model="formData.fileNameSetting.labelMachineName"
                  @input="wordLimit('labelMachineName', 1)"
                  :maxLength="16"
                  id="engraving_setting_exportSetting_labelmachineName_input"
                  @dragover="handleDragOver"
                ></a-input>
                <span class="error-info" ref="error1">{{ errorStr }}</span>
              </div>
            </div>
            <div class="custom-cure-head">
              <span
                >{{
                  $t(
                    'cuttingDock.NCExportSettings.fileNameSettings.machineCenter'
                  )
                }}：</span
              >
              <div class="has-error-info">
                <a-input
                  v-model="formData.fileNameSetting.machineCenterName"
                  @input="wordLimit('machineCenterName', 2)"
                  :maxLength="16"
                  id="engraving_setting_exportSetting_nachineCenterName_input"
                  @dragover="handleDragOver"
                ></a-input>
                <span class="error-info" ref="error2">{{ errorStr }}</span>
              </div>
            </div>
          </div>
          <div
            class="custom-head"
            id="engraving_setting_exportSetting_head_side"
          >
            <div class="custom-label-head">
              <span>{{
                $t('cuttingDock.NCExportSettings.fileNameSettings.front')
              }}</span>
              <div class="has-error-info">
                <a-input
                  v-model="formData.fileNameSetting.front_info"
                  @input="wordLimit('front_info', 3)"
                  :maxLength="16"
                  id="engraving_setting_exportSetting_front_input"
                  :class="[isNullFileSide ? 'custom-head-err-boder ' : '']"
                  @dragover="handleDragOver"
                ></a-input>
                <span class="error-info" ref="error3">{{ errorStr }}</span>
              </div>
            </div>
            <div class="custom-cure-head">
              <span>{{
                $t('cuttingDock.NCExportSettings.fileNameSettings.back')
              }}</span>
              <div class="has-error-info">
                <a-input
                  v-model="formData.fileNameSetting.back_info"
                  @input="wordLimit('back_info', 4)"
                  :maxLength="16"
                  id="engraving_setting_exportSetting_back_input"
                  :class="[isNullFileSide ? 'custom-head-err-boder ' : '']"
                  @dragover="handleDragOver"
                ></a-input>
                <span class="error-info" ref="error4">{{ errorStr }}</span>
              </div>
            </div>
            <div class="ml10">
              <span
                :class="[
                  'error-info',
                  isNullFileSide ? 'error-info-in' : '',
                  'err-text-tip',
                ]"
                >{{
                  $t('cuttingDock.NCExportSettings.fileNameSettings.emptyTip')
                }}</span
              >
            </div>
          </div>
          <div class="tag-lake">
            <div class="tag-box">
              <div class="custom-form-head">
                <span>{{
                  $t('cuttingDock.NCExportSettings.fileNameSettings.labelPool')
                }}</span>
                <span class="ml10">{{
                  $t('cuttingDock.NCExportSettings.fileNameSettings.dragTip')
                }}</span>
              </div>
              <div
                class="custom-form-main"
                v-for="(item, index) in tagList"
                :key="index"
              >
                <div class="tag-item">
                  <span>{{ item.title }}：</span>
                  <div class="tags">
                    <span
                      v-for="(items, indexs) in item.arr"
                      :key="indexs + '1'"
                      :class="`tag-item${indexs}`"
                      @dragstart="startDrag($event, items)"
                      @click="copyTag(indexs)"
                      data-clipboard-action="copy"
                      :data-clipboard-text="'{' + items.label_name_show + '}'"
                      draggable="true"
                      :id="`engraving_setting_exportSetting_tagList_${index}_${indexs}`"
                      >{{ items.label_name_show }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="custom-file-name">
              <div class="file-name-format">
                <span>{{
                  $t(
                    'cuttingDock.NCExportSettings.fileNameSettings.customizeName'
                  )
                }}</span>
                <div
                  @drop="dropOver($event)"
                  @dragover="handleDragOver($event)"
                  :ref="`format_table_input`"
                  @change="autoFileName"
                  class="file-format-box"
                >
                  <div v-for="(item, index) in nameArr" :key="index">
                    <span class="tag">
                      {{ item }}
                      <i
                        class="el-icon-close"
                        @click="removeItem(index)"
                        :id="`engraving_setting_exportSetting_tag_${index}_remove_btn`"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="file-has-delimiter">
                <span
                  >{{
                    $t('cuttingDock.labelingSettings.imgSetting.delimiter')
                  }}:</span
                >
                <a-input
                  v-model="formData.fileNameSetting.hasDelimiter"
                  @input="modifyDelimiter"
                  :maxLength="1"
                  id="engraving_setting_exportSetting_hasDelimiter_input"
                ></a-input>
              </div>
              <div class="file-name-example">
                <span>{{
                  $t('cuttingDock.NCExportSettings.fileNameSettings.example')
                }}</span>
                <span>{{ exampleFileName }}</span>
              </div>
            </div>
          </div>
          <div class="merge-box" id="merge-box">
            <div class="merge-box-title">
              <span>{{
                $t('cuttingDock.NCExportSettings.fileMerge.engravingMerge')
              }}</span>
              <a-switch
                default-checked
                v-model="isMergeFile"
                @change="fileMergeChange"
                id="engraving_setting_exportSetting_isMergeFile_switch"
              ></a-switch>
            </div>
            <div
              class="radio-check-box"
              v-show="isShowSecondaryCatalog || isMergeFile"
            >
              <a-radio-group
                v-model="mergeRadio"
                :disabled="!isMergeFile"
                @change="changeFileMerge"
              >
                <div class="radio-check-item">
                  <a-radio
                    value="doubleMerge"
                    :disabled="!isMergeFile"
                    id="engraving_setting_exportSetting_doubleMerge_radio"
                    ><span
                      id="engraving_setting_exportSetting_doubleMerge_radio_label"
                      >{{
                        $t('cuttingDock.NCExportSettings.fileMerge.zfMerge')
                      }}</span
                    ></a-radio
                  >
                  <a-checkbox-group
                    :disabled="mergeRadio != 'doubleMerge'"
                    v-model="frontBackStation"
                    @change="changeDoubleMerge"
                  >
                    <a-checkbox
                      :disabled="!isMergeFile"
                      value="bEnd1"
                      id="engraving_setting_exportSetting_doubleMerge_bEnd1_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleMerge_bEnd1_checkbox_label"
                        >{{
                          $t('cuttingDock.NCExportSettings.fileMerge.fEndCode')
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      :disabled="!isMergeFile"
                      value="fBegin1"
                      id="engraving_setting_exportSetting_doubleMerge_fBegin1_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleMerge_fBegin1_checkbox_label"
                        >{{
                          $t(
                            'cuttingDock.NCExportSettings.fileMerge.zBeginCode'
                          )
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      :disabled="!isMergeFile"
                      value="displayfbInfo"
                      >{{
                        $t(
                          'cuttingDock.NCExportSettings.fileMerge.displayfbInfo'
                        )
                      }}</a-checkbox
                    >
                  </a-checkbox-group>
                </div>
                <div class="radio-check-item">
                  <a-radio
                    value="singleStation"
                    id="engraving_setting_exportSetting_singleStation_radio"
                    ><span
                      id="engraving_setting_exportSetting_singleStation_radio_label"
                      >{{
                        $t('cuttingDock.NCExportSettings.fileMerge.singleMerge')
                      }}</span
                    ></a-radio
                  >
                  <a-checkbox-group
                    :disabled="mergeRadio != 'singleStation' || !isMergeFile"
                    v-model="singleStation"
                    @change="changeSingleStation"
                  >
                    <a-checkbox
                      value="bBegin2"
                      id="engraving_setting_exportSetting_singleStation_bBegin2_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_singleStation_bBegin2_checkbox_label"
                        >{{
                          $t(
                            'cuttingDock.NCExportSettings.fileMerge.fBeginCode'
                          )
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="bEnd2"
                      id="engraving_setting_exportSetting_singleStation_bEnd2_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_singleStation_bEnd2_checkbox_label"
                        >{{
                          $t('cuttingDock.NCExportSettings.fileMerge.fEndCode')
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="fBegin2"
                      id="engraving_setting_exportSetting_singleStation_fBegin2_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_singleStation_fBegin2_checkbox_label"
                        >{{
                          $t(
                            'cuttingDock.NCExportSettings.fileMerge.zBeginCode'
                          )
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="fEnd2"
                      id="engraving_setting_exportSetting_singleStation_fEnd2_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_singleStation_fEnd2_checkbox_label"
                        >{{
                          $t('cuttingDock.NCExportSettings.fileMerge.zEndCode')
                        }}</span
                      ></a-checkbox
                    >
                  </a-checkbox-group>
                </div>
                <div class="radio-check-item" v-if="showConfig">
                  <a-radio
                    value="doubleStation"
                    id="engraving_setting_exportSetting_doubleStation_radio"
                    ><span
                      id="engraving_setting_exportSetting_doubleStation_radio_label"
                      >{{
                        $t('cuttingDock.NCExportSettings.fileMerge.doubleMerge')
                      }}</span
                    ></a-radio
                  >
                  <a-checkbox-group
                    :disabled="mergeRadio != 'doubleStation' || !isMergeFile"
                    v-model="doubleStation"
                    @change="changeDoubleStation"
                  >
                    <a-checkbox
                      value="bBegin3"
                      id="engraving_setting_exportSetting_doubleStation_bBegin3_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleStation_bBegin3_checkbox_label"
                        >{{
                          $t(
                            'cuttingDock.NCExportSettings.fileMerge.fBeginCode'
                          )
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="bEnd3"
                      id="engraving_setting_exportSetting_doubleStation_bEnd3_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleStation_bEnd3_checkbox_label"
                        >{{
                          $t('cuttingDock.NCExportSettings.fileMerge.fEndCode')
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="fBegin3"
                      id="engraving_setting_exportSetting_doubleStation_fBegin3_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleStation_fBegin3_checkbox_label"
                        >{{
                          $t(
                            'cuttingDock.NCExportSettings.fileMerge.zBeginCode'
                          )
                        }}</span
                      ></a-checkbox
                    >
                    <a-checkbox
                      value="fEnd3"
                      id="engraving_setting_exportSetting_doubleStation_fEnd3_checkbox"
                      ><span
                        id="engraving_setting_exportSetting_doubleStation_fEnd3_checkbox_label"
                        >{{
                          $t('cuttingDock.NCExportSettings.fileMerge.zEndCode')
                        }}</span
                      ></a-checkbox
                    >
                  </a-checkbox-group>
                </div>
              </a-radio-group>
            </div>
          </div>
          <div class="annotation" id="annotation">
            <span class="head-title">{{
              $t('cuttingDock.NCExportSettings.tab3')
            }}</span>
            <div class="annotation-box">
              <div>
                <label for="test">
                  <span>{{
                    $t(
                      'cuttingDock.NCExportSettings.marginalNotes.addPlankInfo'
                    )
                  }}</span>
                  <a-checkbox
                    id="test"
                    v-model="formData.fileAnnotation.beforePlankAddInfo"
                    class="engraving_setting_exportSetting_beforePlankAddInfo_checkbox"
                  ></a-checkbox>
                </label>
              </div>
              <div class="plank-info">
                <span>{{
                  $t('cuttingDock.NCExportSettings.marginalNotes.plankInfo')
                }}</span>
                <a-textarea
                  id="engraving_setting_exportSetting_plankInfo_textarea"
                  @blur="
                    blurSelect(
                      $event,
                      formData.fileAnnotation.beforePlankAddCustomizeInfo,
                      customTag.plank_info
                    )
                  "
                  v-model="
                    formData.fileAnnotation.beforePlankAddCustomizeInfo
                      .template_string
                  "
                  @change="
                    tagPoolTextareaChange(
                      formData.fileAnnotation.beforePlankAddCustomizeInfo,
                      customTag.plank_info
                    )
                  "
                  @drop="
                    dragTagOver(
                      $event,
                      formData.fileAnnotation.beforePlankAddCustomizeInfo,
                      customTag.plank_info
                    )
                  "
                />
              </div>
              <div v-if="showConfig">
                <span>{{
                  $t(
                    'cuttingDock.NCExportSettings.marginalNotes.addStationCodeFirst'
                  )
                }}</span>
                <a-checkbox
                  v-model="formData.fileAnnotation.beforePlank"
                  id="engraving_setting_exportSetting_beforePlank_textarea"
                ></a-checkbox>
              </div>
              <div v-if="showConfig">
                <span>{{
                  $t(
                    'cuttingDock.NCExportSettings.marginalNotes.addStationCodeSecond'
                  )
                }}</span>
                <a-checkbox
                  v-model="formData.fileAnnotation.beforeStartKnife"
                  id="engraving_setting_exportSetting_beforeStartKnife_checkbox"
                ></a-checkbox>
              </div>
            </div>
            <div class="tag-pool">
              <DataTagPool
                :tagList="customTag.plank_info"
                :dropData.sync="dropData"
                @selectChange="selectChange"
                @dragStart="handleDargStart"
              ></DataTagPool>
            </div>
          </div>
          <div class="format" id="format-type">
            <span class="head-title">{{
              $t('cuttingDock.NCExportSettings.tab4')
            }}</span>
            <div class="format-box">
              <div class="format-check-box">
                <div>
                  <span>{{
                    $t('cuttingDock.NCExportSettings.fileType.zAddtion')
                  }}</span>
                  <a-checkbox
                    v-model="formData.fileType.alwaysZ"
                    id="engraving_setting_exportSetting_alwaysZ_checkbox"
                  ></a-checkbox>
                </div>
                <div>
                  <span>{{
                    $t('cuttingDock.NCExportSettings.fileType.addSemicolon')
                  }}</span>
                  <a-checkbox
                    class="mr10"
                    id="engraving_setting_exportSetting_semicolon_checkbox"
                    v-model="formData.fileType.semicolon"
                  ></a-checkbox>
                  <span class="color-a"
                    >（{{
                      $t('cuttingDock.NCExportSettings.fileType.semicolonTip')
                    }}）</span
                  >
                </div>
              </div>
              <div class="xinghui">
                <span class="mr10"
                  >{{
                    $t('cuttingDock.NCExportSettings.fileType.fileName')
                  }}:</span
                >
                <a-checkbox
                  class="mr10"
                  v-model="formData.fileType.exportUsingBanlu"
                  id="engraving_setting_exportSetting_exportUsingBanlu_checkbox"
                ></a-checkbox>
                <a-radio-group
                  v-model="formData.fileType.exportFormat"
                  @change="changeExportFormat"
                >
                  <a-radio
                    value="default"
                    :disabled="!formData.fileType.exportUsingBanlu"
                    id="engraving_setting_exportSetting_exportFormat_radio"
                    ><span
                      id="engraving_setting_exportSetting_exportFormat_radio_label"
                      >{{
                        $t('cuttingDock.NCExportSettings.fileType.defaultName')
                      }}</span
                    ></a-radio
                  >
                  <a-radio
                    value="xinghui"
                    :disabled="!formData.fileType.exportUsingBanlu"
                    id="engraving_setting_exportSetting_xinghui_radio"
                    ><span
                      id="engraving_setting_exportSetting_xinghui_radio_label"
                      >{{
                        $t(
                          'cuttingDock.NCExportSettings.fileType.dedicatedName'
                        )
                      }}</span
                    >

                    <span class="xinghui-zhushi">
                      (<a-checkbox
                        v-model="xinghuiE2_9"
                        @change="changeXinghuiAnnotation"
                        :disabled="
                          formData.fileType.exportFormat != 'xinghui' ||
                          !formData.fileType.exportUsingBanlu
                        "
                        id="engraving_setting_exportSetting_xinghui_zhushi_checkbox"
                        ><span
                          id="engraving_setting_exportSetting_xinghui_zhushi_checkbox_label"
                          >{{
                            $t('cuttingDock.NCExportSettings.fileType.xhNotes')
                          }}</span
                        ></a-checkbox
                      >)
                    </span>
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="format-radio-box">
                <span style="line-height: 32px" class="mr10"
                  >{{
                    $t('cuttingDock.NCExportSettings.fileType.format')
                  }}:</span
                >
                <el-input
                  class="mr10"
                  size="small"
                  v-model="formData.fileType.fileExtensionName"
                  style="width: 200px"
                  ref="fileNameType"
                  id="ncExportSetting_fileNameType_input"
                >
                </el-input>
                <span style="line-height: 32px"
                  >{{ $t('cuttingDock.NCExportSettings.fileType.example')
                  }}{{ formData.fileType.fileExtensionName }}</span
                >
                <div v-show="isContainsSpecialChars" class="err-tip absolute">
                  {{
                    $t('cuttingDock.NCExportSettings.fileType.fileNameErrTip')
                  }}
                  &lt; &gt; |
                </div>
              </div>
              <div>
                <span style="line-height: 32px" class="mr10"
                  >{{
                    $t('cuttingDock.NCExportSettings.fileType.stillGenerate')
                  }}:</span
                >
                <a-checkbox
                  class="mr10"
                  v-model="formData.fileType.always_back_nc"
                  id="ncExportSetting_always_back_nc_checkbox"
                ></a-checkbox>
                <span class="color-a"
                  >（{{
                    $t('cuttingDock.NCExportSettings.fileType.generateTip')
                  }}）</span
                >
              </div>
            </div>
          </div>
          <div class="format" id="format-file">
            <span class="head-title">{{
              $t('cuttingDock.NCExportSettings.tab5')
            }}</span>
            <div class="format-box">
              <span class="small-title">{{
                $t('cuttingDock.NCExportSettings.categorize')
              }}</span>
              <a-checkbox
                v-model="formData.folderCategory.useCategoryFolder"
                id="engraving_setting_exportSetting_useCategoryFolder_radio"
              ></a-checkbox>
            </div>
            <div class="format-box">
              <span class="small-title"
                >{{ $t('cuttingDock.NCExportSettings.folderSetting') }}：</span
              >
              <a-checkbox
                v-model="formData.folderCategory.same_size_sheet_cutting_files"
                id="engraving_setting_exportSetting_useSizeCategoryFolder_radio"
              ></a-checkbox>
              <span class="cutting-tip color-a"
                >（{{
                  $t('cuttingDock.NCExportSettings.folderSettingTip')
                }}）</span
              >
            </div>
          </div>
          <div class="g-order" id="g-order">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.tab6') }}
            </div>
            <div class="common-box">
              <span class="small-title mr10">{{
                $t('cuttingDock.NCExportSettings.simplifyG')
              }}</span>
              <a-checkbox
                v-model="
                  formData.processingInstructionSetting.GCommandShorthand
                "
                id="engraving_setting_exportSetting_GCommandShorthand_radio"
              ></a-checkbox>
            </div>
            <div class="common-box">
              <span class="small-title mr10">{{
                $t('cuttingDock.NCExportSettings.ZToW')
              }}</span>
              <!-- <a-checkbox
                v-model="formData.processingInstructionSetting.z_to_w_normal"
                >常规刀具</a-checkbox
              > -->
              <a-checkbox
                v-model="
                  formData.processingInstructionSetting.z_to_w_gang_drill
                "
                >{{ $t('cuttingDock.NCExportSettings.knives') }}</a-checkbox
              >
            </div>
          </div>
          <div class="fileFormatSetting" id="fileFormatSetting">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.tab7') }}
            </div>
            <div class="file-format-setting-box">
              <div class="common-box">
                <span class="small-title">{{
                  $t('cuttingDock.NCExportSettings.lineBreakSetting')
                }}</span>
                <a-radio-group v-model="formData.fileFormatSetting.platform">
                  <a-radio
                    value="windows"
                    id="engraving_setting_exportSetting_platform_windows_radio"
                    ><span
                      id="engraving_setting_exportSetting_platform_windows_radio_label"
                      >windows</span
                    ></a-radio
                  >
                  <a-radio
                    value="unix"
                    id="engraving_setting_exportSetting_platform_unix_radio"
                    ><span
                      id="engraving_setting_exportSetting_platform_unix_radio_label"
                      >unix</span
                    ></a-radio
                  >
                </a-radio-group>
              </div>
              <div class="common-box">
                <span class="small-title">{{
                  $t('cuttingDock.NCExportSettings.codeFormatSetting')
                }}</span>
                <a-radio-group
                  v-model="formData.fileFormatSetting.fileEncoding"
                >
                  <a-radio
                    value="utf-8"
                    id="engraving_setting_exportSetting_fileEncoding_utf8_radio"
                    ><span
                      id="engraving_setting_exportSetting_fileEncoding_utf8_radio_label"
                      >UTF-8</span
                    ></a-radio
                  >
                  <a-radio
                    value="utf8bom"
                    id="engraving_setting_exportSetting_fileEncoding_utf8bom_radio"
                    ><span
                      id="engraving_setting_exportSetting_fileEncoding_utf8bom_radio_label"
                      >{{ $t('cuttingDock.NCExportSettings.utf8bom') }}</span
                    ></a-radio
                  >
                  <a-radio
                    value="gbk"
                    id="engraving_setting_exportSetting_fileEncoding_gbk_radio"
                    ><span
                      id="engraving_setting_exportSetting_fileEncoding_gbk_radio_label"
                      >ansi</span
                    ></a-radio
                  >
                </a-radio-group>
              </div>
            </div>
          </div>
          <div class="plank-code" id="plank-code">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.oneCodeSetting') }}
            </div>
            <div class="common-box">
              <div class="mb20">
                <span class="small-title mr10"
                  >{{ $t('cuttingDock.NCExportSettings.uniqueBarCode') }}
                </span>
                <a-checkbox
                  v-model="formData.barCodeSetting.uniqueBarCode"
                  id="engraving_setting_exportSetting_uniqueBarCode_checkbox"
                ></a-checkbox>
              </div>
              <span class="small-title mr10"
                >{{ $t('cuttingDock.NCExportSettings.simplifyOneCode') }}
              </span>
              <a-checkbox
                v-model="formData.barCodeSetting.genSimpleLabelBarcode"
                id="engraving_setting_exportSetting_genSimpleLabelBarcode_checkbox"
              ></a-checkbox>
            </div>
          </div>
          <div class="plank-code" id="plank-code">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.codeLocation') }}
            </div>
            <div class="common-box">
              <span class="small-title mr10"
                >{{ $t('cuttingDock.NCExportSettings.generateLocation') }}
              </span>
              <a-checkbox
                v-model="
                  formData.codePositionSetting.stationEndCodeBeforeEndCodeB
                "
                id="engraving_setting_exportSetting_stationEndCodeBeforeEndCodeB_checkbox"
              ></a-checkbox>
            </div>
          </div>
          <div class="plank-code" id="plank-code">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.emergencySetting') }}
            </div>
            <div class="common-box">
              <span class="small-title mr10"
                >{{ $t('cuttingDock.NCExportSettings.show') }}
              </span>
              <a-checkbox
                v-model="isShowEmergencySetting"
                id="engraving_setting_exportSetting_isShowEmergencySetting_checkbox"
              ></a-checkbox>
              <span class="color-a"
                >（{{ $t('cuttingDock.NCExportSettings.emergencyTip') }}）</span
              >
            </div>
            <div v-if="isShowEmergencySetting" class="ml20 mb10">
              <div>
                <el-input
                  style="width: 320px"
                  v-model="formData.emergencySetting.settingKey"
                  maxlength="20"
                  show-word-limit
                  :placeholder="
                    $t('cuttingDock.NCExportSettings.emergencyCode')
                  "
                  class="mb10"
                  id="engraving_setting_exportSetting_settingKey_input"
                ></el-input>
              </div>
              <div>
                <el-input
                  :placeholder="`${$t('cuttingDock.NCExportSettings.params')}1`"
                  style="width: 100px"
                  v-model="formData.emergencySetting.param1"
                  maxlength="20"
                  show-word-limit
                  class="mr10"
                  id="engraving_setting_exportSetting_param1_input"
                ></el-input>
                <el-input
                  :placeholder="`${$t('cuttingDock.NCExportSettings.params')}2`"
                  style="width: 100px"
                  v-model="formData.emergencySetting.param2"
                  maxlength="20"
                  show-word-limit
                  class="mr10"
                  id="engraving_setting_exportSetting_param2_input"
                ></el-input>
                <el-input
                  :placeholder="`${$t('cuttingDock.NCExportSettings.params')}3`"
                  style="width: 100px"
                  maxlength="20"
                  show-word-limit
                  v-model="formData.emergencySetting.param3"
                  id="engraving_setting_exportSetting_param3_input"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="plank-code" id="plank-code">
            <div class="head-title">
              {{ $t('cuttingDock.NCExportSettings.holeSetting') }}
            </div>
            <div class="common-box">
              <!-- <span class="small-title mr10"
                >{{ $t('cuttingDock.NCExportSettings.holeFileCategorize') }}
              </span>
              <a-checkbox
                id="engraving_setting_exportSetting_output56FDrillSortByCode_input"
                v-model="formData.drillFileSetting.output56FDrillSortByCode"
              ></a-checkbox>
              <span class="color-a">（{{ $t('cuttingDock.NCExportSettings.holeTip') }}）</span> -->
              <!-- 原“打孔文件按不同订单文件分文件夹单独放置”功能已迁移到“更多设置”页面中！” -->
              <goToMoreSettings
                :function-name="
                  translateLang(
                    'cuttingDock.NCExportSettings.fileManagerFunction'
                  )
                "
                :module-name="translateLang('main.head.fileManager')"
                router-path="/fileManage"
                :query-setting-id="$route.query.setting_id"
              >
              </goToMoreSettings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnchorNav from '@/components/anchorNav/index.vue'
import DataTagPool from '@/components/dataTagPool'
import goToMoreSettings from '@/components/goToMoreSettings.vue'
import { ncExportSettingNavs } from '@/util/anchorNavData.ts'
import { translate } from '@/util/commonFun'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    isShowSecondaryCatalog: {
      type: Boolean,
      default: false,
    },
  },
  components: { DataTagPool, AnchorNav, goToMoreSettings },
  data() {
    return {
      isContainsSpecialChars: false,
      isShowEmergencySetting: false,
      tagList: [],
      navs: ncExportSettingNavs,
      formData: {
        // 开料机文件中注释
        fileAnnotation: {
          beforePlank: false, // 板件开始前加入工位代码
          beforePlankAddInfo: false, // 板件开始前加入当前板件信息
          beforeStartKnife: false, // 换刀前加入工位代码
          //大板信息自定义
          beforePlankAddCustomizeInfo: {
            label_ids: null,
            template_string: '',
          },
        },
        // 开料机文件合并
        fileMerge: {
          bEnd1: false, // 正反面合并 反面结束代码
          fBegin1: false, // 正反面合并 正面开始代码
          displayfbInfo: false, //文件名显示正反面信息
          bBegin2: false, // 单工位合并 反面开始代码
          fBegin2: false, // 单工位合并 正面开始代码
          bEnd2: false, // 单工位合并 反面结束代码
          fEnd2: false, // 单工位合并 正面结束代码
          bBegin3: false, // 双工位合并 反面开始代码
          bEnd3: false, // 双工位合并 反面结束代码
          fBegin3: false, // 双工位合并 正面开始代码
          fEnd3: false, // 双工位合并 正面结束代码
          doubleEdgeCombine: false, // 正反面合并
          doubleStationCombine: false, // 双工位合并
          singleStationCombine: false, // 单工位合并
        },
        // 开料机文件中类型
        fileNameSetting: {
          customizeSetting: {
            headerIds: {},
            templateString: '',
          }, // 自定义文件名(格式)
          // front: '',
          // back: '',
          front_info: '',
          back_info: '',
          stockNumLength: '',
          hasDelimiter: '',
          doubleStationSortName: false, // 按双工位自动排序
          is_generate_process_sort_number: true,
          labelMachineName: '', // 标签机开头
          machineCenterName: '', // 加工中心开头
        },
        //
        fileType: {
          alwaysZ: false, // Z坐标补充
          exportFormat: 'default', // 星辉设备专用文件
          exportUsingBanlu: false, // 是否选中星辉设备专用文件
          fileNameType: 'NC', // 导出文件类型
          fileExtensionName: '.nc', // 导出文件类型
          semicolon: false, // 班鲁每行NC末尾加分号
          exportFormatAnnotation: '', // 是否选中E2-9星辉注释
          always_back_nc: '', //反面不加工时仍然生成仅含反面结束代码的反面NC文件
        },
        // 开料机文件夹
        folderCategory: {
          useCategoryFolder: false,
          // output56FDrillSortByCode: false,
          same_size_sheet_cutting_files: true,
        },
        processingInstructionSetting: {
          GCommandShorthand: false, // g指令
          z_to_w_normal: false,
          z_to_w_gang_drill: false,
        },
        //文件格式设置
        fileFormatSetting: {
          platform: 'windows',
          fileEncoding: 'utf-8',
          fileSuffix: '',
        },
        // 板件条码设置
        barCodeSetting: {
          uniqueBarCode: false,
          genSimpleLabelBarcode: false,
        },
        // 代码位置设置
        codePositionSetting: {
          stationEndCodeBeforeEndCodeB: false,
        },
        // 应急设置
        emergencySetting: {
          settingKey: '', //应急码
          param1: '', //参数1
          param2: '', //参数2
          param3: '', //参数3
        },
        // 打孔文件设置
        drillFileSetting: {
          output56FDrillSortByCode: false,
        },
      },
      isMergeFile: false, // 记录是否选中开料机文件合并
      singleStation: [], //记录选择的单工位合并
      doubleStation: [], // 记录选择的双工位合并
      frontBackStation: [], // 记录选择的正反面合并
      mergeRadio: '', // 记录开料机文件合并的单选
      xinghuiE2_9: false, // 记录是否选中星辉E2-9
      fileNameFormat: '',
      exampleFileName: '', // 自定义文件名示例
      dragItem: '', // 记录当前拖拽的标签
      checkObj: {
        doubleMerge: ['bEnd1', 'fBegin1', 'displayfbInfo'],
        singleStation: ['bBegin2', 'bEnd2', 'fBegin2', 'fEnd2'],
        doubleStation: ['bBegin3', 'bEnd3', 'fBegin3', 'fEnd3'],
      },
      nameArr: [],
      showError: false,
      errorStr: this.$t('cuttingDock.NCExportSettings.specialCodeErr'),
      dropData: {},
      // 自定义标签
      customTag: {
        plank_info: [],
      },
      // 选中DOM
      recodeSelect: null,
    }
  },
  watch: {
    'formData.fileType.fileExtensionName': {
      handler(newVal) {
        const specialChars = /[\\/:*?"<>|]/
        this.isContainsSpecialChars = specialChars.test(newVal)
        if (this.isContainsSpecialChars) {
          this.$refs.fileNameType.$el.style.borderColor = 'red'
        } else {
          this.$refs.fileNameType.$el.style.borderColor = '#3bbdd4'
        }
      },
      deep: true,
    },
    obj() {
      if (Object.keys(this.obj).length > 0) {
        this.formData = this.obj
        if (this.formData.fileMerge.doubleEdgeCombine == true) {
          this.mergeRadio = 'doubleMerge'
        } else if (this.formData.fileMerge.doubleStationCombine == true) {
          this.mergeRadio = 'doubleStation'
        } else if (this.formData.fileMerge.singleStationCombine == true) {
          this.mergeRadio = 'singleStation'
        } else {
          this.mergeRadio = ''
        }
        if (this.isNullFileSide) {
          this.formData.fileNameSetting.front_info = '正面'
          this.formData.fileNameSetting.back_info = '反面'
        }
        this.singleStation = []
        this.frontBackStation = []
        this.doubleStation = []
        this.checkObj.doubleMerge.forEach((v) => {
          if (this.formData.fileMerge[v] == true) {
            this.frontBackStation.push(v)
          }
        })
        this.checkObj.singleStation.forEach((v) => {
          if (this.formData.fileMerge[v] == true) {
            this.singleStation.push(v)
          }
        })
        this.checkObj.doubleStation.forEach((v) => {
          if (this.formData.fileMerge[v] == true) {
            this.doubleStation.push(v)
          }
        })

        if (this.formData.fileType.exportFormatAnnotation == 'xinghui') {
          this.xinghuiE2_9 = true
        }

        if (
          this.formData.fileMerge.doubleEdgeCombine ||
          this.formData.fileMerge.singleStationCombine ||
          this.formData.fileMerge.doubleStationCombine
        ) {
          this.isMergeFile = true
        }

        if (!this.formData.fileNameSetting.hasOwnProperty('hasDelimiter')) {
          this.formData.fileNameSetting.hasDelimiter = '/'
        }

        let pattern = /[^a-zA-Z0-9_@%\+\-\u4e00-\u9fa5]+$/g
        // 修改自定义的标签机开头, 去除特殊字符
        let labelStr = this.formData.fileNameSetting.labelMachineName
        if (labelStr != '') {
          if (pattern.test(labelStr)) {
            this.formData.fileNameSetting.labelMachineName = labelStr.replace(
              pattern,
              ''
            )
          }
        }
        // 修改自定义的加工中心开头, 去除特殊字符
        let machineStr = this.formData.fileNameSetting.machineCenterName
        if (machineStr != '') {
          if (pattern.test(machineStr)) {
            this.formData.fileNameSetting.machineCenterName =
              machineStr.replace(pattern, '')
          }
        }
        // 修改自定义的正面, 去除特殊字符
        let frontStr = this.formData.fileNameSetting.front_info
        if (frontStr != '') {
          if (pattern.test(frontStr)) {
            this.formData.fileNameSetting.front_info = frontStr.replace(
              pattern,
              ''
            )
          }
        }
        // 修改自定义的反面, 去除特殊字符
        let backStr = this.formData.fileNameSetting.back_info
        if (backStr != '') {
          if (pattern.test(backStr)) {
            this.formData.fileNameSetting.back_info = backStr.replace(
              pattern,
              ''
            )
          }
        }

        let tempName =
          this.formData.fileNameSetting.customizeSetting.templateString
        if (tempName != '') {
          let nameArr =
            this.formData.fileNameSetting.customizeSetting.templateString.match(
              /\{(.+?)\}/g
            )
          for (let i = 0; i < nameArr.length; i++) {
            nameArr[i] = nameArr[i].replace(/\{|\}/g, '')
          }
          this.nameArr = nameArr
          this.fileNameFormat = tempName
        }
        this.autoFileName()
      }
    },
    // nameArr(){
    //   this.dealNameArr()
    // }
  },
  computed: {
    ...mapGetters('equipEnvironment', ['showConfig']),
    container() {
      return this.$refs.wrap
    },
    isNullFileSide() {
      const fileNameSetting = this.formData.fileNameSetting
      return !fileNameSetting.front_info && !fileNameSetting.back_info
    },
  },
  methods: {
    ...mapMutations(['setNcLabel']),
    // 是否选中星辉E2-9注释
    changeXinghuiAnnotation() {
      this.formData.fileType.exportFormatAnnotation = this.xinghuiE2_9
        ? 'xinghui'
        : ''
    },
    // 切换文件名格式时, 判断E2-9格式
    changeExportFormat() {
      if (this.formData.fileType.exportFormat == 'default') {
        this.xinghuiE2_9 = false
        this.formData.fileType.exportFormatAnnotation = ''
      }
    },
    // 选中开料机文件合并
    fileMergeChange() {
      if (!this.isMergeFile) {
        this.mergeRadio = ''
        this.formData.fileMerge.doubleEdgeCombine = false
        this.formData.fileMerge.singleStationCombine = false
        this.formData.fileMerge.doubleStationCombine = false
      }
    },
    // 选中开料机文件合并单选
    changeFileMerge() {
      this.formData.fileMerge.doubleEdgeCombine = false
      this.formData.fileMerge.singleStationCombine = false
      this.formData.fileMerge.doubleStationCombine = false
      switch (this.mergeRadio) {
        case 'doubleMerge':
          this.formData.fileMerge.doubleEdgeCombine = true
          break
        case 'singleStation':
          this.formData.fileMerge.singleStationCombine = true
          break
        case 'doubleStation':
          this.formData.fileMerge.doubleStationCombine = true
          break
      }
    },
    // 更改正反面合并
    changeDoubleMerge() {
      this.checkObj.doubleMerge.forEach((v) => {
        this.formData.fileMerge[v] = false
      })
      this.frontBackStation.forEach((v) => {
        this.formData.fileMerge[v] = true
      })
    },
    // 更改单工位合并
    changeSingleStation() {
      this.checkObj.singleStation.forEach((v) => {
        this.formData.fileMerge[v] = false
      })
      this.singleStation.forEach((v) => {
        this.formData.fileMerge[v] = true
      })
    },
    // 更改双工位合并
    changeDoubleStation() {
      this.checkObj.doubleStation.forEach((v) => {
        this.formData.fileMerge[v] = false
      })
      this.doubleStation.forEach((v) => {
        this.formData.fileMerge[v] = true
      })
    },
    // 复制标签
    copyTag(index) {
      var clipboard = new this.$copy(`.tag-item${index}`)
      clipboard.on('success', (e) => {
        this.$message({
          type: 'success',
          message: this.$t('cuttingDock.NCExportSettings.copySuccess'),
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        this.$message({
          type: 'success',
          message: this.$t('cuttingDock.NCExportSettings.copyErr'),
          offset: 70,
          duration: 1000,
        })
        clipboard.destroy()
      })
    },
    // 拖拽标签
    startDrag(event, item) {
      this.dragItem = item
    },
    // 放入时触发函数, 深度拷贝拖动的标签dom, 将克隆之后的标签放入盒子, 不影响之前的dom
    dropOver(event) {
      if (this.dragItem) {
        event.target.focus()
        this.fileNameFormat += `{${this.dragItem.label_name_show}}`
        this.autoFileName()
        this.$nextTick(() => {
          event.preventDefault()
          this.nameArr.push(this.dragItem.label_name_show)
        })
      }
    },
    //
    removeItem(index) {
      let s = this.fileNameFormat.match(/\{(.+?)\}/g)
      s.splice(index, 1)
      this.fileNameFormat = s.join('')
      this.nameArr.splice(index, 1)
      this.autoFileName()
    },
    // 拖动放入时, 阻止事件冒泡
    handleDragOver(event, index) {
      event.preventDefault()
    },
    dragTagOver(e, obj, list) {
      e.preventDefault()
      obj['template_string'] += `{${this.dropData.label_name_show}}`
      this.tagPoolTextareaChange(obj, list)
      this.recodeSelect = null
    },
    // 标签数据改变生成新的label_ids
    tagPoolTextareaChange(obj, list) {
      obj['label_ids'] = this.tempStringToObj(
        obj['template_string'],
        list[0].arr
      )
    },
    // 根据字符串和list返回新的label_ids
    tempStringToObj(str, list) {
      if (!str) return {}
      const strReg = str.match(/(?<={).*?(?=})/g) ?? []
      const obj = {}
      strReg.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj.hasOwnProperty(item)) {
          const find = list.find((d) => d.label_name_show == item)
          if (find) {
            obj[item] = find.id
          }
        }
      })
      return obj
    },
    // 重新给选中文本赋值
    selectChange(str) {
      if (this.recodeSelect) {
        const { obj, start, end, list } = this.recodeSelect
        const s = obj['template_string']
        obj['template_string'] = `${s.substring(0, start)}${str}${s.substring(
          end
        )}`
        this.tagPoolTextareaChange(obj, list)
      }
      this.recodeSelect = null
    },
    handleDargStart() {
      this.dragItem = ''
    },
    // 编辑框失焦保存选中文本
    blurSelect(e, obj, list) {
      if (e.target.selectionStart || e.target.selectionEnd) {
        this.recodeSelect = {
          start: e.target.selectionStart,
          end: e.target.selectionEnd,
          obj,
          list,
        }
      }
    },
    // 修改分隔符
    modifyDelimiter() {
      let reg = /[^-_]/
      this.formData.fileNameSetting.hasDelimiter =
        this.formData.fileNameSetting.hasDelimiter.replace(
          new RegExp(reg, 'g'),
          ''
        )
      this.autoFileName()
    },
    // 自动生成文件名示例
    autoFileName() {
      let list = []
      this.tagList.forEach((v) => {
        list = [...list, ...v.arr]
      })
      let tempName = this.fileNameFormat
      // 筛选出标签池里所拥有的数据
      let s = this.fileNameFormat.match(/\{(.+?)\}/g)
      if (s != null) {
        let tempTags = s.map((item) => item.replace(/\{|\}/g, ''))
        let testArr = []
        tempTags.forEach((v) => {
          list.forEach((x) => {
            if (x.label_name_show === v) {
              let setting = this.formData.fileNameSetting
              switch (x.label_name) {
                case 'stockNum':
                  let num = Number(setting.stockNumLength)
                  if (num > 0) {
                    let str = '0'.repeat(num - 1) + '1'
                    testArr.push(str)
                  } else {
                    testArr.push(x.val)
                  }
                  break
                case 'labelMachineName':
                  if (setting.labelMachineName != '') {
                    testArr.push(setting.labelMachineName)
                  } else {
                    testArr.push(x.val)
                  }
                  break
                case 'machineCenterName':
                  if (setting.machineCenterName != '') {
                    testArr.push(setting.machineCenterName)
                  } else {
                    testArr.push(x.val)
                  }
                  break
                case 'direction':
                  if (setting.front_info == '' && setting.back_info == '') {
                    // testArr.push(x.val)
                    testArr.push('/')
                  } else {
                    let str = ''
                    if (setting.front_info != '') {
                      str = `${setting.front_info}/`
                    } else {
                      str = '/'
                    }
                    if (setting.back_info != '') {
                      str += setting.back_info
                    } else {
                      str += ''
                    }
                    testArr.push(str)
                  }
                  break
                default:
                  testArr.push(x.val)
                  break
              }
            }
          })
        })
        if (this.formData.fileNameSetting.hasDelimiter) {
          this.exampleFileName = testArr.join(
            this.formData.fileNameSetting.hasDelimiter
          )
        } else {
          this.exampleFileName = testArr.join('')
        }
      } else {
        this.exampleFileName = this.fileNameFormat
      }
    },
    inputCheck() {
      this.formData.fileNameSetting.stockNumLength =
        this.formData.fileNameSetting.stockNumLength.replace(/[^\d]/g, '') // 去除数字和小数点以外的数字
    },
    wordLimit(word, num) {
      let pattern = /[^a-zA-Z0-9_@%\+\-\u4e00-\u9fa5]+$/
      let str = this.formData.fileNameSetting[word]
      if (pattern.test(str)) {
        this.$nextTick(() => {
          this.errorStr = this.$t('cuttingDock.NCExportSettings.specialCodeErr')
          this.$refs[`error${num}`].className = 'error-info error-info-in'
        })
      } else {
        // this.showError = false
        this.$nextTick(() => {
          this.$refs[`error${num}`].className = 'error-info'
        })
      }
      if (str.length == 16) {
        this.$nextTick(() => {
          this.formData.fileNameSetting[word] = str.slice(0, -1)
          this.errorStr = this.$t('cuttingDock.NCExportSettings.lengthLimit')
          this.$refs[`error${num}`].className = 'error-info error-info-in'
        })
      } else if (str.length < 16) {
        this.$refs[`error${num}`].className = 'error-info'
      }
      this.formData.fileNameSetting[word] = this.formData.fileNameSetting[
        word
      ].replace(pattern, '')
      this.autoFileName()
    },
    // 获取nc自定义文件名的接口
    getNcCustomLabel() {
      this.$getByToken(
        '/get_electronic_saw_label',
        {
          label_class: 'ncLabel',
        },
        (res) => {
          let ncLabel = res.data
          let arr = []
          Object.keys(ncLabel).forEach((v) => {
            arr = [...arr, ...ncLabel[v]]
          })
          arr.forEach((v) => {
            switch (v.label_name) {
              case 'stockNum':
                v.val = '001'
                break
              case 'matCode':
                v.val = '多层实木'
                break
              case 'thick':
                v.val = '18'
                break
              case 'texture':
                v.val = 'T01'
                break
              case 'direction':
                v.val = '正面/反面'
                break
              case 'labelMachineName':
                v.val = '标签机开头'
                break
              case 'machineCenterName':
                v.val = '加工中心开头'
                break
              case 'plankWidth':
                v.val = '1220'
                break
              case 'plankHeight':
                v.val = '2440'
                break
              case 'address':
                v.val = '板件旋转_侧孔错误'
                break
              case 'orderNo':
                v.val = '220818-154'
                break
              case 'surplus_size':
                v.val = '1440（1000）-1000（600）/1440-600'
                break
              default:
                v.val = ''
            }
          })
          this.setNcLabel(arr)
          let plankInfo = arr.filter((v) => v.label_type == 'plank_info')
          let otherInfo = arr.filter((v) => v.label_type == 'other_info')
          let orderInfo = arr.filter((v) => v.label_type == 'order_info')
          const plank_info = arr.filter((v) => v.label_type == 'big_plank_info')
          this.customTag.plank_info = [{ arr: plank_info }]
          this.tagList = [
            {
              title: this.$t('materialPage.orderInfo'),
              arr: orderInfo,
            },
            {
              title: this.$t('cuttingDock.NCExportSettings.plankInfo'),
              arr: plankInfo,
            },
            {
              title: this.$t('cuttingDock.NCExportSettings.otherInfo'),
              arr: otherInfo,
            },
          ]
        }
      )
    },
    //组织锚点改变url
    handlePrevent(e, link) {
      e.preventDefault()
    },
    // 处理nc自定义文件名
    dealNameArr() {
      let list = []
      this.tagList.forEach((v) => {
        list = [...list, ...v.arr]
      })
      let arr = JSON.parse(JSON.stringify(this.nameArr))
      let tempStr = arr.map((v) => `{${v}}`)
      if (this.formData.fileNameSetting.hasDelimiter) {
        this.formData.fileNameSetting.customizeSetting.templateString =
          tempStr.join(this.formData.fileNameSetting.hasDelimiter)
      } else {
        this.formData.fileNameSetting.customizeSetting.templateString =
          tempStr.join('')
      }
      let headerIds = {}
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (list[i].label_name_show === arr[j]) {
            headerIds[list[i].label_name_show] = list[i].id
          }
        }
      }
      this.formData.fileNameSetting.customizeSetting.headerIds = headerIds
    },
    translateLang(key) {
      return translate(key)
    },
  },
  mounted() {
    this.getNcCustomLabel()
    document
      .getElementById('ncExportSetting_fileNameType_input')
      .addEventListener('dragover', (event) => {
        event.preventDefault()
      })
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/engraving.less';
.export-setting {
  background: #fff;
  span {
    cursor: default;
  }
  .wrap {
    .navRightWrapWidth();
    .main-setting {
      .auto-sort {
        box-sizing: border-box;
        padding: 24px;
      }
      .custom-head {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px 24px 24px;
        .custom-label-head {
          display: flex;
          align-items: center;
          margin-right: 32px;
          .ant-input {
            width: 290px;
          }
        }
        .custom-cure-head {
          display: flex;
          align-items: center;
          .ant-input {
            width: 290px;
          }
        }
        .plank-num-length {
          .ant-input {
            width: 90px;
          }
        }
        .has-error-info {
          position: relative;
          .error-info {
            position: absolute;
            display: block;
            width: 100%;
            color: red;
            font-size: 12px;
            transform: scaleY(0);
            transform-origin: top;
            top: unset !important;
            left: unset !important;
            transition: all 0.1s ease-out;
          }
          .error-info-in {
            transform: scaleY(1);
          }
        }
      }
      .tag-lake {
        box-sizing: border-box;
        padding: 0 24px;
        .tag-box {
          box-sizing: border-box;
          overflow: hidden;
          border: 1px solid #e9e9e9;
          border-radius: 4px;
          .custom-form-head {
            box-sizing: border-box;
            height: 44px;
            padding: 11px 24px;
            background: #e9e9e9;
            span:first-child {
              color: #000;
              font-weight: 600;
              font-size: 16px;
            }
            span:last-child {
              color: #8c8c8c;
              font-size: 14px;
            }
          }
          .custom-form-main {
            box-sizing: border-box;
            padding: 0 24px;
            &:last-child .tag-item {
              border-bottom: none;
            }
            .tag-item {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 29px 0 21px 0;
              border-bottom: 1px solid #e9e9e9;
              & > span {
                display: inline-block;
                width: 128px;
                font-size: 16px;
                text-align: right;
                user-select: none;
              }
              .tags {
                display: flex;
                align-items: center;
                & > span {
                  display: inline-block;
                  min-width: 102px;
                  height: 32px;
                  margin-right: 8px;
                  color: #5a5a5a;
                  font-size: 14px;
                  line-height: 32px;
                  text-align: center;
                  background: #eaf4f6;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .custom-file-name {
          margin-top: 24px;
          & > div {
            margin-bottom: 16px;
          }
          .file-has-delimiter {
            .ant-input {
              width: 100px;
            }
          }
          .file-name-format {
            display: flex;
            align-items: center;
            .file-format-box {
              display: flex;
              min-width: 290px;
              min-height: 42px;
              // height: 34px;
              padding-right: 24px;
              line-height: 32px;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              padding-top: 4px;
              padding-bottom: 4px;
              padding-left: 11px;
              .default-show {
                padding-left: 8px;
              }
              .tag {
                display: inline-block;
                min-width: 102px;
                height: 32px;
                margin-right: 8px;
                color: #5a5a5a;
                font-size: 14px;
                line-height: 32px;
                text-align: center;
                background: #eaf4f6;
                & > i {
                  margin-left: 8px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .merge-box {
        .merge-box-title {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding: 24px;
          border-bottom: 1px solid #e9e9e9;
          span {
            margin-right: 16px;
            color: #000;
            font-weight: 600;
            font-size: 18px;
          }
        }
        .radio-check-box {
          box-sizing: border-box;
          padding: 12px 24px;
          .radio-check-item {
            margin: 12px 0;
          }
        }
      }
      .annotation {
        .annotation-box {
          display: flex;
          flex-wrap: wrap;
          float: left;
          box-sizing: border-box;
          width: 40%;
          padding: 24px;
          & > div {
            width: 100%;
            // margin-right: 80px;
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .plank-info {
            display: flex;
            .ant-input {
              width: 350px;
              height: 110px;
              resize: none;
            }
          }
        }
        .tag-pool {
          float: left;
          width: 50%;
        }
      }
      .annotation.annotation-disable {
        span {
          color: #d9d9d9 !important;
        }
      }

      .format {
        .format-box {
          box-sizing: border-box;
          padding: 24px;
          .format-check-box {
            display: flex;
            margin-bottom: 24px;
            & > div {
              margin-right: 80px;
            }
          }
          .xinghui {
            margin-bottom: 24px;
            .ant-checkbox {
              margin: 0 24px 0 8px;
            }
            .xinghui-zhushi {
              .ant-checkbox {
                margin: 0;
              }
              .ant-checkbox + span {
                padding-right: 0;
              }
            }
          }
          .format-radio-box {
            display: flex;
            margin-bottom: 24px;
          }
        }
      }
      .format.format-disable {
        span {
          color: #d9d9d9 !important;
        }
      }
      .g-order {
        .common-box {
          box-sizing: border-box;
          padding: 24px;
        }
      }
      .file-format-setting-box {
        box-sizing: border-box;
        padding: 24px;
        .common-box {
          & > span {
            margin-right: 24px;
          }
          margin-bottom: 24px;
        }
      }
      .plank-code {
        .common-box {
          box-sizing: border-box;
          padding: 24px;
        }
      }
    }
  }
}
.err-tip {
  top: 40px;
  left: 140px;
  color: red;
}
.custom-head-err-boder {
  border-color: #ff0000;
  // box-shadow: none !important;
  &:focus {
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.16) !important;
  }
}
.error-info {
  display: block;
  width: 100%;
  color: red;
  font-size: 12px;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.1s ease-out;
}
.err-text-tip {
  position: unset !important;
}
.error-info-in {
  transform: scaleY(1);
}
</style>
